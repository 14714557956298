<template>
    <div class="row">
        <transition-group :name="mobile ? 'flip' : 'fade'">
            <div class="col-12" v-if="screen == 'main'">
                <div class="features-card min-sc" id="products_card">
                    <div class="card-header flex-ec">
                        <button type="button" class="main-button small me-1" @click="$router.go(-1)"><i class="fas fa-arrow-left fa-lg"></i></button>
                        <transition-group tag="div" class="header-container pe-4" name="fade">
                            <h3 key="namez" v-if="group.name">{{ group.name }}</h3>
                            <!-- <h3 key="namez" v-if="group.name && mobile"><i class="fas fa-users text-primary"></i></h3> -->
                            <i key="icon" v-if="group.name" class="fas fa-chevron-right fa-lg mx-1"></i>
                            <h3 key="ads" v-if="group.name">{{ language.group_products.split(' ')[1] }}</h3>
                        </transition-group>
                        <transition-group name="fade">
                            <router-link v-if="!mobile" key="add" :to="user.can_post ? ('/p/add/'+data.groupId) : '/checkout?type=new-ads'" class="main-button small me-1 me-lg-2">{{ language.add_product }}</router-link>
                            <div key="search" class="position-relative ms-auto me-1 me-lg-2" :class="mobile ? 'w-40' : 'w-25'">
                                <input type="search" ref="search_field" v-model="search_string" @keyup.enter="search_page" class="form-control" :placeholder="language.type_min_3" required>
                                <button type="button" @click="search_page" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                            </div>
                            <button key="filter_data" aria-label="filter_data" data-toggle="tooltip" :title="language.heading_sort_filter" type="button" @click="filterShow" :class="{'light' : !filter_applied}" class="main-button small search_button me-4"><i class="fas fa-15x fa-filter"></i></button>
                            <!-- <button key="icon" type="button" @click="changeShowInput" :class="{ 'active_search' : showInput }" class="main-button light small me-4 search_button"><i class="fas fa-15x fa-search"></i></button>
                            <input key="search" type="search" ref="search_field" :class="showInput ? 'show_input me-4' : 'p-0'" v-model="search_string"  @input="debouncedSearch" class="form-control small" :placeholder="language.type_min_3" required> -->
                            <div v-click-outside="closeDropdown" class="btn-group position-absolute" key="ellipsis-dropdown">
                                <button class="btn menu-btn btn-sm d-flex" type="button" @click="navdrop = navdrop == 2 ? false : 2">
                                    <i class="fas fa-15x fa-ellipsis-v"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" :class="{ 'animate' : navdrop == 2}">
                                    <button type="button" @click="screen='second';infoscreen=false;navdrop=false;tooltip_fun();" class="dropdown-item">
                                        <i class="fas fa-comments"></i> <span>{{ language.group_chat }}</span>
                                    </button>
                                    <button type="button" @click="screen='second';infoscreen=true;navdrop=false;tooltip_fun();" class="dropdown-item position-relative">
                                        <i class="fas fa-user"></i> <span>{{ language.group_info }}</span>
                                        <span class="notif fs-6 normal me-2" v-if="requests.length" :class="requests.length ? 'faa-tada animated' : ''">{{ requests.length }}</span>
                                    </button>
                                    <router-link :to="'/group/' + id + '/events'" class="dropdown-item">
                                        <i class="fas fa-calendar-plus pe-1"></i>{{ language.community_events }}
                                    </router-link>
                                    <a href="javascript:;" class="dropdown-item" @click="showModal = 'share'">
                                        <i class="fas fa-share-alt pe-1"></i>{{language.share_community}}
                                    </a>
                                    <router-link v-if="mobile" :to="user.can_post ? ('/p/add/' + data.groupId) : '/checkout?type=new-ads'" class="dropdown-item">
                                        <i class="fas fa-box-open"></i> <span>{{ language.add_product }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <div class="card-body">
                        <transition-group tag="div" class="pcont" name="bounce">
                            <Product :pids="pids" :active_chats="active_chats" :type="'?type=group&id='+group.firebase_group_id" :lang="language" :user="user" :product="product" class="product-block-card" display_type="card"
                                :liked_array="liked" :can_like="true" v-for="(product,index) in products" :index="index" :key="product.id"
                                :group_del="am_admin ? group.firebase_group_id : false"
                                :can_del="false" :class="index == (products.length - last_item) ? 'last_item' : ''"  />
                        </transition-group>
                    </div>
                    <NotFound :lang="language" :type="text" :load="loading" v-if="loading || products.length == 0"></NotFound>
                </div>
            </div>
            <div class="col-12" v-if="screen == 'second' && !infoscreen">
                <div class="features-card min-sc sticky-top d-flex flex-column" style="top:115px; z-index:unset;">
                    <div class="card-header flex-ec">
                        <h3 class="me-auto"><i class="fas fa-chevron-left pe-2" role="button" @click="screen = 'main';infoscreen=false;"></i>{{ language.group_chat }}</h3>
                        <!-- <transition-group tag="div" class="header-container pe-5" name="fade">
                            <h3 key="namez" v-if="group.name">{{ group.name }}</h3>
                            <i key="icon" v-if="group.name" class="fas fa-chevron-right fa-lg mx-1"></i>
                            <h3 key="ads">{{ language.chat }}</h3>
                        </transition-group> -->
                        <!-- <h3 class="me-auto"><i class="fas fa-chevron-left pe-2" role="button" @click="$router.push('/groups')"></i>{{ language.group_chat }}</h3> -->
                        <div v-click-outside="closeDropdown" class="btn-group ms-5 ms-md-auto position-absolute">
                            <button type="button" class="btn menu-btn pe-md-0" @click="navdrop = navdrop == 1 ? false : 1">
                                <i class="fas fa-15x fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" :class="{ 'animate' : navdrop == 1}">
                                <a @click="screen='main';navdrop=false" href="javascript:;" class="dropdown-item">
                                    <i class="fas fa-boxes"></i> <span>{{ language.group_marketplace }}</span>
                                </a>
                                <a @click="infoscreen = true;navdrop=false" href="javascript:;" :class="!mobile ? '' : '', requests.length ? 'faa-tada animated' : ''" class="dropdown-item">
                                    <i class="fas fa-user"></i> <span>{{ language.group_info }}</span>
                                </a>
                                <a href="javascript:;" class="dropdown-item" @click="showModal = 'share'">
                                    <i class="fas fa-share-alt pe-1"></i>{{language.share_community}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <transition name="flip">
                        <div class="card-body h-100">
                            <div class="row position-relative h-100">
                                <div class="col-12">
                                    <transition-group tag="div" class="mb-3 chat_container" style="height: calc(100vh - 310px);"
                                        name="fade">
                                        <div v-for="(c, index) in chat"
                                            :class="{ 'reverse': user.user.social_profile_id == c.idFrom }" :key="c.timestamp"
                                            class="msg-container">
                                            <div class="d-flex flex-column">
                                                <span
                                                    v-if="index == 0 || (index > 0 && chat[index - 1].idFrom != c.idFrom && c.idFrom != user.user.social_profile_id)"
                                                    @click.prevent="user.user.social_profile_id == c.idFrom ? null : reply_private(c.sender, c.idFrom, c.fromCryptid)"
                                                    class="name cursor-pointer">{{ c.sender }} <i v-if="user.user.social_profile_id != c.idFrom" class="fas fa-comments fa-sm ms-1"></i></span>
                                                <div class="msgbox"
                                                    :class="[{ 'incoming': user.user.social_profile_id == c.idFrom }, index < chat.length - 1 && chat[index + 1].idFrom == c.idFrom ? 'mb-2p' : '']"
                                                    @click.prevent="user.user.social_profile_id == c.idFrom ? null : reply_private(c.sender, c.idFrom, c.fromCryptid)">
                                                    <span class="msg">{{ c.content }}</span>
                                                </div>
                                            </div>
                                            <span class="time"
                                                :class="user.user.social_profile_id == c.idFrom ? 'me-3' : 'ms-3'">{{
                                                    $filters.formatUnix(c.timestamp) }}</span>
                                        </div>
                                        <NotFound :lang="language" :type="ctext" :load="cloading" class="mb-5"
                                            v-if="!chat.length"></NotFound>
                                    </transition-group>
                                    <div id="sendmessage" class="rounded-3">
                                        <input class="rounded-3 ps-2" type="text" @keypress.enter="send_message()" id="input_send" :placeholder="language.send_message" v-model="msg_to_send" />
                                        <button @click="send_message()" class="rounded-3">
                                            <i class="fab fa-telegram-plane"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="col-12" v-if="screen == 'second' && infoscreen">
                <div class="features-card min-sc sticky-top d-flex flex-column" style="top:115px; z-index:unset;">
                    <div class="card-header flex-ec">
                        <h3 class="me-auto"><i class="fas fa-chevron-left pe-2" role="button" @click="screen = 'main';infoscreen=false;"></i>{{ language.group_info }}</h3>
                        <div v-click-outside="closeDropdown" class="btn-group position-absolute ms-5 ms-md-auto">
                            <button type="button" class="btn menu-btn pe-md-0" @click="navdrop = navdrop == 2 ? false : 2">
                                <i class="fas fa-15x fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" :class="{ 'animate' : navdrop == 2}">
                                <a @click="screen='main';navdrop=false" href="javascript:;" class="dropdown-item">
                                    <i class="fas fa-boxes"></i> <span>{{ language.group_marketplace }}</span>
                                </a>
                                <a @click="infoscreen=false;navdrop=false" href="javascript:;" class="dropdown-item">
                                    <i class="fas fa-comments"></i> <span>{{ language.group_chat }}</span>
                                </a>
                                <a href="javascript:;" class="dropdown-item" @click="showModal = 'share'">
                                    <i class="fas fa-share-alt pe-1"></i>{{language.share_community}}
                                </a>
                                <a v-if="am_admin" href="javascript:;" @click="change_showModal('update')" class="dropdown-item position-relative">
                                    <i class="fas fa-pencil-alt"></i> <span>{{ language.update_group }}</span>
                                </a>
                                <a v-if="am_admin" href="javascript:;" @click="delete_group()" class="dropdown-item">
                                    <i class="fas fa-trash"></i> <span>{{ language.delete_group }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column position-relative">
                        <div class="group-block small">
                            <div class="me-1 me-md-2">
                                <div class="group-icon" v-if="!group.icon_blob || group.icon_blob == ''">{{ Array.from(group.name)[0] }}</div>
                                <img v-else :src="preview" class="profile-icon w50">
                            </div>
                            <div class="body">
                                <div class="content">
                                    <div ref="name" class="name">{{ group.name }}</div>
                                    <i data-toggle="tooltip" :title="language.private_group" class="fas fa-lock mx-2 fa-15x" v-if="group.is_private"></i>
                                </div>
                                <div class="message" v-if="group.description">{{ group.description }}</div>
                            </div>
                        </div>
                        <h4 class="mb-0 mt-1">{{ language.group_invite_link }}</h4>
                        <hr class="mt-1">
                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                            <span class="link pe-md-4" v-if="group.invite_code && group.invite_code.length > 0">{{ $domain+'/group/invite/'+group.invite_code }}</span>
                            <span class="ms-md-auto">
                                <button v-if="group.invite_code && group.invite_code.length > 0" type="button" class="main-button small" @click="emitter.emit('copy_url', [$domain+'/group/invite/'+group.invite_code, 'group_invite_link'])">
                                    <i class="fas fa-clipboard me-2"></i> {{ language.copy }}
                                </button>
                                <!-- <button type="button" class="main-button small ms-2" @click="resetLink()">
                                    <i class="fas fa-minus-circle  me-2"></i> {{ language.reset }}
                                </button> -->
                            </span>
                        </div>
                        <h4 class="mb-0 mt-1">{{ language.share_link }}</h4>
                        <hr class="mt-1">
                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                            <span class="link pe-md-4">{{ $domain+'/p/add/'+data.groupId }}</span>
                            <span class="ms-md-auto">
                                <button v-if="group.invite_code && group.invite_code.length > 0" type="button" class="main-button small" @click="emitter.emit('copy_url', [$domain+'/p/add/'+data.groupId, 'share_link'])">
                                    <i class="fas fa-clipboard me-2"></i> {{ language.copy }}
                                </button>
                                <!-- <button type="button" class="main-button small ms-2" @click="resetLink()">
                                    <i class="fas fa-minus-circle  me-2"></i> {{ language.reset }}
                                </button> -->
                            </span>
                        </div>
                        <h4 class="mb-0 mt-1">{{ language.members_list }} </h4>
                        <hr class="mt-1">
                        <!-- mh-200px overflow-y-auto -->
                        <!-- <div class="mh-200px overflow-y-auto row">
                        </div> -->
                        <transition-group name="fade" tag="div" class="row" appear>
                            <div class="col-12 col-md-4" v-for="member in members.members" :key="member.id">
                                <div class="group-block small">
                                    <img @click="$router.push('/u/'+member.user_details.slug)" :src="member.user_details ? $s3_url + member.user_details.thumbnail_image : ''" class="profile-icon w40">
                                    <div @click="$router.push('/u/'+member.user_details.slug)" class="name text-body">
                                        {{ member.user_details.first_name }}
                                    </div>
                                    <div class="flex-ec gap-1 position-relative">
                                        <transition name="fade">
                                            <i data-toggle="tooltip" :title="language.admin" class="fas fa-user-shield fa-15x ms-2" :class="{ 'pe-4 me-1' : member.user_details.social_profile_id != user.user.social_profile_id }" v-if="member.role == 1"></i>
                                        </transition>
                                        <div v-click-outside="closeMember" class="btn-group position-absolute" v-if="member.user_details.social_profile_id != user.user.social_profile_id">
                                            <button type="button" class="btn menu-btn" @click="memberdrop = memberdrop == member.id ? false : member.id">
                                                <i class="fas fa-15x fa-ellipsis-v"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right members" :class="{ 'animate' : memberdrop == member.id}">
                                                <button :disabled="buttonLoading == 'make_admin'" type="button" class="dropdown-item" v-if="member.role == 2 && am_admin" @click="make_admin(member.id, member.user_details.social_profile_id, 'add')">
                                                    <transition name="fade">
                                                        <i class="fas me-1" :class="buttonLoading == 'make_admin' ? 'fa-spinner fa-spin' : 'fa-user-shield'" :key="buttonLoading"></i>
                                                    </transition>
                                                    {{ language.make_admin }}
                                                </button>
                                                <button :disabled="buttonLoading == 'remove_admin'" type="button" class="dropdown-item" v-if="member.user_details.social_profile_id != user.user.social_profile_id && member.role == 1 && am_admin" @click="make_admin(member.id, member.user_details.social_profile_id, 'remove')">
                                                    <transition name="fade">
                                                        <i class="fas me-1" :class="buttonLoading == 'remove_admin' ? 'fa-spinner fa-spin' : 'fa-user-shield'" :key="buttonLoading"></i>
                                                    </transition>
                                                    {{ language.remove_admin }}
                                                </button>
                                                <button type="button" class="dropdown-item" v-if="member.user_details.social_profile_id != user.user.social_profile_id" @click="reply_private(member.user_details.first_name, member.user_details.social_profile_id, member.user_details.crypt_id)">
                                                    <i class="fas me-1 fa-comments"></i>
                                                    {{ language.chat }}
                                                </button>
                                                <button :disabled="buttonLoading == 'leave'" type="button" v-if="member.role == 2 && am_admin" class="dropdown-item text-danger" @click="leave_group(member.user_details.id, member.user_details.social_profile_id, 'remove')">
                                                    <transition name="fade">
                                                        <i class="fas me-1" :class="buttonLoading == 'leave' ? 'fa-spinner fa-spin' : 'fa-user-minus'" :key="buttonLoading"></i>
                                                    </transition>
                                                    {{ language.remove }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                        <div class="mt-auto">
                            <a href="javascript:;" v-if="am_admin" class="float-start main-button position-relative" @click="change_showModal('requests')">
                                {{ language.join_requests }}
                                <span class="notif invert" :class="requests.length ? 'faa-tada animated' : ''" v-if="requests.length">
                                    {{ requests.length }}
                                </span>
                            </a>
                            <a href="javascript:;" v-if="am_admin" class="float-end main-button" @click="change_showModal('search')">
                                <i class="fas fa-user-plus fa-15x"></i>
                            </a>
                            <a href="javascript:;" v-if="!am_admin" class="float-end main-button small bg-danger" @click="leave_group(user.user.id, user.user.social_profile_id, 'leave')">
                                {{ language.leave }} <i class="fas fa-sign-out-alt ms-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'search'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div
                                class="card-header flex-bc">
                                <h3>{{ language.search_user_email }}</h3>
                                <a v-if="token" href="javascript:;" @click="showModal = false; search = ''; searched_user = [];"
                                    class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body">
                                <div class="row contact-form">
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.search_name_email }}</label>
                                        <input type="search" ref="inputSearch" autocomplete v-model="search" @keyup="search_user()" class="form-control" required />
                                    </div>
                                    <div v-if="searched_user?.length > 0" class="col-12">
                                        <label class="ms-3 mb-3">{{ language.users_list }}</label>
                                        <button type="button" class="main-button d-flex align-items-center justify-content-between light btn-block mx-auto w-100 mb-3" v-for="su in searched_user" :key="su.id">
                                            <span>
                                                <img :src="$s3_url + su.thumbnail_image" class="profile-icon">
                                                {{ su.first_name + ' ' + su.last_name }}
                                            </span>
                                            <button type="button" :disabled="buttonLoading == 'invite' || group_invites.includes(su.id)" v-if="!members.members.find(el => el.user_id === su.id)" class="main-button small" @click="invite_user(su)" :class="group_invites.includes(su.id) ? 'bg-dark' : ''">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='invite'"></i>
                                                </transition>
                                                {{ group_invites.includes(su.id) ? language.invited : language.invite }}
                                            </button>
                                            <button type="button" v-else class="main-button small bg-dark">
                                                {{ language.already_joined }}
                                            </button>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'update'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="update_group" class="features-card rounded-4">
                            <div
                                class="card-header flex-bc">
                                <h3>{{ language.update_group }}</h3>
                                <a v-if="token" href="javascript:;"
                                    @click="showModal = false; name = group.name; is_private = group.is_private == 1 ? true : false;"
                                    class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.group_name }}</label>
                                        <input type="text" v-model="name" class="form-control" required autofocus :class="{ 'is-invalid mb-0': errors.name}" />
                                        <span class="invalid-feedback ms-2" role="alert" v-if="errors.name" ><strong>{{ errors.name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.group_location }}</label>
                                        <div class="flex-bc gap-2 flex-column flex-md-row">
                                            <div class="col-12 col-md-8">
                                                <GMapAutocomplete @placeholder="language?.search_your_location || 'N/A'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" @class="'mb-4 form-control'" ></GMapAutocomplete>
                                            </div>
                                            <button type="button" @click="current_location" class="main-button mb-4 col-md-4"><i class="fa fa-map-marker-alt me-2"></i>{{language.use_my_current_location}}</button>
                                            <transition name="bounce">
                                                <button key="change" aria-label="change" type="button" v-if="loc_label && mobile" class="main-button light small mb-3">
                                                    <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                                    {{ loc_label }}
                                                </button>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex flex-column">
                                        <label class="ms-3 mb-1">{{ language.is_group_private }}</label>
                                        <div class="custom-control ms-3 custom-switch custom-switch-lg mb-3">
                                            <input type="checkbox" ref="invis" v-model="is_private" :checked="is_private"
                                            class="custom-control-input" id="customSwitch2" />
                                            <label class="custom-control-label text-nowrap" for="customSwitch2"></label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <label class="ms-3 mb-1">{{ language.group_image }}</label>
                                        <div class="input-preview ms-3">
                                            <div class="group-block p-0 shadow-none mb-0" v-if="!preview">
                                                <div class="group-icon position-relative">
                                                    {{ Array.from(data.groupName)[0] }}
                                                </div>
                                            </div>
                                            <img v-else :src="preview" class="profile-icon w50">
                                            <input type="file" ref="images" accept="image/*" @change="uploadphoto" id="update_image">
                                            <label for="update_image">
                                                <i class="fas fa-upload"></i>
                                                <!-- <button type="button" class="main-button small">
                                                </button> -->
                                            </label>
                                        </div>
                                    </div>
                                    <transition name="bounce">
                                        <div class="col-12 col-md-4 flex-cc" v-if="!mobile">
                                            <transition name="bounce">
                                                <button key="change" aria-label="change" type="button" v-if="loc_label" class="main-button light small mb-3">
                                                    <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                                    {{ loc_label }}
                                                </button>
                                            </transition>
                                        </div>
                                    </transition>
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.description }}</label>
                                        <textarea v-model="description" class="form-control h-auto" rows="3" autofocus :class="{ 'is-invalid mb-0': errors.description}"></textarea>
                                        <span class="invalid-feedback ms-2" role="alert" v-if="errors.description" ><strong>{{ errors.description[0] }}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light"
                                    @click="showModal = false; name = group.name; name = group.description; is_private = group.is_private;">
                                    {{ language.close }}
                                </button>
                                <button :disabled="buttonLoading == 'update'" type="submit" class="main-button ms-4">
                                    <transition name="fade">
                                        <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='update'"></i>
                                    </transition>
                                    {{ language.save_changes }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'requests'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div
                                class="card-header flex-bc">
                                <h3>{{ language.group_join_requests }}</h3>
                                <a v-if="token" href="javascript:;" @click="showModal = false;"
                                    class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body">
                                <transition-group tag="div" class="row" name="bounce" appear>
                                    <div class="col-12" v-for="request in requests" :key="request.id">
                                        <div class="group-block small">
                                            <img :src="request.user_details ? $s3_url + request.user_details.thumbnail_image : ''"
                                                class="profile-icon w40">
                                            <div class="name">
                                                {{ request.user_details.first_name }}
                                            </div>
                                            <button type="button" :disabled="buttonLoading=='approve_request' || buttonLoading=='reject_request'" class="main-button ms-5 ms-md-auto me-2 small" @click="approve(request.user_id, request.user_details.social_profile_id)">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='approve_request' || buttonLoading=='reject_request'"></i>
                                                </transition>
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button type="button" :disabled="buttonLoading=='approve_request' || buttonLoading=='reject_request'" class="main-button small bg-dark" @click="reject(request.user_id)">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='approve_request' || buttonLoading=='reject_request'"></i>
                                                </transition>
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </transition-group>
                            </div>
                            <NotFound :lang="language" :type="language.no_requests_found + '<br>' + language.you_can_invite"
                                :load="false" v-if="!requests.length"></NotFound>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'messageDirect'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="directMessageSend" class="features-card rounded-4">
                            <div
                                class="card-header flex-bc">
                                <h3>{{ language.send_message_directly }} {{ directMessage.name }}</h3>
                                <a v-if="token" href="javascript:;" @click="showModal = false"
                                    class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.message }}</label>
                                        <input type="text" v-model="directMessage.message" class="form-control" required autofocus />
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light" @click="showModal = false">
                                    {{ language.close }}
                                </button>
                                <button :disabled="buttonLoading == 'send_message'" type="submit" class="main-button ms-4">
                                    <transition name="fade">
                                        <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='send_message'"></i>
                                    </transition>
                                    {{ language.send_message }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'share'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.share_community }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row networks-body">
                                    <div class="col-12 mb-3">
                                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                                            <span class="link pe-md-4">{{ $domain+'/group/i/'+data.groupId }}</span>
                                            <span class="ms-md-auto">
                                                <button v-if="group.invite_code && group.invite_code.length > 0" type="button" class="main-button small" @click="emitter.emit('copy_url', [$domain+'/group/i/'+data.groupId, 'share_community'])">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.copy }}
                                                </button>
                                            </span>
                                        </div>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="col-3 d-flex justify-content-center" v-for="network in networks">
                                        <ShareNetwork
                                            :network="network.network"
                                            :key="network.network"
                                            :style="{backgroundColor: network.color, border: '1px solid '+network.color}"
                                            class="network-icon"
                                            :url="sharing.url"
                                            :title="sharing.title"
                                            :description="sharing.description"
                                            :quote="sharing.quote"
                                            :hashtags="sharing.hashtags"
                                            :twitterUser="sharing.twitterUser"
                                        >
                                            <i :class="network.icon"></i>
                                            <span class="ms-2" v-if="!mobile">{{ network.name }}</span>
                                        </ShareNetwork>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'filters'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="apply_filter" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.heading_sort_filter }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row match-height">
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.sort_by }}</h5>
                                            <ul class="ps-3 mb-4">
                                                <!-- <label class="radio-container small">Default
                                                    <input type="radio" v-model="filter_data.sort_by" value="default">
                                                    <span class="checkmark"></span>
                                                </label> -->
                                                <label class="radio-container small">{{ language.text_newest }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="newest">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_distance }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="distance">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_az }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-az">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_za }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-za">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_lth }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-lth">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_htl }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-htl">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <span class="mt-auto">
                                            <h5>{{ language.filter_by_cats }}</h5>
                                            <div class="col-12">
                                                <label class="ms-3 mb-1">{{ language.category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.cat" :options="comp_cats" />
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-lg-4">
                                        <span class="order-2 order-md-1">
                                            <h5>{{ language.filter_by_type }}</h5>
                                            <ul class="ps-3 pb-3">
                                                <label class="checkbox-container small" v-for="(t,ind) in ttypes" :key="ind">{{t.label}}
                                                    <input type="checkbox" v-model="filter_data.type" :value="t.value">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <transition name="fade">
                                            <div class="col-12 order-1 order-md-2 d-flex flex-column justify-content-end" v-if="filter_data.cat.length > 0">
                                                <label class="ms-3 mb-1">{{ language.sub_category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.subcat" :options="comp_subcats" />
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.filter_by_date }}</h5>
                                            <div class="col-12">
                                                <v-select :clearable="false" :reduce="(option) => option.value" class="v-form-control" v-model="filter_data.date_posted" :options="filter_dates" />
                                            </div>
                                        </span>
                                        <span class="mt-5 mb-3 mb-md-0">
                                            <h5>{{ language.filter_by_price }}</h5>
                                            <!-- <div class="col-12 d-flex text-nowrap align-items-center justify-content-between">
                                                <input type="range" v-model="filter_data.price" step="1" class="form-control filter-slider pe-4" :min="1" :max="max_price">
                                                <p class="mb-0">{{ price(filter_data.price || max_price) }}</p>
                                            </div> -->
                                            <Slider tooltipPosition="bottom" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="0" :format="price" :max="max_price" v-model="filter_data.price" />
                                        </span>
                                        <!-- <span class="mt-auto">
                                            <h5>{{ language.filter_by_distance }}</h5>
                                            <Slider tooltipPosition="bottom" :step="1" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="1" :max="3000" :format="f_distance" v-model="filter_data.distance" />
                                        </span> -->
                                        <!-- <span class="slider-container mt-auto">
                                            <div class="col-12 d-flex text-nowrap align-items-center justify-content-between">
                                                <input type="range" v-model="filter_data.date_posted" step="1" class="form-control filter-slider pe-4" :min="7" :max="100">
                                                <h5 class="mb-0">{{ filter_data.date_posted+' days ago' }}</h5>
                                            </div>
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button aria-label="{{language.reset}}" type="button" class="main-button light me-2" @click="reset_filter">{{language.reset}}</button>
                                <button aria-label="{{language.apply}}" type="submit" class="main-button ms-2">{{ language.apply }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
.flip-enter-active {
    transition: all 0.5s ease;
}
.flip-leave-active {
    display: none;
}
.flip-enter-from, .flip-leave-to {
    transform: rotateY(180deg);
    opacity: 0;
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.2s reverse;
}

.fade-enter-active {
    transition: all 0.3s ease-in;
}

.fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    position: absolute;
    opacity: 0;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    position: absolute;
    transform: translateX(-20%);
    opacity: 0;
}
</style>
<script setup>
import { collection, orderBy, onSnapshot, getDocs, query, where, addDoc, setDoc, updateDoc, deleteDoc, getDoc, arrayUnion, arrayRemove, doc } from "firebase/firestore";
    </script>
<script>
export default {
    name: 'Group',
    props: ['user', 'language', 'categories'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            id: this.$route.params.id,
            data: [],
            group: [],
            members: [],
            buttonLoading: false,
            products: [],
            pids: [],
            c_page:1,
            l_count:0,
            searched: false,
            search_string: '',
            errors: [],
            last_item: this.isMobile() ? 4 : 1,
            showInput: false,
            navdrop: false,
            memberdrop: false,
            liked: [],
            requests: [],
            chat: [],
            search: '',
            screen: 'main',
            infoscreen: false,
            a: false,
            productscreen: this.isMobile() ? true : false,
            showModal: false,
            searched_user: [],
            group_invites: [],
            active_chats: [],
            name: '',
            description: '',
            preview: '',
            icon: '',
            latitude: '',
            longitude: '',
            loc_label: '',
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            directMessage: {
                name: '',
                social_profile_id: '',
                fromCryptid: '',
                message: ''
            },
            is_private: false,
            loading: true,
            text: this.language.loading,
            cloading: true,
            ctext: this.language.loading,
            msg_to_send: '',
            networks: [
                { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
            sharing: {
                url: '',
                title: '',
                description: '',
                hashtags: 'thrift,sell,trade,exchange',
            },
            filter_data: new Form({
                sort_by: 'newest',
                type: [],
                distance: 0,
                price: [0, ''],
                date_posted: 0,
                cat: [],
                subcat: [],
            }),
            filter_applied: false,
            max_price: '',
            am_admin: false,
        };
    },
    created() {
        if (this.token != null) this.index()
        this.emitter.on('removeModal', () => {
            this.showModal = false
        })
        this.emitter.on("view_more", () => {
            if(this.$route.name == 'Group') {
                this.view_more()
            }
        });
        this.emitter.on("remove_product", (id) => {
            if(this.$route.name == 'Group') {
                this.products.splice(id, 1)
            }
        });
    },
    watch: {
        search(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.search_user()
            }
        },
        search_string(newVal, oldVal) {
            if (oldVal && !newVal && this.searched) {
                this.searched = false
                this.c_page = 1
                this.products = []
                this.get_products()
            }
        },
    },
    computed: {
        comp_cats() {
            var data = []
            this.categories.forEach(cat => {
                var d = {
                    value: cat.id,
                    label: this.language[cat.text],
                }
                data.push(d)
            })
            return data
        },
        comp_subcats() {
            var data = []
            this.categories.forEach(cat => {
                if(this.filter_data.cat.includes(cat.id)) {
                    cat.sub_categories.forEach(item => {
                        var d = {
                            value: item.id,
                            label: this.language[item.text],
                        }
                        data.push(d)
                    })
                }
            })
            return data.sort()
        },
        filter_dates() {
            return [{ value:7, label: this.language.last_7_days }, { value:15, label: this.language.last_15_days }, { value:30, label: this.language.last_30_days}, { value:0, label: this.language.all_time }]
        },
        filter_price() {
            return [{ value:{min:0,max:0}, label: 'Free' }, { value:{min:0,max:1000}, label: 'Less than '+ this.price(1000) }, { value:{min:0,max:5000}, label: 'Less than '+ this.price(5000)}, { value:{min:10000,max:20000}, label: this.price(10000)+' - '+this.price(20000) }, { value:{min:20000,max:30000}, label: this.price(20000)+' - '+this.price(30000) },  { value:'Any', label: 'Any' },]
        },
        ttypes() {
            return [{ value: 1, label: this.language.trade }, { value: 2, label: this.language.sell }, { value: 3, label: this.language.free }, { value: 4, label: this.language.rent }, { value: 5, label: this.language.buy }, { value: 6, label: this.language.service }] ;
        },
    },
    mounted() {
        this.debouncedSearch = this.$debounce(this.search_page, 500);

        this.emitter.on('get-np', (e) => {
            if(e.type == 'group') {
                var index = this.pids.indexOf(e.id)
                if((index+1) == this.pids.length - 3) this.view_more()
                var obj = { n: this.pids[index+1], p: this.pids[index-1]}
                this.$nextTick(() => {
                    this.emitter.emit('set-np', obj)
                })
            }
        })
    },
    updated() {
        this.tooltip_fun()
        $('.chat_container').animate({ scrollTop: $('.chat_container').prop("scrollHeight") }, 500);
    },
    beforeUnmount() {
        this.emitter.emit('product_add', '/p/add/')
    },
    methods: {
        async index() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()

            const q = query(collection(db, "groups"), where("groupId", "==", this.id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.data = doc.data();
                this.emitter.emit('product_add', ('/p/add/' + this.data.groupId))
            });
            await this.get_products(config) ;

            await axios.get(this.$api + "groups/get-group/" + this.id, config).then((res) => {
                this.group = res.data.group
                this.name = this.group.name
                this.description = this.group.description
                this.latitude = this.group.latitude
                this.longitude = this.group.longitude
                this.loc_label = this.group.loc_label
                this.preview = this.group.icon_blob ? this.$s3_url+this.group.icon_blob : ''
                this.sharing.url =  this.$domain + '/group/invite/' + this.group.invite_code
                this.sharing.title = `${this.name} Ads\n`

                this.is_private = this.group.is_private == 1 ? true : false
            }).catch((err) => { console.log(err); this.$Progress.fail() });

            //For chat
            const docRef = doc(db, "groups", this.id);
            const colRef = query(collection(docRef, 'messages'), orderBy("timestamp", "asc"))
            const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
                this.chat = []
                querySnapshot.forEach((doc) => {
                    this.chat.push(doc.data());
                });
                this.$nextTick(() => {
                    $('.chat_container').animate({ scrollTop: $('.chat_container').prop("scrollHeight") }, 500);
                })
            });
            setTimeout(() => {
                this.cloading = false;
                this.ctext = this.language.start_typing;
            }, 1000);

            await axios.get(this.$api + "groups/get-members/" + this.id, config).then((res) => {
                if (!res.status) {
                    toast.fire({ 'icon': 'error', 'title': this.language.toast_you_are_not_member_of_community})
                    this.$router.push('/groups') ;
                }
                this.members = res.data
                if(this.members.members.findIndex(i => i.user_id == this.user.user.id) < 0) {
                    toast.fire({ 'icon': 'error', 'title': this.language.toast_you_are_not_member_of_community})
                    this.$router.push('/groups') ;
                }
                this.am_admin = this.members.members.find(i => i.user_details.social_profile_id == this.user.user.social_profile_id).role == 1 ? true : false
            }).catch((err) => { this.$Progress.fail() });
            await axios.get(this.$api + "groups/join-requests/" + this.id, config).then((res) => {
                this.requests = res.data.requests
            }).catch((err) => { this.$Progress.fail() });

            this.$Progress.finish()
        },
        async get_products() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.text = this.language.loading
            this.loading = true
            var data = {
                'group': this.id,
                'string': this.search_string,
                'filter_data': this.filter_data,
            }

            axios.post(this.$api + "groups/get-products"+"?page="+this.c_page, data, config).then((res) => {
                this.$Progress.finish()
                this.text = this.language.no_products_to_display
                this.loading = false

                var resdata = Object.values(res.data.items.data)

                if(this.c_page != 1) {
                    this.products = [...this.products, ...resdata];
                    var items = resdata
                    this.pids = [...this.pids, ...items.map(e => e.slug)]
                }
                else {
                    this.products = resdata
                    this.pids = resdata.map(e => e.slug)
                }

                this.l_count = resdata.length
                this.liked = res.data.liked_items
                this.max_price = res.data.max_price
                if(!this.filter_data.price[1])
                this.filter_data.price[1] = res.data.max_price
                this.active_chats = res.data.active_chats
            }).catch((e) => {
                console.log(e);
                this.$Progress.fail()
                this.loading = false
                this.text = this.language.server_error
            });
        },
        view_more() {
            if(this.l_count > 0) {
                this.c_page++
                this.get_products()
            }
        },
        changeShowInput() {
            this.showInput = !this.showInput
        },
        search_page() {
            if(this.search_string.length > 1){
                this.$refs.search_field.focus()
                this.searched = true
                this.c_page = 1
                this.products = []
                this.get_products()
            }
        },
        async delete_group() {
            var thiss = this
            Swal.fire({
                title: this.language.toast_are_you_sure,
                text: this.language.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.language.toast_yes_delete,
                cancelButtonText: this.language.cancel,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(async function (result) {
                if (result.value) {
                    const q = query(collection(db, "groups"), where("groupId", "==", thiss.data.groupId));
                    const querySnapshot = await getDocs(q);
                    var curr_grp;
                    querySnapshot.forEach((doc) => {
                        curr_grp = doc.data();
                    });
                    curr_grp.members.forEach((ele) => {
                        updateDoc(doc(db, 'users', ele), {
                            "groups": arrayRemove(thiss.data.groupId)
                        })
                    })
                    deleteDoc(doc(db, 'groups', thiss.data.groupId))
                    var config = { headers: { Authorization: `Bearer ${thiss.token}` } }
                    thiss.$Progress.start()
                    axios.get(thiss.$api + 'groups/delete/' + thiss.data.groupId, config).then((res) => {
                        thiss.$Progress.finish()
                        Swal.fire({
                            icon: 'success',
                            title: thiss.language.toast_deleted,
                            text: thiss.language.toast_group_deleted,
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        thiss.$router.push('/groups')
                    }).catch((err) => {
                        thiss.$Progress.fail();
                        toast.fire({
                            'icon': 'error',
                            'title': thiss.language.server_error,
                        });
                    })
                }
            });
        },
        search_user() {
            if (this.search.length > 0) {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.post(this.$api + "groups/invite-search", { 'string': this.search, 'firebase_group_id': this.data.groupId }, config).then((res) => {
                    this.$Progress.finish()
                    this.searched_user = res.data.results
                    this.group_invites = res.data.group_invites
                }).catch((e) => {
                    this.$Progress.fail()
                });
            }
        },
        async invite_user(su) {
            if (this.group_invites.includes(su.id)) {
                toast.fire({
                    'icon': 'info',
                    'title': this.language.toast_user_already_invited,
                });
                return ;
            }
            this.buttonLoading = 'invite'
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            await axios.post(this.$api + "groups/invite", { 'email': su.email, 'firebase_group_id': this.data.groupId }, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_user_invited,
                });
                this.group_invites.push(su.id)
                if(this.searched_user.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
                toast.fire({
                    'icon': 'error',
                    'title': this.language.server_error,
                });
            });
            this.buttonLoading = false
        },
        getAddressData(placeResultData) {
            const place = JSON.parse(JSON.stringify(placeResultData))
            this.latitude = place.geometry.location.lat
            this.longitude = place.geometry.location.lng
            this.loc_label = place.formatted_address
        },
        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
        },
        async locationiq(coords) {
            if(this.flag) {
                this.latitude = coords.latitude ;
                this.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get("https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" + this.latitude + "&lon=" + this.longitude + "&format=json").then((res) => {
                    this.loc_label = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
        async update_group() {
            this.buttonLoading = 'update'

            if(!(this.latitude && this.longitude)) {
                toast.fire({'icon' : 'info', 'title': this.language.toast_select_location_first });
                this.buttonLoading = false
                return ;
            }

            const colRef = collection(db, 'groups')
            const nameDocs = await getDocs(query(colRef, where("groupName", "==", this.name), where('admin', '==', this.user.user.social_profile_id)))
            if(this.name != this.data.groupName && !nameDocs.empty) {
                this.errors.name = [this.language.groupname_taken];
                this.buttonLoading = false
                return ;
            }
            this.errors = []

            this.$Progress.start()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var fd = new FormData()
            fd.append('name', this.name)
            fd.append('description', this.description)
            fd.append('icon', this.icon)
            fd.append('latitude', this.latitude)
            fd.append('longitude', this.longitude)
            fd.append('loc_label', this.loc_label)
            fd.append('is_private', this.is_private ? 1 : 0)
            fd.append('firebase_group_id', this.data.groupId)
            await axios.post(this.$api + 'groups/update', fd, config).then(async (res) => {
                await updateDoc(doc(db, 'groups', this.data.groupId), {
                    "groupName": this.name,
                    "description": this.description,
                    "is_private": this.is_private,
                    "icon": res.data.path,
                })
                this.$Progress.finish()
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_group_updated_success,
                });
                this.index()
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail()
                toast.fire({
                    'icon': 'error',
                    'title': this.language.server_error,
                });
            });
            this.buttonLoading = false
        },
        async leave_group(uid, spid, type) {
            var id = this.data.groupId
            var thiss = this
            var str = (type == 'leave') ? this.language.i_am_leaving : this.language.remove
            var str2 = (type == 'leave') ? this.language.toast_group_left_success : this.language.toast_member_removed_success
            Swal.fire({
                title: this.language.toast_are_you_sure,
                text: this.language.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: str,
                cancelButtonText: this.language.cancel,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(async function (result) {
                if (result.value) {
                    thiss.buttonLoading = 'leave'
                    await updateDoc(doc(db, 'groups', id), {
                        "members": arrayRemove(spid)
                    })
                    await updateDoc(doc(db, 'users', spid), {
                        "groups": arrayRemove(id)
                    })
                    var fd = {
                        firebase_group_id: id,
                        user_ids: [uid],
                    }
                    var config = { headers: { Authorization: `Bearer ${thiss.token}` } }
                    thiss.$Progress.start()
                    await axios.post(thiss.$api + 'groups/remove-members', fd, config).then((res) => {
                        thiss.$Progress.finish()
                        Swal.fire({
                            icon: 'success',
                            title: thiss.language.toast_leaved,
                            text: str2,
                            customClass: { confirmButton: 'btn btn-success' }
                        });
                        if (type == 'leave') thiss.$router.push('/groups')
                        else thiss.index()
                    }).catch((err) => {
                        thiss.$Progress.fail();
                        toast.fire({
                            'icon': 'error',
                            'title': thiss.language.server_error,
                        });
                    })
                    thiss.buttonLoading = false
                }
            });
        },
        async approve(id, uid) {
            this.buttonLoading = 'approve_request'
            await updateDoc(doc(db, 'groups', this.data.groupId), {
                "members": arrayUnion(uid)
            })
            await updateDoc(doc(db, 'users', uid), {
                "groups": arrayUnion(this.data.groupId)
            })
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.groupId,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/approve-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_accepted,
                })
                this.index()
                if(this.requests.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },
        async reject(id) {
            this.buttonLoading = 'reject_request'
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.groupId,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/delete-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_rejected,
                })
                this.index()
                if(this.requests.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },
        send_message() {
            if (this.msg_to_send) {
                $('#input_send').removeClass('form-control border border-danger is-invalid');
                const docRef = doc(db, "groups", this.id);
                const colRef = collection(docRef, 'messages')

                var str = Date.now().toString();
                setDoc(doc(colRef, str), {
                    "idFrom": this.user.user.social_profile_id,
                    "content": this.msg_to_send,
                    "timestamp": Date.now().toString(),
                    "sender": this.user.user.first_name,
                    "fromCryptid": this.user.user.crypt_id,
                    "type": 0
                })
                updateDoc(doc(db, 'groups', this.id), {
                    "recentMessage": this.msg_to_send,
                    "recentMessageSender": this.user.user.first_name,
                    "recentMessageTime": Date.now().toString(),
                })
                this.msg_to_send = ''
                $('.chat_container').animate({ scrollTop: $('.chat_container').prop("scrollHeight") }, 500);
            }
            else {
                $('#input_send').addClass('form-control border border-danger is-invalid me-2');
            }
        },
        change_productscreen(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.productscreen = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        },
        change_infoscreen(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.infoscreen = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        },
        change_showModal(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.showModal = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
            if(id == 'search')
                this.$nextTick(() => this.$refs.inputSearch.focus())
        },
        scroll_to_products() {
            $('#products_card')[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        async directMessageSend() {
            if (this.directMessage.message == '') return

            this.buttonLoading = 'send_message'
            var chk = query(doc(db, "messages", this.directMessage.social_profile_id.id + "-" + this.user.user.social_profile_id));
            var docSnap = await getDoc(chk);
            var docid = ''
            if (docSnap.exists()) {
                docid = this.directMessage.social_profile_id + "-" + this.user.user.social_profile_id;
            } else {
                docid = this.user.user.social_profile_id + "-" + this.directMessage.social_profile_id
            }

            const docRef = doc(db, "messages", docid);
            const colRef = collection(docRef, docid)
            var msg = this.directMessage.message
            var str = Date.now().toString();
            setDoc(doc(colRef, str), {
                "idFrom": this.user.user.social_profile_id,
                "idTo": this.directMessage.social_profile_id,
                "content": this.directMessage.message,
                "imageBlob": null,
                "timestamp": str,
                "type": 0,
                "seen": 0
            })
            setDoc(docRef, {
                'recentMessage': this.directMessage.message,
                "timestamp": str,
                'isDisabled': false,
                'blocked_by': '',
            })
            var chat_data = {
                user: this.directMessage.social_profile_id,
                user_crypt: this.directMessage.fromCryptid,
                product_id: 0,
                chat_id: docid,
            }

            await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                "chats": arrayUnion(chat_data)
            })

            var rev_chat_data = {
                user: this.user.user.social_profile_id,
                user_crypt: this.user.user.crypt_id,
                product_id: 0,
                chat_id: docid,
            }

            await updateDoc(doc(db, 'users', this.directMessage.social_profile_id), {
                "chats": arrayUnion(rev_chat_data)
            })
            this.buttonLoading = false
            this.$Progress.finish()
            toast.fire({
                'icon': 'success',
                'title': this.language.toast_message_sent,
            })
            this.$router.push('/chats')
        },
        reply_private(s, i, f) {
            this.directMessage.name = s;
            this.directMessage.social_profile_id = i;
            this.directMessage.fromCryptid = f;
            this.showModal = 'messageDirect';
        },
        async resetLink() {
            this.$Progress.start()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + "groups/reset-invite-code/" + this.id, config).then((res) => {
                this.group = res.data.group
                toast.fire({
                    'icon': 'success',
                    'title': this.language.link_reset,
                })

            }).catch((err) => { console.log(err); this.$Progress.fail() });
            this.$Progress.finish()

        },
        closeDropdown() {
            this.navdrop = false
        },
        closeMember() {
            this.memberdrop = false
        },
        filterShow() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.$nextTick(() => this.showModal = 'filters')
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
        },
        apply_filter() {
            this.showModal = false;
            this.c_page = 1;
            this.filter_applied = true
            this.products = []
            this.get_products();
        },
        reset_filter() {
            this.showModal = false;
            this.filter_data.reset()
            this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000
            this.filter_data.price[0] = 0
            this.filter_data.price[1] = ''
            this.c_page = 1;
            this.filter_applied = false;
            this.products = []
            this.get_products();
        },
        price(val) {
            var formatter = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                maximumFractionDigits: 0,
            });
            return formatter.format(val);
        },
        async make_admin(id, uspid, type) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()

            if(type == 'add') {
                this.buttonLoading = 'make_admin'
                await updateDoc(doc(db, "groups",this.id), { "admins": arrayUnion(uspid) })
            }
            else {
                this.buttonLoading = 'remove_admin'
                await updateDoc(doc(db, "groups",this.id), { "admins": arrayRemove(uspid) })
            }

            await axios.post(this.$api + "groups/make-admin", { 'id': id, 'type': type }, config).then((res) => {
                this.$Progress.finish();
                if(type == 'add')
                toast.fire({ 'icon': 'success', 'title': this.language.toast_make_admin });
                else if(type == 'remove')
                toast.fire({ 'icon': 'success', 'title': this.language.toast_remove_admin });

                var index = this.members.members.findIndex(i => i.id == id)
                this.members.members[index].role = (type == 'add' ? 1 : 2)

            }).catch((e) => {
                this.$Progress.fail();
                toast.fire({'icon': 'error', 'title': this.language.server_error });
            });
            this.buttonLoading = false
        },
        async uploadphoto(e) {
            var fi = e.target.files[0];
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 200,
                useWebWorker: true,
            }
            const compressed_File = await imageCompression(fi, options);
            this.preview = URL.createObjectURL(compressed_File)
            this.icon = compressed_File
            this.$refs.images.value = null
        },
        tooltip_fun() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        }
    },
}
</script>
