<template>
    <div class="row">
        <div class="col-12">
            <div v-if="group" class="features-card">
                <div class="card-header flex-bc">
                    <button type="button" class="main-button small me-1" @click="goback()"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <div class="header-container">
                        <h3><span v-if="false">{{language.group}}: </span><b>{{ group.name }}</b></h3>
                    </div>
                    <transition-group name="fade">
                        <div key="search" class="position-relative me-1 me-lg-2" :class="mobile ? 'w-40' : 'w-25'">
                            <input type="search" ref="search_field" v-model="search_string" @keyup.enter="search_page" class="form-control" :placeholder="language.type_min_3" required>
                            <button type="button" @click="search_page" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                        </div>
                        <button key="filter_data" aria-label="filter_data" data-toggle="tooltip" :title="language.heading_sort_filter" type="button" @click="filterShow" :class="{'light' : !filter_applied}" class="main-button small search_button me-1 me-lg-2"><i class="fas fa-15x fa-filter"></i></button>
                        <button type="button" key="join" v-if="!group.is_requested.length && is_requested==false" class="main-button small" @click="joinNow()">
                            <transition name="fade">
                                <i class="fas me-1" :class="buttonLoading ? 'fa-spinner fa-spin' : 'fa-sign-in-alt'" :key="buttonLoading"></i>
                            </transition>
                            {{ language.join_now }}
                        </button>
                        <button type="button" class="main-button small" v-if="is_requested">{{ language.approval_pending }}</button>
                    </transition-group>
                </div>
                <div class="card-body">
                    <div class="col-12 d-flex flex-column">
                        <div v-if="routename == 'GroupInviteLink'" :class="mobile ? 'rounded-4' : 'rounded-5'" class="alert alert-primary flex-bc flex-column flex-md-row">
                            <span class="flex-bc">
                                <!-- <i class="fas fa-heart fa-15x me-1" v-if="!mobile"></i> -->
                                <span class="fa-15x" v-if="!mobile">🎉</span>
                                <span :class="mobile ? 'fs-6' : 'fs-5'" class="mb-2 mb-md-0"><b>{{ language.group_invited }}!</b></span>
                            </span>
                            <div class="d-flex">
                                <button type="button" class="main-button small outline me-2 me-lg-1">{{ language.members }}: {{group.members_count}}</button>
                                <button type="button" class="main-button small outline">{{ language.advertisements }}: {{group.products_count}}</button>
                            </div>
                        </div>
                        <transition-group name="fade">
                            <transition-group v-if="!data.is_private" appear key="ads" tag="div" class="pcont" name="bounce">
                                <Product :active_chats="active_chats" :class="index == (products.length - last_item) ? 'last_item' : ''" :lang="language" class="product-block-card mb-3" display_type="card" :type="'?type=group&id='+group.firebase_group_id" :user="user" :product="product" :liked_array="liked" :can_like="user.user && product.user_id == user.user.id ? false : true" :can_del="user.user && product.user_id == user.user.id ? true : false" v-for="(product,index) in products" :key="product.id" />
                            </transition-group>
                            <div key="private" :class="mobile ? 'rounded-4' : 'rounded-5'" class="flex-cc flex-column py-5" v-else>
                                <i class="fas text-primary fa-exclamation-circle fa-5x my-3"></i>
                                <h1 class="card-title text-primary text-center my-3">
                                    {{ group.is_requested.length ? language.group_is_private.split('.')[0] : language.group_is_private }}
                                </h1>
                                <button type="button" :disabled="buttonLoading" v-if="!group.is_requested.length  && is_requested==false" class="main-button" @click="joinNow()">
                                    <transition name="fade">
                                        <i class="fas me-1" :class="buttonLoading ? 'fa-spinner fa-spin' : 'fa-sign-in-alt'" :key="buttonLoading"></i>
                                    </transition>
                                    {{ language.join_now }}
                                </button>
                                <button type="button" class="main-button" v-if="is_requested">{{ language.approval_pending }}</button>
                            </div>
                            <NotFound key="loading" :lang="language" :type="text" :load="loading" v-if="!data.is_private && (loading || products.length == 0)"></NotFound>
                        </transition-group>
                    </div>
                </div>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'filters'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="apply_filter" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.heading_sort_filter }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row match-height">
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.sort_by }}</h5>
                                            <ul class="ps-3 mb-4">
                                                <label class="radio-container small">{{ language.text_newest }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="newest">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_distance }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="distance">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_az }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-az">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_za }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-za">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_lth }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-lth">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_price_htl }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="price-htl">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <span class="mt-auto">
                                            <h5>{{ language.filter_by_cats }}</h5>
                                            <div class="col-12">
                                                <label class="ms-3 mb-1">{{ language.category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.cat" :options="comp_cats" />
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-lg-4">
                                        <span class="order-2 order-md-1">
                                            <h5>{{ language.filter_by_type }}</h5>
                                            <ul class="ps-3 pb-3">
                                                <label class="checkbox-container small" v-for="(t,ind) in ttypes" :key="ind">{{t.label}}
                                                    <input type="checkbox" v-model="filter_data.type" :value="t.value">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                        <transition name="fade">
                                            <div class="col-12 order-1 order-md-2 d-flex flex-column justify-content-end" v-if="filter_data.cat.length > 0">
                                                <label class="ms-3 mb-1">{{ language.sub_category }}</label>
                                                <v-select multiple :clearable="false" :reduce="(option) => option.value" class="v-form-control unbordered" v-model="filter_data.subcat" :options="comp_subcats" />
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.filter_by_date }}</h5>
                                            <div class="col-12">
                                                <v-select :clearable="false" :reduce="(option) => option.value" class="v-form-control" v-model="filter_data.date_posted" :options="filter_dates" />
                                            </div>
                                        </span>
                                        <span class="mt-5 mb-3 mb-md-0">
                                            <h5>{{ language.filter_by_price }}</h5>
                                            <Slider tooltipPosition="bottom" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="0" :format="price" :max="max_price" v-model="filter_data.price" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button aria-label="{{language.reset}}" type="button" class="main-button light me-2" @click="reset_filter">{{language.reset}}</button>
                                <button aria-label="{{language.apply}}" type="submit" class="main-button ms-2">{{ language.apply }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.2s reverse;
    }
    .fade-enter-active {
        transition: all 0.3s ease-in;
    }
    .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        position: absolute;
        opacity: 0;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.03);
        }
        100% {
            transform: scale(1);
        }
    }
    .slide-fade-enter-active, .slide-fade-leave-active {
        transition: all 0.5s ease-in;
    }

    .slide-fade-enter-from, .slide-fade-leave-to {
        position: absolute;
        transform: translateX(-20%);
        opacity: 0;
    }
</style>
<script setup>
    import { collection, getDocs, query, where, addDoc, updateDoc, deleteDoc, arrayUnion,arrayRemove, doc } from "firebase/firestore";
</script>
<script>
    export default {
        name: 'GroupInviteLink',
        props: ['user','language','categories'],
        data() {
            return {
                token: this.isLogged(),
                mobile: this.isMobile(),
                id: this.$route.params.id,
                group: null,
                buttonLoading: false,
                data: [],
                products: [],
                c_page:1,
                l_count:0,
                searched: false,
                last_item: this.isMobile() ? 4 : 1,
                liked: [],
                active_chats: [],
                pids: [],
                showInput: false,
                filter_applied: false,
                loading: true,
                text: this.language.loading,
                routename: '',
                showModal: false,
                search_string: '',
                filter_data: new Form({
                    sort_by: 'newest',
                    type: [],
                    distance: 0,
                    price: [0, ''],
                    date_posted: 0,
                    cat: [],
                    subcat: [],
                }),
                clicked: false,
                is_requested: false,
                max_price: '',
            };
        },
        computed: {
            chkShowJoinNow(){
                if (this.token) {
                    if (this.data && this.data.members && this.data.members.includes(this.user.user.social_profile_id)) {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else{
                    return true
                }
            },
            ttypes() {
                return [{ value: 1, label: this.language.trade }, { value: 2, label: this.language.sell }, { value: 3, label: this.language.free }, { value: 4, label: this.language.rent }, { value: 5, label: this.language.buy }, { value: 6, label: this.language.service }] ;
            },
            comp_cats() {
                var data = []
                this.categories.forEach(cat => {
                    var d = {
                        value: cat.id,
                        label: this.language[cat.text],
                    }
                    data.push(d)
                })
                return data
            },
            comp_subcats() {
                var data = []
                this.categories.forEach(cat => {
                    if(this.filter_data.cat.includes(cat.id)) {
                        cat.sub_categories.forEach(item => {
                            var d = {
                                value: item.id,
                                label: this.language[item.text],
                            }
                            data.push(d)
                        })
                    }
                })
                return data.sort()
            },
            filter_dates() {
                return [{ value:7, label: this.language.last_7_days }, { value:15, label: this.language.last_15_days }, { value:30, label: this.language.last_30_days}, { value:0, label: this.language.all_time }]
            },
            filter_price() {
                return [{ value:{min:0,max:0}, label: 'Free' }, { value:{min:0,max:1000}, label: 'Less than '+ this.price(1000) }, { value:{min:0,max:5000}, label: 'Less than '+ this.price(5000)}, { value:{min:10000,max:20000}, label: this.price(10000)+' - '+this.price(20000) }, { value:{min:20000,max:30000}, label: this.price(20000)+' - '+this.price(30000) },  { value:'Any', label: 'Any' },]
            },
        },
        watch: {
            search_string(newVal, oldVal) {
                if (oldVal && !newVal && this.searched) {
                    this.searched = false
                    this.c_page = 1
                    this.products = []
                    this.get_products()
                }
            },
        },
        created() {
            this.routename = this.$route.name
            this.index()
            this.emitter.on("update_user", (data) => {
                if(this.routename == 'GroupInviteLink' || this.routename == 'GroupInfo') {
                    this.token = this.isLogged();
                    this.index() ;
                }
            });
            this.emitter.on("loggedOut", () => {
                if(this.$route.name == 'GroupInviteLink') {
                    this.token = null
                    this.products = []
                }
            });
            this.emitter.on("view_more", () => {
                if(this.$route.name == 'GroupInviteLink' || this.$route.name == 'GroupInfo') {
                    this.view_more()
                }
            });
        },
        mounted() {
            this.debouncedSearch = this.$debounce(this.search_page, 500);
                    
            this.emitter.on('get-np', (e) => {
                if(e.type == 'group') {
                    var index = this.pids.indexOf(e.id)
                    if((index+1) == this.pids.length - 3) this.view_more()
                    var obj = { n: this.pids[index+1], p: this.pids[index-1]}
                    this.$nextTick(() => {
                        this.emitter.emit('set-np', obj)
                    })
                }
            })
        },
        methods: {
            async index() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                await axios.get(this.$api + "groups/invite-link-details/"+this.routename+"/" + this.id, config).then((res) => {
                    if (res.data.status == 'already_member') {
                        this.$router.push('/group/'+res.data.group.firebase_group_id)
                        return ;
                    }
                    if (res.data.status == 'not_found') {
                        this.$router.push('/404')
                        return ;
                    }
                    // toast.fire({ 'icon': 'info', 'title': 'Joining Now' })
                    if(this.clicked) this.joinNow()
                    this.group = res.data.group
                    if(this.group.is_requested.length > 0) {
                        this.is_requested = true
                    }
                    this.$Progress.finish()
                }).catch((err) => { console.log(err); this.$Progress.fail() });

                const q = query(collection(db, "groups"), where("groupId", "==", this.group.firebase_group_id) );
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    this.data = doc.data();
                });

                if(!this.data.is_private) {
                    if(this.group.products_count > 0)
                        this.get_products()
                    else {
                        this.text = this.language.no_products_to_display
                        this.loading = false
                    }
                }
                else {
                    this.loading = false
                }
            },
            search_page() {
                if(this.search_string.length > 1){
                    this.$refs.search_field.focus()
                    this.searched = true
                    this.c_page = 1
                    this.products = []
                    this.get_products()
                }
            },
            get_products() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                this.loading = true
                this.text = this.language.loading

                var data = {
                    'group': this.group.firebase_group_id,
                    'string': this.search_string,
                    'filter_data': this.filter_data,
                }
                axios.post(this.$api + "groups/get-products"+"?page="+this.c_page, data, config).then((res) => {

                    if(res.data?.status == 'failed') {
                        this.loading = false
                        this.text = this.language.invalid_group_id
                        return ;
                    }

                    this.$Progress.finish()
                    this.text = this.language.no_products_to_display
                    this.loading = false

                    var resdata = Object.values(res.data.items.data)

                    if(this.c_page != 1) {
                        this.products = [...this.products, ...resdata];
                        var items = resdata
                        this.pids = [...this.pids, ...items.map(e => e.slug)]
                    }
                    else {
                        this.products = resdata
                        this.pids = resdata.map(e => e.slug)
                    }

                    this.l_count = resdata.length
                    this.liked = res.data.liked_items
                    this.max_price = res.data.max_price
                    if(!this.filter_data.price[1])
                    this.filter_data.price[1] = res.data.max_price
                    this.active_chats = res.data.active_chats
                }).catch((e) => {
                    console.log(e);
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
            view_more() {
                if(this.l_count > 0) {
                    this.c_page++
                    this.get_products()
                }
            },
            async joinNow() {
                if(this.token) {
                    this.buttonLoading = true
                    if(this.routename == 'GroupInfo' && this.group.is_private){
                        await this.request_group()
                    }
                    else{
                        await this.addTogroup()
                    }
                    this.buttonLoading = false
                }
                else {
                    this.clicked = true
                    this.emitter.emit('showlogin')
                }

            },
            async addTogroup() {
                this.$Progress.start() ;

                await updateDoc(doc(db, 'groups', this.data.groupId), {
                    "members": arrayUnion(this.user.user.social_profile_id)
                })
                await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                    "groups": arrayUnion(this.data.groupId)
                })
                var fd = {
                    firebase_group_id: this.data.groupId,
                    user_ids: [this.user.user.id],
                }
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                await axios.post(this.$api + 'groups/add-members',fd,config).then((res) => {
                    toast.fire({
                        'icon': 'success',
                        'title': this.language.toast_group_joined_success,
                    })
                    this.$router.push('/group/'+this.group.firebase_group_id)
                }).catch((e) => {
                    this.$Progress.fail() ;
                })
            },
            async request_group() {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                await axios.get(this.$api + "groups/request-to-join/"+this.data.groupId,config).then((res) => {
                    this.is_requested = true
                    this.$Progress.finish()
                    toast.fire({
                        icon: "success",
                        title: this.language.toast_request_submitted,
                    });

                }).catch((e) => {
                    this.$Progress.fail()
                    toast.fire({
                        icon: "error",
                        title: this.language.toast_error_occured,
                    });
                });
            },
            filterShow() {
                $('[data-toggle="tooltip"]').tooltip('dispose')
                this.$nextTick(() => this.showModal = 'filters')
                this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
            },
            apply_filter() {
                this.showModal = false;
                this.c_page = 1;
                this.filter_applied = true
                this.products = []
                this.get_products();
            },
            reset_filter() {
                this.showModal = false;
                this.filter_data.reset()
                this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000
                this.filter_data.price[0] = 0
                this.filter_data.price[1] = ''
                this.c_page = 1;
                this.filter_applied = false;
                this.products = []
                this.get_products();
            },
            price(val) {
                var formatter = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    maximumFractionDigits: 0,
                });
                return formatter.format(val);
            },
            goback() {
                this.routename == 'GroupInviteLink' ? this.$router.push('/') : (window.history.state.back ? this.$router.go(-1) : this.$router.push('/'))
            },
        },
    }
</script>
