<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-bc contact-form">
                    <h3>{{ language.products }}</h3>
                    <router-link :to="user.can_post ? 'p/add' : '/checkout?type=new-ads'" v-if="!mobile" class="main-button small ms-auto me-2">{{ language.add_product }}</router-link>
                    <button type="button" class="main-button small me-2" :class="mobile ? 'ms-auto' : ''" @click="showModal = 'share'">
                        <i class="fas fa-share-alt pe-1"></i>
                        {{ language.share_store }}
                    </button>
                    <div key="search" class="position-relative" :class="mobile ? 'w-40' : 'w-25'">
                        <input type="search" ref="search_field" v-model="search" @keyup.enter="search_page" class="form-control" :placeholder="language.type_min_3" required>
                        <button type="button" @click="search_page" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                    </div>
                    <!-- <button key="icon" aria-label="icon" type="button" @click="showInput = !showInput" :class="{'active_search' : showInput}" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                    <input key="search" type="search" ref="search_field" :class="showInput ? 'show_input' : 'p-0'" v-model="search" @input="debouncedSearch" class="form-control small" :placeholder="language.search_products_here" required> -->
                </div>
                <div class="card-body">
                    <transition-group tag="div" class="pcont match-height" name="bounce">
                        <Product :type="'?type=myads'" :lang="language" class="product-block-card" display_type="card" :user="user" :index="index" :product="product" :liked_array="[]" :can_like="true" :can_del="true" v-for="(product,index) in filter_products" :key="product.id" />
                    </transition-group>
                </div>
                <transition name="fade">
                    <NotFound :lang="language" :type="text" :load="loading" v-if="token == null || !filter_products.length"></NotFound>
                </transition>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'share'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.share_text }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row networks-body">
                                    <div class="col-12 mb-3">
                                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                                            <span class="link pe-md-4">{{ $domain+'/u/'+user.user.slug }}</span>
                                            <span class="ms-md-auto">
                                                <button type="button" class="main-button small me-2" @click="$router.push('/u/'+user.user.slug)">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.view }}
                                                </button>
                                                <button type="button" class="main-button small" @click="emitter.emit('copy_url', [$domain+'/u/'+user.user.slug, 'my_store'])">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.copy }}
                                                </button>
                                            </span>
                                        </div>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="col-3 d-flex justify-content-center" v-for="network in networks">
                                        <ShareNetwork
                                            :network="network.network"
                                            :key="network.network"
                                            :style="{backgroundColor: network.color, border: '1px solid '+network.color}"
                                            class="network-icon"
                                            :url="sharing.url"
                                            :title="sharing.title"
                                            :description="sharing.description"
                                            :quote="sharing.quote"
                                            :hashtags="sharing.hashtags"
                                            :twitterUser="sharing.twitterUser"
                                        >
                                            <i :class="network.icon"></i>
                                            <span class="ms-2" v-if="!mobile">{{ network.name }}</span>
                                        </ShareNetwork>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
<script>
    export default {
        name: 'Products',
        props: ['user','language'],
        data() {
            return {
                token: this.isLogged(),
                mobile: this.isMobile(),
                products: [],
                loading: true,
                showModal: false,
                search: '' ,
                text: this.language.loading,
                showInput: false,
                networks: [
                    { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                    { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                    { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                ],
                sharing: {
                    url: '',
                    title: '',
                    description: '',
                    hashtags: 'thrift,sell,trade,exchange',
                },
            };
        },
        created() {
            if(this.token != null) this.index()
            else {
                this.loading = false
                this.text = this.language.login_to_continue
            }
            this.emitter.on("loggedIn", (token) => {
                if(this.$route.name == 'Products') {
                    this.token = token;
                    this.products = []
                    this.index()
                }
            });
            this.emitter.on("remove_product", (id) => {
                if(this.$route.name == 'Products') {
                    this.products.splice(id, 1)
                }
            });
            this.sharing.url =  this.$domain+'/u/'+this.user.user.slug
            this.sharing.title = `${this.user.user.store?.store_name.length > 0 ? this.user.user.store.store_name+' '+this.language.sort_by.split(' ')[1].toLowerCase()+' '+this.user.user.first_name : this.user.user.first_name} | ${this.$appname}\n`
            this.sharing.description = ''

        },
        computed: {
            filter_products() {
                if(this.search) {
                    return this.filter_products.filter(item => {
                        return item.title.toUpperCase().includes(this.search.toUpperCase())
                    })
                }
                else {
                    return this.products
                }
            }
        },
        methods: {
            index() {
                this.loading = true
                this.text = this.language.loading
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.get(this.$api + "products/get-all-products",config).then((res) => {
                    this.$Progress.finish()
                    this.loading = false
                    this.text = this.language.no_products_to_display
                    this.products = res.data.products
                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
        },
    }
</script>