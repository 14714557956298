<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-bc">
                    <transition-group name="fade">
                        <button v-if="groupsSearch.length > 0" type="button" class="main-button small me-1" @click="groupsSearch = []; searchText= '' ;"><i class="fas fa-arrow-left fa-lg"></i></button>
                        <h3 v-if="groupsSearch.length > 0">{{language.search_public_groups}}</h3>
                        <h3 v-else>{{language.groups}}</h3>
                    </transition-group>
                    <div class="col-md-2 ms-4" v-if="groups.length && !mobile">
                        <v-select class="v-form-control" v-model="group_type" :options="['All','Public','Private']"></v-select>
                    </div>
                    <div key="search" class="position-relative ms-auto me-1 me-lg-2" :class="mobile ? 'w-40' : 'w-25'">
                        <input type="search" ref="search_field" v-model="searchText" @keyup.enter="search" class="form-control" :placeholder="language.type_min_3" required>
                        <button type="button" @click="search" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                    </div>
                    <a v-if="token" href="javascript:;" data-toggle="tooltip" :title="language.add_new_group" @click="showModal = 'add'" class="main-button me-1 small"><i class="fas fa-plus fa-15x"></i></a>
                    <a v-if="token" data-toggle="tooltip" :title="language.view_group_invites" href="javascript:;" @click="showModal = 'notif'" class="main-button light me-1 small position-relative"><i class="fas fa-15x fa-bell" :class="invites.length ? 'faa-tada animated' : ''"></i><span v-if="invites.length" class="notif small">{{ invites.length }}</span></a>
                    <!-- <a v-if="token" data-toggle="tooltip" :title="language.view_group_invites" href="javascript:;" @click="showModal = 'notif'" class="main-button light me-1 small position-relative">
                        <i class="fas fa-15x fa-bell" :class="invites.length || urs.length ? 'faa-tada animated' : ''"></i>
                        <span v-if="invites.length || urs.length" class="notif small invert">{{ invites.length+urs.length }}</span>
                    </a> -->
                    <a v-if="token" data-toggle="tooltip" :title="language.view_requests_sent" href="javascript:;" @click="showModal = 'sent'" class="main-button light small"><i class="fas fa-15x fa-share"></i></a>
                </div>
                <div class="card-body">
                    <transition name="fade">
                        <div v-if="groupsSearch.length > 0">
                            <transition-group tag="div" class="row match-height" name="bounce">
                                <group-block :key="g.id" v-for="g in filter_groupsSearch" :language="language" :group="g" :view_only="true" />
                            </transition-group>
                        <NotFound :lang="language" :type="seachLangText" :load="loading" v-if="token == null || !filter_groupsSearch.length"></NotFound>
                        </div>
                        <div v-else>
                            <transition-group tag="div" class="row match-height" name="bounce" v-if="token">
                                <group-block :g_data="g_data" :key="g.groupId" v-for="g in filter_groups" :user="user"
                                :language="language" :group="g" :view_only="false" />
                            </transition-group>
                            <transition name="fade">
                                <NotFound :lang="language" :type="text" :load="loading" v-if="token == null || !groups.length"></NotFound>
                            </transition>
                            <transition name="fade">
                                <div class="row" v-if="nearby_groups.length > 0">
                                    <div class="col-12">
                                        <h4 class="border-bottom pb-1 mb-3 mt-5">{{ language.heading_nearby_groups }}</h4>
                                        <transition-group tag="div" appear class="row match-height" name="bounce">
                                            <group-block :key="g.id" v-for="g in nearby_groups" :class="index == (nearby_groups.length - last_item) ? 'last_item' : ''" :language="language" :group="g" :view_only="true" />
                                        </transition-group>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'add'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="add_group" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.create_group}}</h3>
                                <a href="javascript:;" @click="showModal = false;name = description = icon = latitude = longitude = loc_label = '';is_private = preview = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{language.group_name}}</label>
                                        <input type="text" v-model="name" @input="findSuggestions()" class="form-control" :class="{ 'is-invalid mb-0': errors.name, 'focus mb-0' : existingSuggestions.length > 0 }" required autofocus/>
                                        <span class="invalid-feedback ms-2" role="alert" v-if="errors.name" ><strong>{{ errors.name[0] }}</strong></span>
                                        <transition-group name="slide-down" tag="div" :class="{'focus-dropdown' : existingSuggestions.length > 0}">
                                            <p key="heading" v-if="existingSuggestions.length > 0" class="names mb-0 border-bottom">{{ language.suggestion_existing }}</p>
                                            <span class="names" v-for="(s,sIndex) in existingSuggestions" @click="$router.push('/group/i/'+s.firebase_group_id)" :key="sIndex"><i>{{ s.name }}</i></span>
                                        </transition-group>
                                    </div>
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.group_location }}</label>
                                        <div class="flex-bc gap-2 flex-column flex-md-row">
                                            <div class="col-12 col-md-8">
                                                <GMapAutocomplete @placeholder="language?.search_your_location || 'N/A'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" @class="'mb-4 form-control'" ></GMapAutocomplete>
                                            </div>
                                            <button type="button" @click="current_location" class="main-button mb-4 col-md-4"><i class="fa fa-map-marker-alt me-2"></i>{{language.use_my_current_location}}</button>
                                            <transition name="bounce">
                                                <button key="change" aria-label="change" type="button" v-if="loc_label && mobile" class="main-button light small mb-3">
                                                    <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                                    {{ loc_label }}
                                                </button>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 d-flex flex-column">
                                        <label class="ms-3 mb-1">{{language.is_group_private}}</label>
                                        <div class="custom-control ms-3 custom-switch custom-switch-lg mb-3">
                                            <input type="checkbox" ref="invis" v-model="is_private" :checked="is_private" class="custom-control-input" id="customSwitch2"/>
                                            <label class="custom-control-label text-nowrap" for="customSwitch2"></label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <label class="ms-3 mb-1">{{ language.group_image }}</label>
                                        <div class="input-preview ms-3">
                                            <img v-if="!preview" src="/front/sample.png" class="profile-icon w50">
                                            <img v-else :src="preview" class="profile-icon w50">
                                            <input type="file" ref="images" accept="image/*" @change="uploadphoto" id="update_image">
                                            <label for="update_image">
                                                <i class="fas fa-upload"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <transition name="bounce">
                                        <div class="col-12 col-md-4 flex-cc" v-if="!mobile">
                                            <transition name="bounce">
                                                <button key="change" aria-label="change" type="button" v-if="loc_label" class="main-button light small mb-3">
                                                    <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                                    {{ loc_label }}
                                                </button>
                                            </transition>
                                        </div>
                                    </transition>
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{ language.description }}</label>
                                        <textarea v-model="description" class="form-control h-auto" rows="3" autofocus :class="{ 'is-invalid mb-0': errors.description}"></textarea>
                                        <span class="invalid-feedback ms-2" role="alert" v-if="errors.description" ><strong>{{ errors.description[0] }}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 d-flex justify-content-center">
                                <button type="button" class="main-button light" :class="{'small' : mobile }" @click="showModal = false; name = description = icon = latitude = longitude = loc_label = '';is_private = preview = false;">
                                    {{language.close}}
                                </button>
                                <button type="submit" :disabled="buttonLoading=='add'" class="main-button ms-4" :class="{'small' : mobile }" >
                                    <transition name="fade">
                                        <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='add'"></i>
                                    </transition>
                                    {{ language.save_changes }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'notif' " :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.view_group_invites}}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body">
                                <transition-group tag="div" class="row match-height" name="bounce">
                                    <div class="col-12" v-for="i in invites" :key="i.id">
                                        <div class="group-block small">
                                            <div class="me-2"><div class="group-icon">{{ Array.from(i.group_details.name)[0] }}</div></div>
                                            <div class="d-flex flex-column">
                                                <div class="name ms-2">{{ i.group_details.name }}</div>
                                                <div class="message ms-2">{{ language.admin }}: {{ i.group_details.admins[0].user_details.fullname }}</div>
                                            </div>
                                            <button type="button" :disabled="buttonLoading=='accept' || buttonLoading=='reject'" @click="accept_invite(i.group_details.firebase_group_id, i.user_id)" class="main-button small ms-auto me-2">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='accept' || buttonLoading=='reject'"></i>
                                                </transition>
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button type="button" :disabled="buttonLoading=='accept' || buttonLoading=='reject'" @click="reject_invite(i.group_details.firebase_group_id, i.user_id)" class="main-button small bg-dark">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='accept' || buttonLoading=='reject'"></i>
                                                </transition>
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12" v-for="i in urs" :key="i.id">
                                        <div class="group-block small">
                                            <div class="me-2"><img class="profile-icon w40" :src="$s3_url + i.user_details.thumbnail_image" alt=""></div>
                                            <div class="d-flex flex-column">
                                                <div class="name ms-2">{{ i.user_details.first_name }}</div>
                                                <div class="message ms-2">{{ language.group }}: {{ i.group_details.name }}</div>
                                            </div>
                                            <a href="javascript:;" @click="accept_invite(i.user_id, i.user_details.social_profile_id)" class="main-button small ms-auto me-2"><i class="fas fa-check"></i></a>
                                            <a href="javascript:;" @click="reject_invite(i.user_id)" class="main-button small bg-dark"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </div> -->
                                </transition-group>
                            </div>
                            <NotFound :lang="language" :type="language.no_invitations" :load="false" v-if="!invites.length"></NotFound>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'sent' " :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3 class="text-capitalize">{{language.view_requests_sent}}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body">
                                <transition-group tag="div" class="row match-height" name="bounce">
                                    <div class="col-12" v-for="r in requests" :key="r.id">
                                        <div class="group-block small">
                                            <div><div class="group-icon">{{ Array.from(r.group_details.name)[0] }}</div></div>
                                            <div class="name ms-2">{{ r.group_details.name }}</div>
                                            <button type="button" :disabled="buttonLoading=='cancel'" @click="cancel_request(r.user_id,r.group_details.firebase_group_id)" class="main-button small bg-dark">
                                                <transition name="fade">
                                                    <i class="fas fa-spinner fa-spin me-1" v-if="buttonLoading=='cancel'"></i>
                                                </transition>
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </transition-group>
                            </div>
                            <NotFound :lang="language" :type="language.no_requests" :load="false" v-if="!requests.length"></NotFound>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.2s reverse;
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .slide-down-enter-active {
        transition: all 0.2s ease-in;
    }
    .slide-down-leave-active {
        transition: all 0s ease-in;
    }
    .slide-down-enter-from {
        opacity: 0;
        transform: translateY(20%);
    }
    .slide-down-leave-to {
        opacity: 0;
        transform: translateY(20%);
    }
</style>
<script>
import { collection, getDocs, query, where, addDoc, updateDoc, deleteDoc, arrayUnion,arrayRemove, doc } from "firebase/firestore";
export default {
    name: "Groups",
    props: ["user",'language'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            groups: [],
            groupsSearch: [],
            existingSuggestions: [],
            loading: true,
            buttonLoading: false,
            flag: false,
            text: this.language.loading,
            showModal: false,
            showInput: false,
            name: "",
            description: '',
            preview: '',
            icon: '',
            latitude: '',
            longitude: '',
            loc_label: '',
            searchText: "",
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            seachLangText: this.language.search_public_groups,
            g_data: [],
            invites: [],
            requests: [],
            nearby_groups: [],
            is_private: false,
            currentUser: null,
            errors: [],
            group_type: 'All',
            urs: [],
            last_item: this.isMobile() ? 3 : 1,
            c_page:1,
            l_count:0,
        };
    },
    computed: {
        filter_groups() {
            if(this.group_type == 'All') return this.groups ;
            else return this.groups.filter(item => item.is_private == (this.group_type == 'Public' ? false : true))
        },
        filter_groupsSearch() {
            if(this.group_type == 'All') return this.groupsSearch ;
            else return this.groupsSearch.filter(item => item.is_private == (this.group_type == 'Public' ? false : true))
        }
    },
    async created() {
        if (this.token != null){
            this.index();
            const q = query(collection(db, "users"), where("id", "==", this.user.user.social_profile_id) );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.currentUser = doc.data();
            });
        }
        else {
            this.loading = false;
            this.text = this.language.login_to_continue;
        }
        this.emitter.on("update_user", async (data) => {
            if(this.$route.name == 'Groups') {
                this.token = this.isLogged();
                this.index(data.user.social_profile_id)
                const q = query(collection(db, "users"), where("id", "==", data.user.social_profile_id) );
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    this.currentUser = doc.data();
                });
            }
        });
        this.emitter.on("loggedOut", () => {
            if(this.$route.name == 'Groups') {
                this.token = null
                this.groups = []
            }
        });
        this.emitter.on('removeModal', () => {
            this.showModal = false
        })
        if(!this.mobile)
        $('[data-toggle="tooltip"]').tooltip()
    },
    mounted() {
        this.debouncedSearch = this.$debounce(this.search, 500);
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    watch: {
        showInput(newVal, oldVal) {
            if(newVal) this.$nextTick(() => { this.$refs.search_field.focus() })
        },
    },
    methods: {
        getAddressData(placeResultData) {
            const place = JSON.parse(JSON.stringify(placeResultData))
            this.latitude = place.geometry.location.lat
            this.longitude = place.geometry.location.lng
            this.loc_label = place.formatted_address
        },
        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
        },
        async locationiq(coords) {
            if(this.flag) {
                this.latitude = coords.latitude ;
                this.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get("https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" + this.latitude + "&lon=" + this.longitude + "&format=json").then((res) => {
                    this.loc_label = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
        async index1() {
            const q = query(collection(db, "groups"));
            const querySnapshot = await getDocs(q);
            var temp = []
            querySnapshot.forEach((doc) => {
                temp.push(doc.data()) ;
            });
            temp.forEach(async (item) => {
                if(item.admins == undefined) {
                    await updateDoc(doc(db, "groups", item.groupId), {
                        "admins": arrayUnion(item.admin),
                        // "admin": deleteField()
                    })
                }
            });
        },
        async index(id = null) {
            this.loading = true;
            this.text = this.language.loading;
            this.groups = []
            if(id) {
                const q = query(collection(db, "groups"),where("members", "array-contains", id));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => { this.groups.push(doc.data()); });
            }
            else {
                const q = query(collection(db, "groups"),where("members", "array-contains", this.user.user.social_profile_id));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => { this.groups.push(doc.data()); });
            }

            this.$Progress.start();
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + 'groups/join-invitations', config).then((res) => {
                this.$Progress.finish();
                this.invites = res.data.invitations
                this.urs = res.data.requests
            }).catch((err) => { this.$Progress.fail(); });
            this.$Progress.start();
            await axios.get(this.$api + 'groups/user-group-requests', config).then((res) => {
                this.$Progress.finish();
                this.requests = res.data.requests
            }).catch((err) => { this.$Progress.fail(); });
            this.$Progress.start();
            await axios.get(this.$api + 'groups/get-all', config).then((res) => {
                this.$Progress.finish();
                this.g_data = res.data.groups
            }).catch((err) => { this.$Progress.fail(); });
            this.loading = false;
            this.text = this.language.no_groups_to_display;

            await this.nearbyGroups()
        },
        async nearbyGroups() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + 'groups/nearby-groups?page=1', config).then((res) => {
                this.nearby_groups = res.data.groups.data
            }).catch((err) => { this.$Progress.fail(); });
        },
        async delete_group(gid){
            const q = query(collection(db, "groups"), where("groupId", "==", gid));
            const querySnapshot = await getDocs(q);
            var curr_grp;
            querySnapshot.forEach((doc) => {
                curr_grp = doc.data();
            });
            curr_grp.members.forEach((ele) => {
                updateDoc(doc(db, 'users', ele), {
                    "groups": arrayRemove(gid)
                })
            })
            deleteDoc(doc(db, 'groups',gid))
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            axios.get(this.$api + 'groups/delete/'+gid, config).then((res) => {
                console.log(res);
                this.$Progress.finish()
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_group_deleted,
                });
                this.index()
            }).catch((err) => {
                this.$Progress.fail();
                toast.fire({
                    'icon' : 'error',
                    'title': this.language.server_error,
                });
            })
        },
        async add_group() {
            this.buttonLoading = 'add'

            if(!(this.latitude && this.longitude)) {
                toast.fire({'icon' : 'info', 'title': this.language.toast_select_location_first });
                this.buttonLoading = false
                return ;
            }

            this.existingSuggestions = []
            const colRef = collection(db, 'groups')
            const nameDocs = await getDocs(query(colRef, where("groupName", "==", this.name), where('admin', '==', this.user.user.social_profile_id)))
            if(!nameDocs.empty) {
                this.errors.name = [this.language.groupname_taken];
                this.buttonLoading = false
                return ;
            }
            this.errors = []

            this.$Progress.start();
            const dataObj = {
                "groupName": this.name,
                "description": this.description,
                "icon": "",
                "admin name": this.currentUser.nickname,
                "members": [this.currentUser.id],
                "groupId": "",
                "is_private": this.is_private,
                "recentMessage": "",
                "recentMessageSender": "",
                "recentMessageTime": Date.now().toString(),
                "groupId": "",
                "admins": [this.currentUser.id] ,
                "admin": this.currentUser.id,
            }

            const docRef = await addDoc(colRef, dataObj)

            await updateDoc(doc(db, 'groups', docRef.id), {
                "groupId": docRef.id
            })

            var arr = this.currentUser.groups
            arr.push(docRef.id)

            await updateDoc(doc(db, 'users', this.currentUser.id), {
                "groups": arrayUnion(docRef.id)
            })

            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var fd = new FormData()
            fd.append('name', this.name)
            fd.append('description', this.description)
            fd.append('icon', this.icon)
            fd.append('latitude', this.latitude)
            fd.append('longitude', this.longitude)
            fd.append('loc_label', this.loc_label)
            fd.append('is_private', this.is_private ? 1 : 0)
            fd.append('firebase_group_id', docRef.id)
            await axios.post(this.$api + 'groups/create', fd, config).then(async (res) => {
                if(res.data.status) {
                    toast.fire({'icon' : 'success', 'title': this.language.toast_group_added_success });
                    this.$Progress.finish();
                    await updateDoc(doc(db, 'groups', docRef.id), {
                        "icon": res.data.path
                    })
                    this.name = this.description = this.icon = ''
                    this.is_private = this.preview = false
                    this.showModal = false
                    this.index()
                }
                else {
                    this.$Progress.fail();
                    toast.fire({
                        'icon' : 'error',
                        'title': this.language.server_error,
                    });
                }
            }).catch((err) => {
                this.$Progress.fail();
                toast.fire({
                    'icon' : 'error',
                    'title': this.language.server_error,
                });
            })
            this.buttonLoading = false
        },
        async accept_invite(id,user_id) {
            this.buttonLoading = 'accept'
            this.$Progress.start() ;
            await updateDoc(doc(db, 'groups', id), {
                "members": arrayUnion(this.currentUser.id)
            })
            await updateDoc(doc(db, 'users', this.currentUser.id), {
                "groups": arrayUnion(id)
            })
            var fd = {
                firebase_group_id: id,
                user_ids: [user_id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/add-members',fd,config).then((res) => {
                this.$Progress.finish() ;
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_group_joined_success,
                })
                this.index()
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail() ;
            })
            this.buttonLoading = false
        },
        async reject_invite(id,user_id) {
            this.buttonLoading = 'reject'
            this.$Progress.start() ;
            var fd = {
                firebase_group_id: id,
                user_ids: [user_id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/delete-invites',fd,config).then((res) => {
                this.$Progress.finish() ;
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_discarded,
                })
                this.index()
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail() ;
            })
            this.buttonLoading = false
        },
        async cancel_request(id,gid) {
            this.buttonLoading = 'cancel'
            this.$Progress.start() ;
            var fd = {
                firebase_group_id: gid,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/delete-requests',fd,config).then((res) => {
                this.$Progress.finish() ;
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_request_cancelled,
                })
                this.index()
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail() ;
            })
            this.buttonLoading = false
        },
        search() {
            if(this.searchText.length > 1) {
                this.$refs.search_field.focus()
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.loading = true
                this.seachLangText = this.language.loading
                this.$Progress.start()
                axios.post(this.$api + "groups/search",{ 'string' : this.searchText },config).then((res) => {
                    this.$Progress.finish()
                    this.loading = false
                    this.seachLangText = this.language.no_groups_to_display
                    this.groupsSearch = res.data.results
                    if(!this.groupsSearch.length) toast.fire({ 'icon': 'info', 'title': this.language.no_groups_to_display})
                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.seachLangText = this.language.server_error
                });
            }
            else {
                this.groupsSearch = []
                this.seachLangText = this.language.search_public_groups
            }
        },
        findSuggestions() {
            this.errors = [];
            if (this.name.length > 1) {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.post(this.$api + "groups/search",{ 'string' : this.name, 'limit': 6 },config).then((res) => {
                    this.$Progress.finish()
                    this.existingSuggestions = res.data.results
                }).catch((e) => {
                    this.$Progress.fail()
                });
            }
            else this.existingSuggestions = []
        },
        async uploadphoto(e) {
            var fi = e.target.files[0];
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 200,
                useWebWorker: true,
            }
            const compressed_File = await imageCompression(fi, options);
            this.preview = URL.createObjectURL(compressed_File)
            this.icon = compressed_File
            this.$refs.images.value = null
        },
    },
};
</script>
