<template>
    <div class="col-lg-4 form-group" :class="{'required': field.required}" v-if="field.type == 'select'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <v-select :clearable="false" class="v-form-control" v-model="internalValue" :options="answers" :class="{ 'is-invalid': errors['field_'+field.id] }">
            <template #search="{attributes, events}">
                <input class="vs__search" :required="field.required == '1' && !internalValue ? true : false" v-bind="attributes" v-on="events" />
            </template>
        </v-select>
        <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-lg-4 form-group" :class="{'required': field.required}" v-else-if="field.type == 'text'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <input type="text" class="form-control" v-model="internalValue" :required="field.required" maxlength="100" :class="{ 'is-invalid': errors['field_'+field.id] }" :placeholder="language.placeholder_max_100">
        <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-12 form-group" :class="{'required': field.required}" v-else-if="field.type == 'url'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <input type="url" class="form-control" v-model="internalValue" :required="field.required" maxlength="250" :class="{ 'is-invalid': errors['field_'+field.id] }" :placeholder="language.placeholder_max_250">
        <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-12 form-group" :class="{'required': field.required}" v-else-if="field.type == 'textarea'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <textarea rows="2" class="form-control" v-model="internalValue" :required="field.required" maxlength="250" :class="{ 'is-invalid': errors['field_'+field.id] }" :placeholder="language.placeholder_max_250" />
        <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-lg-4 form-group" :class="{'required': field.required}" v-else-if="field.type == 'date'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <input type="date" class="form-control" v-model="internalValue" :required="field.required" :class="{ 'is-invalid': errors['field_'+field.id] }">
        <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-lg-4 form-group" :class="{'required': field.required}" v-else-if="field.type == 'radio'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <ul class="ps-3 last-margin flex-sc gap-4" :class="{ 'is-invalid': errors['field_'+field.id] }">
            <label class="radio-container small" v-for="(a,index) in answers" :key="index">
                {{ a }}
                <input type="radio" :name="field.title.toLowerCase()" :required="field.required" v-model="internalValue" :value="a">
                <span class="checkmark"></span>
            </label>
        </ul>
        <span class="ms-2 mb-3 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
    <div class="col-lg-4 form-group" :class="{'required': field.required}" v-else-if="field.type == 'checkbox'">
        <label class="ms-2 mb-1">{{ field.title }}</label>
        <label class="checkbox-container small">Yes
            <input type="checkbox" v-model="internalValue" :required="field.required">
            <span class="checkmark"></span>
        </label>
        <span class="ms-2 mb-3 invalid-feedback" role="alert" v-if="errors['field_'+field.id]"><strong>{{ errors['field_'+field.id][0]}}</strong></span>
    </div>
</template>
<script>
export default {
    props: ['field', 'language', 'errors', 'value'],
    data() {
        return {
            internalValue: this.value || '',
        }
    },
    watch: {
        internalValue(to, from) {
            this.$emit('update:model_value', to)
        }
    },
    computed: {
        answers() {
            return Object.values(this.field.answers)
        }
    }
}
</script>
