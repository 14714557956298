<template>
    <div ref="element" v-if="!display">
        <div class="ribbon-wrapper" :class="display_type == 'card' ? 'left' : 'right'" v-if="!product.approved && !product.rejection" data-toggle="tooltip" :title="lang.approval_pending"><div class="ribbon bg-yellow">{{ lang.pending }}</div></div>
        <div class="ribbon-wrapper" :class="display_type == 'card' ? 'left' : 'right'" v-else-if="!product.approved && product.rejection" data-toggle="tooltip" :title="lang.rejected"><div class="ribbon bg-danger-button">{{ lang.rejected }}</div></div>
        <div class="ribbon-wrapper" :class="display_type == 'card' ? 'right' : 'left'"><div class="ribbon" :class="tt_class">{{ tt_type }}</div></div>
        <div class="product-img-container" @click="$router.push('/p/'+product.slug+type)">
            <div class="promoted" v-if="product.is_boosted">
                <router-link :to="'/user/profile?view=transactions&product='+product.crypt_id" data-toggle="tooltip" :title="lang.promoted" class="main-button small">
                    <i class="fas fa-star text-warning-l"></i>
                </router-link>
            </div>
            <img :src="product.thumbnail_image ? $s3_url+product.thumbnail_image : ''" :alt="product.title">
        </div>
        <div class="overlay">
            <div class="product-content">
                <span ref="title" :data-toggle="isEllipsisActive ? 'tooltip' : ''" :title="isEllipsisActive ? product.title : ''" @click="$router.push('/p/'+product.slug+type)" class="product-title">{{ product.title }}</span>
                <div class="like-container">
                    <div class="price">{{ price }}</div>
                    <span class="distance" v-if="!can_del">{{ product.user_distance }} {{  user?.user?.distance_unit == 'mi' ? 'mi' : (product.user_distance <= 1 ? 'km' : 'kms') }} {{ lang.away }}</span>
                </div>
                <div class="product-description" v-if="display_type == 'list'">{{ product.description}}</div>
                <div class="button-bar" v-if="!can_del">
                    <button type="button" @click="start_message(product.id,product.thumbnail_image,product.user_details.social_profile_id,product.user_details.slug)" class="main-button small light">
                        <i class="fas fa-comment-dots me-1"></i>{{ lang.chat }}
                    </button>
                    <button type="button" @click="like_p" class="main-button small like light">
                        <i class="fa-heart text-whites" :class="JSON.parse(JSON.stringify(liked_array)).includes(product.id) || !can_like ? 'fas text-danger' : 'far', product.total_likes_count ? 'pe-1' : ''"></i>
                        <span v-if="product.total_likes_count">{{ numberShort(product.total_likes_count,1) }}</span>
                    </button>
                    <button v-if="group_del" type="button" @click="delete_from_group" class="main-button small light">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
                <div class="button-bar" v-else>
                    <router-link :to="'/p/update/'+product.slug" class="main-button small light">
                        <i class="fas fa-pencil-alt" :class="{'pe-1': !mobile}"></i><span v-if="!mobile">{{ lang.edit }}</span>
                    </router-link>
                    <button type="button" class="main-button small light" @click="delete_product(product.id)">
                        <i class="fas fa-trash" :class="{'pe-1': !mobile}"></i><span v-if="!mobile">{{ lang.delete }}</span>
                    </button>
                    <router-link data-toggle="tooltip" :title="lang.boost_this_ad" :to="'/checkout?type=boosting&product_id='+product.crypt_id" class="main-button small light" v-if="product.boosting">
                        <i class="fas fa-bolt"></i>
                    </router-link>
                    <button type="button" @click="like_p" class="main-button small like light">
                        <i class="fa-heart text-whites" :class="JSON.parse(JSON.stringify(liked_array)).includes(product.id) || !can_like ? 'fas text-danger' : 'far', product.total_likes_count ? 'pe-1' : ''"></i>
                        <span v-if="product.total_likes_count">{{ numberShort(product.total_likes_count,1) }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="product-block-list" ref="element" v-else>
        <div class="ribbon-wrapper left"><div class="ribbon" :class="tt_class">{{ tt_type }}</div></div>
        <div class="product-img-container" @click="$router.push('/p/'+product.slug+type)">
            <div class="promoted" v-if="product.is_boosted">
                <router-link :to="'/user/profile?view=transactions&product='+product.crypt_id" data-toggle="tooltip" :title="lang.promoted" class="main-button small light">
                    <i class="fas fa-star"></i>
                </router-link>
            </div>
            <img :src="product.thumbnail_image ? $s3_url+product.thumbnail_image : ''" :alt="product.title">
        </div>
        <div class="overlay">
            <div class="product-content">
                <span ref="title" :data-toggle="isEllipsisActive ? 'tooltip' : ''" :title="isEllipsisActive ? product.title : ''" @click="$router.push('/p/'+product.slug+type)" class="product-title">{{ product.title }}</span>
                <p class="product-description line-clamp-2"><b>{{ lang.description }}: </b> {{ product.description }}</p>
                <div class="like-container">
                    <div class="price">{{ price }}</div>
                    <span class="distance" v-if="!can_del">{{ product.user_distance }} {{  user?.user?.distance_unit == 'mi' ? 'mi' : (product.user_distance <= 1 ? 'km' : 'kms') }} {{ lang.away }}</span>
                </div>
                <div class="button-bar" v-if="!can_del">
                    <button type="button" @click="start_message(product.id,product.thumbnail_image,product.user_details.social_profile_id,product.user_details.slug)" class="main-button small light">
                        <i class="fas fa-comment-dots me-1"></i>{{ lang.chat }}
                    </button>
                    <button type="button" @click="like_p" class="main-button small like light">
                        <i class="fa-heart text-whites" :class="JSON.parse(JSON.stringify(liked_array)).includes(product.id) || !can_like ? 'fas text-danger' : 'far', product.total_likes_count ? 'pe-1' : ''"></i>
                        <span v-if="product.total_likes_count">{{ numberShort(product.total_likes_count,1) }}</span>
                    </button>
                    <button v-if="group_del" type="button" @click="delete_from_group" class="main-button small light">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
                <div class="button-bar" v-else>
                    <router-link :to="'/p/update/'+product.slug" class="main-button small light">
                        <i class="fas fa-pencil-alt" :class="{'pe-1': !mobile}"></i><span v-if="!mobile">{{ lang.edit }}</span>
                    </router-link>
                    <button type="button" @click="like_p" class="main-button small like light">
                        <i class="fa-heart text-whites" :class="JSON.parse(JSON.stringify(liked_array)).includes(product.id) || !can_like ? 'fas text-danger' : 'far', product.total_likes_count ? 'pe-1' : ''"></i>
                        <span v-if="product.total_likes_count">{{ numberShort(product.total_likes_count,1) }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { collection, setDoc, updateDoc, getDoc, arrayUnion,  doc } from "firebase/firestore";
</script>
<script>
export default {
    props: ['active_chats','user','product','can_like', 'can_del', 'liked_array','lang','group_del', 'index', 'type', 'display', 'display_type'],
    name: 'Product',
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            liked: [],
            docid: '',
            config: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("greta")}`,
                },
            },
            isEllipsisActive: false,
            element: null,
        };
    },
    created() {
        this.liked = JSON.parse(JSON.stringify(this.liked_array) || '[]') ;
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    computed: {
        f_name() {
            return this.product.user_details.first_name.indexOf(' ') != -1 ? this.product.user_details.first_name.substring(0, this.product.user_details.first_name.indexOf(' ')) : this.product.user_details.first_name ;
        },
        tt_type() {
            if(this.product.trade_type == 1) return this.lang.trade.split('/')[0] ;
            else if(this.product.trade_type == 2) return this.lang.sell ;
            else if(this.product.trade_type == 3) return this.lang.free ;
            else if(this.product.trade_type == 4) return this.lang.rent ;
            else if(this.product.trade_type == 5) return this.lang.buy ;
            else if(this.product.trade_type == 6) return this.lang.service ;
            else return 'Sale' ;
        },
        tt_class() {
            if(this.product.trade_type == 1) return 'bg-orange' ;
            else if(this.product.trade_type == 2) return 'bg-white-b' ;
            else if(this.product.trade_type == 3) return 'bg-green' ;
            else if(this.product.trade_type == 4) return 'bg-purple' ;
            else if(this.product.trade_type == 5) return 'bg-yellow' ;
            else if(this.product.trade_type == 6) return 'bg-red' ;
            else return 'bg-white-b' ;
        },
        price() {
            var formatter = new Intl.NumberFormat();
            return this.product.user_details.currency_symbol + ' ' + formatter.format(this.product.price);
        }
    },
    mounted() {
        if(this.$refs.element.classList.contains('last_item')) {
            this.element = this.$refs.element
            this.checkItem()
        }
        this.checkEllipsisOverflow();
        this.$nextTick(() => {
            if(!this.mobile)
            $('[data-toggle="tooltip"]').tooltip()
        })
        window.addEventListener('resize', this.checkEllipsisOverflow);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkEllipsisOverflow);
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    methods: {
        checkEllipsisOverflow() {
            const titleElement = this.$refs.title;
            const containerWidth = titleElement.clientWidth;

            const clone = titleElement.cloneNode(true);
            clone.style.visibility = 'hidden';
            clone.style.position = 'absolute';
            clone.style.width = 'auto';
            clone.style.maxWidth = 'none';
            document.body.appendChild(clone);

            this.isEllipsisActive = clone.offsetWidth > containerWidth;
            document.body.removeChild(clone);
        },
        like_p() {
            if(this.token == null){
                this.emitter.emit('showlogin')
                return
            }
            if(this.can_like) {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                var fd = new FormData()
                fd.append('liked_product_id',this.product.id)
                fd.append('message_text',this.lang.notification_text)
                this.$Progress.start()
                if(!JSON.parse(JSON.stringify(this.liked_array)).includes(this.product.id)) {
                    axios.post(this.$api+'like/make-like',fd,config).then((response) => {
                        if(response.data.status){
                            this.$Progress.finish()
                            this.emitter.emit('fetch_index', this.index) ;
                            this.liked_array.push(this.product.id)
                            this.product.total_likes_count++ ;
                        }
                    }).catch((e) => {
                        this.$Progress.fail()
                    });
                }
                else {
                    axios.post(this.$api+'like/make-unlike',{ 'liked_product_id':this.product.id },config).then((response) => {
                        if(response.data.status){
                            this.$Progress.finish()
                            this.emitter.emit('fetch_index', this.index) ;
                            this.liked_array.splice(this.liked_array.indexOf(this.product.id),1)
                            this.product.total_likes_count-- ;
                        }
                    }).catch((e) => {
                        this.$Progress.fail()
                    });;
                }
            }
        },
        async start_message(product_id,image_blob,product_user_spi,slug){
            if(this.token == null){
                // if(!this.mobile) {
                //     toast.fire({
                //         icon: "info",
                //         title: this.lang.login_to_continue,
                //     });
                // }
                // else {
                    this.emitter.emit('showlogin')
                // }
                return ;
            }
            if(!this.can_del && product_user_spi != this.user.user.social_profile_id) {
                // if(this.active_chats.includes(product_id)){
                //     toast.fire({
                //         icon: "info",
                //         title: this.lang.toast_chat_already_active,
                //     });
                // }else{
                    this.$Progress.start()
                    // if(this.user.user_products_count == 0){
                    //     Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 4000 })
                    //     .fire({ icon: "info", title: this.lang.toast_first_add_products_to_message });
                    //     return false
                    // }
                    var fd = new FormData()
                    fd.append('first_user',this.user.user.social_profile_id)
                    fd.append('second_user',product_user_spi)
                    fd.append('product_id',product_id)
                    axios.post(this.$api + "chats/create-chat",fd,this.config).then(async (res)=>{
                        if(res.data.status == true){
                            var chk = doc(db, "messages", this.user.user.social_profile_id+"-"+product_user_spi);

                            var docSnap =  await getDoc(chk);
                            this.docid = ''
                            if (docSnap.exists()) {
                                this.docid = this.user.user.social_profile_id+"-"+product_user_spi;
                            } else {
                                this.docid = product_user_spi+"-"+this.user.user.social_profile_id
                            }

                            const docRef = doc(db, "messages", this.docid);

                            var str  = Date.now().toString() ;
                            var msg = this.lang.new_chat_message ;
                            setDoc(docRef, {
                                'recentMessage': msg,
                                'timestamp' : str,
                                'isDisabled': false,
                                'blocked_by' : '',
                            })

                            const colRef = collection(docRef, this.docid)
                            setDoc(doc(colRef, str), {
                                "idFrom" : this.user.user.social_profile_id,
                                "idTo" : product_user_spi,
                                "content" : msg,
                                "imageBlob" : image_blob,
                                "timestamp" : str,
                                "type" : 0,
                                "seen" : 0,
                            })

                            var chat_data = {
                                user: product_user_spi,
                                user_crypt: slug,
                                product_id: product_id,
                                chat_id: this.docid,
                            }

                            await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                                "chats": arrayUnion(chat_data)
                            })

                            var rev_chat_data = {
                                user: this.user.user.social_profile_id,
                                user_crypt: this.user.user.slug,
                                product_id: product_id,
                                chat_id: this.docid,
                            }

                            await updateDoc(doc(db, 'users', product_user_spi), {
                                "chats": arrayUnion(rev_chat_data)
                            })

                            this.$Progress.finish()
                            toast.fire({
                                'icon' : 'success',
                                'title': this.lang.toast_message_sent,
                            })
                            this.$router.push('/chats')
                        }
                    })
                    .catch((error)=>{
                        this.$Progress.fail()
                    })
                // }
            }
        },
        delete_product(id) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var thiss = this
            Swal.fire({
                title: this.lang.toast_are_you_sure,
                text: this.lang.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.lang.toast_yes_delete,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    thiss.$Progress.start()
                    axios.get(thiss.$api+'products/remove-product/'+id,config).then((response) => {
                        if(response.data.status){
                            thiss.$Progress.finish()
                            thiss.emitter.emit('remove_product', thiss.index)
                            // Swal.fire({
                            //     icon: 'success',
                            //     title: thiss.lang.deleted,
                            //     text: thiss.lang.toast_product_deleted_success,
                            //     customClass: {
                            //     confirmButton: 'btn btn-success'
                            //     }
                            // });
                            toast.fire({
                                icon: "success",
                                title: thiss.lang.toast_product_deleted_success,
                            });
                            thiss.emitter.emit('get_user')
                        }
                    }).catch((e) => {
                        thiss.$Progress.fail()
                        toast.fire({
                            icon: "error",
                            title: thiss.lang.server_error,
                        });
                    });
                }
            });

        },
        checkItem() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.emitter.emit('view_more')
                        // observer.unobserve(this.element);
                    }
                });
            });
            observer.observe(this.element);
        },
        delete_from_group() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            axios.post(this.$api+'groups/remove-product',{ gid: this.group_del, pid: this.product.crypt_id },config)
            .then((response) => {
                if(response.data.status){
                    this.$Progress.finish()
                    toast.fire({
                        icon: "success",
                        title: this.lang.toast_product_deleted_success,
                    });
                    this.emitter.emit('remove_product', this.index)
                }
            }).catch((e) => {
                this.$Progress.fail()
                toast.fire({
                    icon: "error",
                    title: this.lang.server_error,
                });
            });
        },
        boost() {
            alert('hi')
        }
    },
}
</script>
