<template>
    <div class="row match-height">
        <div class="col-12">
            <div class="features-card min-sc">
                <div class="card-header flex-column flex-md-row d-flex align-items-start align-items-md-center justify-content-lg-between">
                    <div class="flex-cc mb-2">
                        <button type="button" class="main-button small me-1" @click="goback()"><i class="fas fa-arrow-left fa-lg"></i></button>
                        <h3 key="h3" class="d-inline-flex align-items-center mx-2 mb-0">
                            <img v-if="user" :src="$s3_url+user.user.thumbnail_image" class="profile-icon w40">
                            <div class="flex-sc align-items-start flex-column">
                                <span>{{user.user.first_name + ' ' +(user.user.last_name || '')}}</span>
                                <small class="text-muted">{{ user.user.email }}</small>
                            </div>
                        </h3>
                    </div>
                    <ul class="nav nav-pills profile-nav justify-content-center justify-content-md-end">
                        <li :class="{ 'active': tab == 1 }" @click="getData(1)"><i class="fas fa-user"></i> {{ language.profile_tab_profile }}</li>
                        <li :class="{ 'active': tab == 2 }" @click="getData(2)"><i class="fas fa-store"></i> {{ language.profile_tab_store }}</li>
                        <li :class="{ 'active': tab == 3 }" @click="getData(3)"><i class="fas fa-file-invoice-dollar"></i>{{ language.profile_tab_transaction }}</li>
                    </ul>
                </div>
                <div class="card-body">
                    <transition-group name="fade">
                        <div key="profile" class="row" v-if="!loading && tab == 1">
                            <div class="col-12 col-lg-12">
                                <!-- <div class="card-header flex-sc"><h3>{{ language.profile_settings }}</h3></div> -->
                                <div class="card-body">
                                    <div class="row contact-form mt-4">
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <label class="ms-3 mb-1">{{ language.profile_image }}</label>
                                            <div class="input-preview ms-3">
                                                <img v-if="user.user && preview==false" :src="$s3_url+user.user.thumbnail_image" class="profile-icon w50">
                                                <img v-else-if="user.user && preview!=false" :src="preview" class="profile-icon w50">
                                                <i v-else class="fas fa-user fa-10x m-auto py-5"></i>
                                                <input type="file" accept="image/*" @change="uploadphoto" id="profile_img">
                                                <label for="profile_img" class="profile">
                                                    <i class="fas fa-upload pe-1"></i> {{ language.image }}
                                                </label>
                                                <button type="button" class="main-button small" @click="showModal = 'avatar'">{{language.avatar}}</button>
                                            </div>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="mb-1 ms-2">{{ language.label_profile_location }}</label>
                                            <br>
                                            <button type="button" :class="{ 'flex-cc' : !mobile }" v-if="display_locality" @click="emitter.emit('location_change', true)" class="main-button light">
                                                <i v-if="!mobile" class="fa pe-1 pe-lg-2 text-body fa-map-marker-alt"></i>
                                                <span class="line-clamp-1">{{display_locality.name}}</span>
                                            </button>
                                        </div>
                                        <div class="col-7 col-lg-5 px-4">
                                            <label class="mb-2">{{language.distance_margin}}</label>
                                            <Slider :tooltips="false" @slide="updatedcontent" :step="slider_settings.step" class="slider-blue my-3" :min="slider_settings.min" :max="slider_settings.max" required v-model="settings.distance_margin" />
                                        </div>
                                        <div class="col-5 col-lg-2">
                                            <label class="ms-3 mb-1">{{language.select_unit}}</label>
                                            <select class="form-control" v-model="settings.distance_unit">
                                                <option value="km">{{settings.distance_margin}} KM</option>
                                                <option value="mi">{{settings.distance_margin}} MI</option>
                                            </select>
                                        </div>
                                        <div class="col-6 col-lg-3 d-flex">
                                            <div class="custom-control custom-switch custom-switch-lg mx-auto my-auto">
                                                <input type="checkbox" ref="invis" :checked="!(user.user.is_visible)" class="custom-control-input" id="customSwitch2">
                                                <label class="custom-control-label" for="customSwitch2" @click="go_invis">
                                                    {{language.go_invisible}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-2">
                                            <label class="ms-3 mb-1">{{ language.label_select_currency }}</label>
                                            <select class="form-control mb-4" v-model="currency">
                                                <option value="0"></option>
                                                <option v-for="(item,index) in currencies" :value="item.id">{{item.currency_code}}( {{ item.symbol }})</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center pt-3">
                                <a v-if="token" href="javascript:;" @click="update_profile()" class="main-button px-5">{{ language.save }}</a>
                            </div>
                        </div>
                        <div key="store" class="row" v-if="!loading && tab == 2">
                            <div class="col-12 col-lg-12">
                                <!-- <div class="card-header flex-sc"><h3>{{ language.profile_tab_store }}</h3></div> -->
                                <div class="card-body">
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <div class="custom-control custom-switch custom-switch-lg mx-auto my-auto">
                                                <input type="checkbox" v-model="storeEnabled" class="custom-control-input" id="storeEnableSwitch">
                                                <label class="custom-control-label" for="storeEnableSwitch" @click="checkStoreEnabledValue">
                                                    {{ language.store_enabled }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="storeEnabled" class="row contact-form mt-4">
                                        <div class="col-12 col-md-6">
                                            <label class="ms-2 mb-1">{{ language.label_store_name }}</label>
                                            <input type="text" v-model="storeData.name" class="form-control" :class="{ 'is-invalid': errors.name }" required>
                                            <span class="invalid-feedback" role="alert" v-if="errors.name"><strong>{{ errors.store_name[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <label class="ms-2 mb-1">{{ language.label_store_number }}</label>
                                            <input type="tel" @keypress="validate" required v-model="storeData.mobile_no" class="form-control" :class="{ 'is-invalid': errors.mobile_no }" maxlength="10">
                                            <span class="invalid-feedback" role="alert" v-if="errors.mobile_no"><strong>{{ errors.mobile_no[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <label class="ms-2 mb-1">{{ language.label_store_banner }}</label>
                                            <div class="input-preview ms-2">
                                                <img v-if="user.user.store && storePreview==false" :src="$s3_url+user.user.store.store_banner" class="profile-icon w50">
                                                <img v-else-if="storePreview!=false" :src="storePreview" class="profile-icon w50">
                                                <img v-else src="/front/sample.png" alt="Sample" class="profile-icon w50">
                                                <input type="file" :required="!storePreview" accept="image/*" @change="uploadStorePhoto" id="store_profile_img">
                                                <label for="store_profile_img" class="profile">
                                                    <i class="fas fa-upload pe-1"></i> Choose Image
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 flex-bc gap-2 mb-3 mb-md-0 flex-column flex-md-row">
                                            <div class="w-100">
                                                <label class="ms-3 mb-1">{{ language.location }}</label>
                                                <GMapAutocomplete id="storeLocation" @placeholder="language?.search_your_location || 'N/A'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" @class="'mb-4 form-control'" ></GMapAutocomplete>
                                            </div>
                                            <button type="button" @click="current_location" class="main-button text-nowrap"><i class="fa fa-map-marker-alt me-2"></i>{{language.use_my_current_location}}</button>
                                        </div>
                                        <transition name="bounce">
                                            <div class="col-12 col-md-3 flex-cc" v-if="storeData.location_locality">
                                                <button key="change" aria-label="change" type="button" class="main-button light small">
                                                    <i class="fa fa-map-marker-alt fa-15x pe-1 pe-lg-2 text-body"></i> {{ storeData.location_locality }}
                                                </button>
                                            </div>
                                        </transition>
                                        <div class="col-12 col-md-3">
                                            <label class="ms-3 mb-1">{{ language.store_type || 'Store Type' }}</label>
                                            <v-select :clearable="false" :searchable="false" class="v-form-control" :options="store_types" v-model="storeData.store_type" :reduce="i => i.id">
                                                <template #search="{attributes, events}">
                                                    <input class="vs__search" :required="!storeData.store_type" v-bind="attributes" v-on="events" />
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <label class="ms-2 mb-1">{{ language.store_desc }}</label>
                                            <textarea v-model="storeData.description" class="form-control" rows="2" :class="{ 'is-invalid': errors.description }"></textarea>
                                            <span class="invalid-feedback" role="alert" v-if="errors.description"><strong>{{ errors.description[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <label class="ms-2 mb-1">{{ language.facebook_link }}</label>
                                            <input type="url" v-model="storeData.social_links.facebook" class="form-control" :class="{ 'is-invalid': errors.facebook }">
                                            <span class="invalid-feedback" role="alert" v-if="errors.facebook"><strong>{{ errors.facebook[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <label class="ms-2 mb-1">{{ language.twitter_link }}</label>
                                            <input type="url" v-model="storeData.social_links.twitter" class="form-control" :class="{ 'is-invalid': errors.twitter }">
                                            <span class="invalid-feedback" role="alert" v-if="errors.twitter"><strong>{{ errors.twitter[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <label class="ms-2 mb-1">{{ language.instagram_link }}</label>
                                            <input type="url" v-model="storeData.social_links.instagram" class="form-control" :class="{ 'is-invalid': errors.instagram }">
                                            <span class="invalid-feedback" role="alert" v-if="errors.instagram"><strong>{{ errors.instagram[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <label class="ms-2 mb-1">{{ language.youtube_link }}</label>
                                            <input type="url" v-model="storeData.social_links.youtube" class="form-control" :class="{ 'is-invalid': errors.youtube }">
                                            <span class="invalid-feedback" role="alert" v-if="errors.youtube"><strong>{{ errors.youtube[0]}}</strong></span>
                                        </div>
                                        <div class="col-12 text-right my-3">
                                            <a v-if="token" href="javascript:;" @click="update_store_details()" class="main-button px-5">{{ language.update }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div key="transactions" v-if="!loading && tab == 3">
                            <transition-group tag="div" class="row" name="bounce">
                                <transaction v-for="(t,index) in transactions" :key="index" :user="user" :language="language" :data="t" />
                            </transition-group>
                            <NotFound :lang="language" :type="text" :load="loading" v-if="!transactions.length"></NotFound>
                        </div>
                        <NotFound key="loading" :lang="language" :type="text" :load="loading" v-if="loading"></NotFound>
                    </transition-group>
                </div>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'avatar'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.select_avatar}}</h3>
                                <a v-if="token" href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row">
                                    <div class="col-md-4" v-for="(img,index) in avatar_paths" :key="index">
                                        <div class="avatar-icon">
                                            <img :src="img" style="height:100px; width:100px;" @click="select_avatar(img)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
<script>
import { updateDoc, doc } from "firebase/firestore";
export default {
    name: 'UserProfile',
    props: ['user','language', 'store_types'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            text: this.language.loading,
            loading: true,
            form: new Form({
                first_name: '',
                last_name: '',
                email: '',
                image_path: '',
                image_avatar_path: "",
            }),
            img_counter: 0,
            tab: 1,
            settings: new Form({
                distance_margin: '',
                distance_unit: '',
            }),
            currencies:null,
            currency:'0',
            transactions: [],
            img_to_update: '',
            store_name: '',
            preview: false,
            showModal: false,
            display_locality: null,
            avatar_paths: [],
            slider_settings: {
                min : 1,
                max : 100,
                step : 10,
            },
            languages: [],
            errors: [],
            view: '',
            payment: '',
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            storeEnabled: false,
            storeData: {
                name: null,
                description: '',
                mobile_no: null,
                latitude: '',
                store_type: '',
                longitude: '',
                location_locality: '',
                social_links: {
                    facebook: '',
                    instagram: '',
                    youtube: '',
                    twitter: ''
                }
            },
            storePreview: false,
            store_img_counter: 0,
            store_img_to_update: '',
            config: { headers: { Authorization: `Bearer ${localStorage.getItem("greta")}` } },
        };
    },
    async created() {
        if(Object.entries(this.$route.query).length > 0) {
            var step = this.$route.query.view == 'transactions' ? 3 : ''
            this.getData(step)
            if(this.$route.query.payment) {
                this.payment = this.$route.query.payment
                if(this.payment == 'success')
                    toast.fire({icon: 'success', title: this.language.payment_success}) ;
                else
                    toast.fire({icon: 'error', title: this.language.payment_failed}) ;
            }
        }

        this.display_locality = JSON.parse(localStorage.getItem('display_locality'))

        await this.index()

        if(this.user.user) {
            if (this.user.user.store) {
                this.store_name = this.user.user.store.store_name
            }
            this.form.fill(this.user.user)
            this.settings.fill(this.user.user)
            this.img_to_display = this.user.user.thumbnail_image
        }

        for (let index = 1; index <= 15; index++) {
            this.avatar_paths.push('/avatars/' + index+'.png')
        }

        this.emitter.on('removeModal', () => {
            this.showModal = false
        })

        this.emitter.on("location_updated", () => {
            if(this.$route.name == 'UserProfile') {
                this.display_locality = JSON.parse(localStorage.getItem('display_locality'))
            }
        });

        this.emitter.on("update_user", async (data) => {
            if(this.$route.name == 'UserProfile') {
                updateDoc(doc(db, 'users', data.user.social_profile_id), {
                    "imageBlob" : data.user.thumbnail_image,
                })
            }
        });
    },
    methods: {
        async index() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            await axios.get(this.$api + "user/app-settings",config).then((res) => {
                this.slider_settings.min = parseInt(res.data.settings.slider_min_max[0].value.split(',')[0])
                this.slider_settings.max = parseInt(res.data.settings.slider_min_max[0].value.split(',')[1])
                this.currencies = res.data.currencies
                if (res.data.user_currency) {
                    this.currency = res.data.user_currency.currency_id
                }
                this.user.user.store_enabled == 1 ? this.storeEnabled = true : this.storeEnabled = false
                if (this.user.user.store_enabled == 1) {
                    this.storeData.name = this.user.user?.store?.store_name
                    this.storeData.store_type = this.user.user?.store?.type_id
                    this.storeData.description = this.user.user?.store?.description
                    this.storeData.mobile_no = this.user.user?.store?.mobile_no
                    this.storeData.latitude = this.user.user?.store?.latitude
                    this.storeData.longitude = this.user.user?.store?.longitude
                    this.storeData.location_locality = this.user.user?.store?.location_locality
                    this.storeData.social_links = JSON.parse(this.user.user?.store?.social_links || "{}")
                }
                this.loading = false
                this.$Progress.finish()
            }).catch((e) => {
                console.log(e);
                this.$Progress.fail()
            });
        },
        async uploadphoto(e) {
            var fi = e.target.files;
            if (fi.length > 0) {
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 200,
                    useWebWorker: true,
                }
                var compressed_File = await imageCompression(fi[0], options);
                this.preview = URL.createObjectURL(fi[0])
                this.img_to_update = compressed_File
                this.img_counter = 1
            }
            else {
                this.preview = false
                this.img_to_update = null
                this.img_counter = 0
            }
        },
        async uploadStorePhoto(e) {
            console.log(e.target.files);
            var fi = e.target.files;
            if (fi.length > 0) {
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 200,
                    useWebWorker: true,
                }
                var compressed_File = await imageCompression(fi[0], options);
                this.storePreview = URL.createObjectURL(fi[0])
                this.store_img_to_update = compressed_File
                this.store_img_counter = 1
            }
            else {
                this.storePreview = false
                this.store_img_to_update = null
                this.store_img_counter = 0
            }
        },
        go_invis() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            if(this.user.user.is_visible) {
                axios.get(this.$api+'user/go-invisible',config).then((response) => {
                    if(response.data.status){

                        toast.fire({
                            icon: "success",
                            title: this.language.toast_invisible_enabled,
                        });
                        this.$Progress.finish()
                        this.user.user.is_visible = 0
                    }
                }).catch((e) => {
                    this.$refs.invis.click() ;
                    this.$Progress.fail()
                });;
            }
            else {
                axios.get(this.$api+'user/go-visible',config).then((response) => {
                    if(response.data.status){
                        toast.fire({
                            icon: "error",
                            title: this.language.toast_invisible_disabled,
                        });
                        this.$Progress.finish()
                        this.user.user.is_visible = 1
                    }
                }).catch((e) => {
                    this.$refs.invis.click() ;
                    this.$Progress.fail()
                });;
            }
            this.emitter.emit('get_user')
        },
        async update_profile() {
            this.$Progress.start()
            let fd = new FormData();
            fd.append('image_path',this.img_to_update)
            fd.append('img_counter',this.img_counter)
            fd.append('store_name',this.store_name)

            await axios.post(this.$api+'user/update-profile',fd,this.config).then((response) => {
                if(response.data.status){
                    toast.fire({
                        icon: "success",
                        title: this.language.toast_profile_updated,
                    });

                    this.$Progress.finish()
                    //Adding Doc in firebase
                    updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                        "imageBlob" : this.user.user.thumbnail_image,
                    })
                }
            }).catch((e) => {
                this.$Progress.fail()
            });
            await this.update_distance()
            if (this.currency != '0') await this.update_currency()
            this.emitter.emit('get_user')
        },
        async update_store_details() {
            this.$Progress.start()
            let fd = new FormData();
            fd.append('store_banner',this.store_img_to_update)
            fd.append('description',this.storeData.description)
            fd.append('store_name',this.storeData.name)
            fd.append('store_type',this.storeData.store_type)
            fd.append('mobile_no',this.storeData.mobile_no)
            fd.append('latitude',this.storeData.latitude)
            fd.append('longitude',this.storeData.longitude)
            fd.append('location_locality',this.storeData.location_locality)
            fd.append('img_counter',this.store_img_counter)
            fd.append('social_links',JSON.stringify(this.storeData.social_links))

            await axios.post(this.$api+'user/update-store-details',fd,this.config).then((response) => {
                if(response.data.status){
                    toast.fire({
                        icon: "success",
                        title: this.language.toast_store_updated,
                    });
                    this.$Progress.finish()
                    this.emitter.emit('get_user')
                }
            }).catch((e) => {
                this.$Progress.fail()
            });
        },
        async checkStoreEnabledValue() {
            var temp = !this.storeEnabled
            if((!temp && this.storeData.name && this.storeData.mobile_no && this.storePreview) || (temp && this.storeData.name && this.storeData.mobile_no && this.storePreview)) {
                this.$Progress.start()
                await axios.get(this.$api+'user/update-disable-store/'+temp,this.config).then((res) => {
                    toast.fire({
                        icon: "success",
                        title: res.data.enabled ? this.language.toast_store_enabled : this.language.toast_store_disabled,
                    });
    
                    this.$Progress.finish()
                }).catch((e) => {
                    this.$Progress.fail()
                });
                this.emitter.emit('get_user')
            }
        },
        async update_distance() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            // this.$Progress.start()
            axios.get(this.$api+'user/update-distance/'+this.settings.distance_margin+'/'+this.settings.distance_unit,config).then((response) => {
                if(response.data.status){
                    // toast.fire({
                    //     icon: "success",
                    //     title: this.language.toast_distance_setting_updated,
                    // });
                    this.user.user.distance_margin = this.settings.distance_margin
                    this.user.user.distance_unit = this.settings.distance_unit
                    // this.$Progress.finish()
                }
            }).catch((e) => {
                // this.$Progress.fail()
            });;
        },
        async update_currency() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            // this.$Progress.start()
            axios.get(this.$api+'user/update-currency/'+this.currency,config).then((response) => {
                if(response.data.status){
                    // toast.fire({
                    //     icon: "success",
                    //     title: this.language.toast_distance_setting_updated,
                    // });
                    this.user.user.distance_margin = this.settings.distance_margin
                    this.user.user.distance_unit = this.settings.distance_unit
                    // this.$Progress.finish()
                }
            }).catch((e) => {
                // this.$Progress.fail()
            });;
        },
        async select_avatar(url){
            const response = await fetch(url);
            const data = await response.blob();
            const metadata = { type: `image/jpeg` };
            this.img_to_update = new File([data], 'ava.jpeg', metadata);
            this.preview = URL.createObjectURL(this.img_to_update)
            this.img_counter = 1
            $('.user-img').attr('src',url)
            this.showModal = false;
        },
        updatedcontent(val) {
            this.settings.distance_margin = val
        },
        goback() {
            window.history.state.back ? this.$router.go(-1) : this.$router.push('/')
        },
        getData(step) {
            this.tab = step
            this.loading = true
            this.text = this.language.loading

            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            axios.get(this.$api + "user/get-data/"+step,config).then((res) => {
                this.$Progress.finish()
                this.loading = false

                if(step == 3){
                    this.transactions = res.data.transactions
                    this.text = this.language.transactions_not_available
                }
            }).catch((err) => {
                this.$Progress.fail()
                this.loading = false
                this.text = this.language.server_error
            })
        },
        validate: function (evt) {
            var key = evt.keyCode || evt.which;
            key = String.fromCharCode(key);
            var regex = /[0-9]|\./;
            if (!regex.test(key)) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault();
            }
        },
        getAddressData(placeResultData) {
            const place = JSON.parse(JSON.stringify(placeResultData))
            this.storeData.latitude = place.geometry.location.lat
            this.storeData.longitude = place.geometry.location.lng
            this.storeData.location_locality = place.formatted_address
        },
        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
        },
        async locationiq(coords) {
            if(this.flag) {
                this.storeData.latitude = coords.latitude ;
                this.storeData.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get("https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" + this.storeData.latitude + "&lon=" + this.storeData.longitude + "&format=json").then((res) => {
                    this.storeData.location_locality = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
    },
}
</script>
