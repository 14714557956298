<template>
    <f_header v-if="language" :language="language" />
    <section class="section">
        <div class="container">
            <div class="d-flex justify-content-end">
                <transition-group name="fade" tag="div" class="float-button">
                    <div key="add">
                        <button v-if="user && language && categories && store_types && event_types" type="button" class="main-button floating-button" :class="{ 'light': buttonOverlapsFooter }" data-toggle="tooltip" @click="floating" :title="language.add_product">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <div key="feedback">
                        <button v-if="user && language && categories && store_types && event_types" type="button" class="main-button floating-button-1" :class="{ 'light': buttonOverlapsFooter1 }" data-toggle="tooltip" @click="show_feedback" :title="language.tooltip_feedback">
                            <i class="fas fa-comment-dots"></i>
                        </button>
                    </div>
                </transition-group>
            </div>
            <router-view :map_view="map_view" :default_display_type="default_display_type" v-slot="{ Component, route }" @keydown.esc="emitter.emit('removeModal')" v-if="user && language && categories && store_types && event_types" :user="user" :store_types="store_types" :event_types="event_types" :language="language" :categories="categories">
                <!-- <transition  :name="route.meta.transition || 'slide-up'" :mode="route.meta.transition ? '' : 'out-in'"> -->
                <transition  name="slide-up" mode="out-in">
                    <keep-alive include="Marketplace,Group">
                        <component :is="Component" :key="$route.params.id || $route.name" />
                    </keep-alive>
                </transition>
            </router-view>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="token == null && showModal == 'login'" key="login" :class="{ 'd-block show backdrop' : showModal=='login'}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <!-- <button type="button" class="main-button small light close" @click="showModal = false;name = '';is_private = false;">
                            <i class="fas fa-times fa-15x"></i>
                        </button> -->
                        <div class="features-card rounded-4">
                            <div class="justify-content-end d-flex" v-if="showClose">
                                <a href="javascript:;" @click="showModal = false;name = '';is_private = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body h-100">
                                <transition name="bounce">
                                    <div class="flex-column h-100 text-center flex-cc">
                                        <h2 class="fw-bolder mb-4">{{ language.welcome_to }} {{ $appname }}</h2>
                                        <div class="blog-post-thumb bharat-image mb-1 mt-0 mt-md-2">
                                            <img src="/front/assets/bharat.webp">
                                        </div>
                                        <!-- <h3 class="mt-4">{{ language.join_the_movement }}</h3> -->
                                        <p class="text-muted px-2 px-md-4 my-3 pt-2">{{ language.you_agree_to_our }}
                                            <a :href="$domain + '/terms'" target="_blank">{{ language.tnc }}</a> {{ language.and }}
                                            <a :href="$domain + '/terms'" target="_blank">{{ language.ppolicy }}.</a>
                                        </p>
                                        <div class="flex-cc flex-column gap-3">
                                            <GoogleLogin :callback="processSignin" />
                                            <!-- <GoogleLogin :callback="processSignin">
                                                <button class="main-button light login-with-google-btn">
                                                    {{ language.sign_in_with_google }}
                                                </button>
                                            </GoogleLogin> -->
                                            <!-- <button type="button" class="main-button light login-with-apple-btn" @click.prevent="signin('apple')">
                                                {{ language.sign_in_with_apple }}
                                            </button> -->
                                            <button type="button" class="main-button light login-with-email-btn" @click="with_email = !with_email">
                                                <i class="fas fa-envelope text-warning pe-2"></i>
                                                {{ language.sign_in_with_email }}
                                            </button>
                                        </div>
                                        <transition name="bounce">
                                            <form class="row align-items-center contact-form w-100 mt-2" :class="mobile ? 'flex-column' : ''" @submit.prevent="signin('email')" v-if="with_email">
                                                <div class="col-12 col-md-7">
                                                    <input type="email" name="email" :placeholder="language.email" v-model="login_email" ref="login_email" class="form-control mb-0">
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <button type="submit" class="main-button text-nowrap light">
                                                        {{ language.send_email_link }}
                                                    </button>
                                                </div>
                                            </form>
                                        </transition>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'location'" key="location" :class="{ 'd-block show backdrop' : showModal=='location'}" tabindex="-1" role="dialog">
                <div class="modal-dialog px-3" :class="{ 'modal-dialog-centered' : !mobile }" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4 position-relative">
                            <button type="button" class="main-button small light close" @click="showModal = false">
                                <i class="fas fa-times fa-15x"></i>
                            </button>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form mt-2">
                                    <div class="col-12 mb-3 d-flex">
                                        <button key="change" type="button" v-if="display_locality" class="main-button light small mx-auto">
                                            <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                            <span>{{display_locality.name}}</span>
                                        </button>
                                    </div>
                                    <div class="col-12 d-flex mb-2" v-if="token!=null && !disabled">
                                        <div class="custom-control custom-switch mx-auto my-auto">
                                            <input type="checkbox" v-model="save_location" class="custom-control-input" id="customSwitch">
                                            <label class="custom-control-label" for="customSwitch">
                                                {{ language.save_location_to_profile }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <GMapAutocomplete :placeholder="language.search_your_location" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" :class="classes" ></GMapAutocomplete>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <!-- <button type="button" class="main-button light me-2" @click="reset_filter">{{language.reset}}</button> -->
                                <button type="button" @click="current_location" class="main-button w-auto mx-auto"><i class="fa fa-map-marker-alt me-2"></i>{{language.use_my_current_location}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'language'" key="language" :class="{ 'd-block show backdrop' : showModal== 'language'}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.select_language}}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body">
                                <div class="row contact-form">
                                    <ul class="mb-0">
                                        <label v-for="l in languages" :key="l.id" class="radio-container" @click="update_lang(l)">{{l.name}}
                                            <input type="radio" :checked="l.code == lang() ? true : false" name="radio">
                                            <span class="checkmark"></span>
                                        </label>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'feedback'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="submit_feedback" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{language.tooltip_feedback}}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12 col-sm-6">
                                        <label class="ms-3 mb-1">{{language.name}}</label>
                                        <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.name}" required v-model="feedback.name">
                                        <span class="invalid-feedback ms-2" v-if="errors.name"><strong>{{ errors.name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="ms-3 mb-1">{{language.mobile}}</label>
                                        <input type="tel" class="form-control" :class="{'is-invalid mb-0' : errors.mobile}" maxlength="10" v-model="feedback.mobile">
                                        <span class="invalid-feedback ms-2" v-if="errors.mobile"><strong>{{ errors.mobile[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="ms-3 mb-1">{{language.email}}</label>
                                        <input type="email" class="form-control" :class="{'is-invalid mb-0' : errors.email}" required v-model="feedback.email">
                                        <span class="invalid-feedback ms-2" v-if="errors.email"><strong>{{ errors.email[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <label class="ms-3 mb-1">{{language.click_to_refresh}}</label>
                                        <img :src="captcha_src" @click="captcha_src = '/get/captcha?' + (new Date()).getTime()" class="rounded-5 w-100 cursor-pointer" height="50">
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <label class="ms-3 mb-1">{{language.captcha}}</label>
                                        <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.captcha}" required v-model="feedback.captcha">
                                        <span class="invalid-feedback ms-2" v-if="errors.captcha"><strong>{{ errors.captcha[0] }}</strong></span>
                                    </div>
                                    <div class="col-12">
                                        <label class="ms-3 mb-1">{{language.message}}</label>
                                        <textarea v-model="feedback.message" class="form-control" :class="{'is-invalid mb-0' : errors.message}" required></textarea>
                                        <span class="invalid-feedback ms-2" v-if="errors.message"><strong>{{ errors.message[0] }}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light" @click="showModal = false;">{{language.close}}</button>
                                <button type="submit" class="main-button ms-4">{{language.send_message}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'email_login_details'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="submitSignupDetails()" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>Please provide details to continue</h3>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12 col-sm-6">
                                        <label class="ms-3 mb-1">{{language.first_name}}</label>
                                        <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.first_name}" required v-model="emailSignup.first_name">
                                        <span class="invalid-feedback ms-2" v-if="errors.first_name"><strong>{{ errors.first_name[0] }}</strong></span>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <label class="ms-3 mb-1">{{language.last_name}}</label>
                                        <input type="text" class="form-control" :class="{'is-invalid mb-0' : errors.last_name}" required v-model="emailSignup.last_name">
                                        <span class="invalid-feedback ms-2" v-if="errors.last_name"><strong>{{ errors.last_name[0] }}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="submit" class="main-button ms-4">{{language.continue}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </section>
    <vue-progress-bar></vue-progress-bar>
    <f_footer v-if="language" :language="language" />
    <transition name="fade">
        <div class="cookie-info" v-if="showCookiePrompt">
            <div class="container">
                <div class="d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <div class="text pe-md-5 text-center text-md-start">The website uses cookies to offer you essential features and increased performance. Read more in the
                        <a :href="$domain+'/cookie-policy'" target="_blank" class="href-primary">Cookies Policy</a>
                        . If you agree to the usage of performance cookies, click OK.
                    </div>
                    <div class="pt-2 pt-md-0">
                        <button class="main-button rounded-4" @click="cookie"><b>OK</b></button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .slide-up-enter-active, .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-up-enter-from {
        opacity: 0;
        transform: translateY(30px);
    }
    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.03);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
    .slide-left-enter-active, .slide-left-leave-active {
        transition: all 0.25s ease-out;
    }
    .slide-left-enter-from {
        opacity: 0;
        transform: translateX(100px);
    }
    .slide-left-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }
</style>
<script setup>
    import f_footer from "./partials/footer.vue" ;
    import f_header from "./partials/header.vue" ;
    import { getAuth, GoogleAuthProvider, getRedirectResult, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, signInWithRedirect, FacebookAuthProvider } from "firebase/auth";
    import { setDoc, doc } from "firebase/firestore";
    import { decodeCredential } from 'vue3-google-login'
</script>
<script>
export default {
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            user: false,
            language: false,
            languages: [],
            errors: [],
            default_display_type: 'grid',
            map_view: false,
            categories: false,
            store_types: false,
            event_types: false,
            flag: false,
            focus: false,
            add_emit: false,
            save_location: false,
            showModal: false,
            showClose: true,
            add_url: '/p/add',
            buttonOverlapsFooter: false,
            buttonOverlapsFooter1: false,
            disabled: false,
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            form: new Form({
                first_name: "",
                last_name: "",
                email: "",
                image_path: "",
                profile_type: "",
                profile_id: "",
                location_locality: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                latitude: "",
                longitude: "",
                ref_by: "",
                source: "",
            }),
            with_email: false,
            login_email: '',
            feedback: new Form({
                name:'',
                email:'',
                mobile:'',
                captcha:'',
                message:'',
            }),
            emailSignup: new Form({
                first_name:'',
                last_name:'',
                email:''
            }),
            display_locality: null,
            current_lang: {
                code: '',
                name: ''
            },
            actionCodeSettings: { url: this.$domain, handleCodeInApp: true, },
            showCookiePrompt: false,
            captcha_src: '/get/captcha',
        };
    },
    computed: {
        classes() {
            if(this.focus) return 'form-control mb-3 focus'
            else return 'form-control mb-3'
        }
    },
    async created() {

        var check = localStorage.getItem('storage_enabled')
        if(check == null || !check) this.showCookiePrompt = true

        if(this.token == null) this.user = []

        this.lang_data()

        this.display_locality = JSON.parse(localStorage.getItem('display_locality'))
        this.form.fill(this.display_locality)

        if(this.getCookie('display_locality_latitude') == undefined)
            this.setCookie('display_locality_latitude',this.display_locality.latitude)
        if(this.getCookie('display_locality_longitude') == undefined)
            this.setCookie('display_locality_longitude',this.display_locality.longitude)

        const auth = getAuth();
        getRedirectResult(auth).then((result) => {
            if (result) {
                toast.fire({
                    'icon': 'info',
                    'title': this.language.loading ,
                })
                if(result.providerId == 'google.com'){
                    this.processSignin(result,'google')
                }
                else{
                    this.processSignin(result,'facebook')
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    },
    watch: {
        with_email(n, o) {
            if(n) {
                this.$nextTick(() => {
                    this.$refs.login_email.focus()
                })
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
        this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
    },
    mounted() {
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll);
        this.emitter.on("update_user", data => {
            this.user = data
            this.feedback.name = this.user.user.first_name+' '+this.user.user.last_name
            this.feedback.email = this.user.user.email
            if(this.user.user.mobile_no && this.user.user.mobile_no != '0')
            this.feedback.mobile = this.user.user.mobile_no
        });
        this.emitter.on("fetch_lang", () => {
            this.lang_data()
        });
        this.emitter.on('showlogin', (data = null) => {
            this.showModal = 'login' ;
            this.showClose = data ? false : true
        })
        this.emitter.on('location_change', (data) => {
            if(data) this.disabled = true
            this.save_location = data
            this.showModal = 'location' ;
            // this.detect()
        })
        this.emitter.on('lang_modal', (data) => {
            this.languages = data
            this.showModal = 'language' ;
        })
        this.emitter.on('copy_url', (url) => {
            this.copyUrl(url) ;
        })
        this.emitter.on("loggedOut", () => { this.token = null ; this.user = [] ; });

        this.emitter.on('emailForSignIn', (data) => { this.emailSignInStep1(data) }) ;

        if(this.mobile) {
            window.onscroll = function() {myFunction()};
            var lastScrollTop = 0 ;
            function myFunction() {
                var cardheader = $("body .card-header")[0] ;
                var pageY = window.scrollY;
                // var sticky = pageY < lastScrollTop ? cardheader.offsetTop + 8 : cardheader.offsetTop;
                var sticky = cardheader.offsetTop + 285;
                // if (pageY > sticky && pageY < lastScrollTop) {
                if (pageY > sticky) {
                    $(cardheader).addClass("fixed");
                } else {
                    $(cardheader).removeClass("fixed");
                }
                // lastScrollTop = pageY <= 0 ? 0 : pageY;
            }
        }
    },
    updated() {
        this.emitter.on("loggedOut", () => { this.token = null ; this.user = [] ; });
        this.emitter.on("product_add", (url) => {
            this.add_url = url ;
        })
    },
    methods: {
        async lang_data() {
            await axios.get(this.$api+'get-app-data/'+this.lang()).then((res) => {
                this.language = res.data.lang
                this.categories = res.data.categories
                this.store_types = res.data.store_types
                this.event_types = res.data.event_types
                this.default_display_type = res.data.default_display_type
                this.map_view = res.data.map_view
            }).catch((e) => { console.log(e); });
        },
        async signin(type) {
            if(type == 'google') {
                const provider  =  new GoogleAuthProvider();
                provider.addScope("email");
                await signInWithRedirect(getAuth(), provider);
            }
            else {
                axios.post(this.$api + "auth/send-sign-in-email", {email: this.login_email}).then(() => {
                    toast.fire({ icon: 'info', title: this.language.email_sent})
                    window.localStorage.setItem('emailForSignIn', this.login_email);
                    this.login_email = ''
                    this.with_email = false
                    this.showModal = false
                })
                .catch((error) => {
                    console.log(error);
                });
            }

        },
        async processSignin(response) {
            toast.fire({
                'icon': 'info',
                'title': this.language.loading ,
            })
            // console.log(response);
            // this.verifyCode(response.code).then((userInfo) => {
            //     console.log(userInfo);
            // }).catch((error) => {
            // // validation failed and userinfo was not obtained
            // })
            // return ;
            response = decodeCredential(response.credential)
            this.$Progress.start() ;
            this.form.first_name = response.given_name;
            this.form.last_name = response.family_name;
            this.form.email = response.email;
            this.form.profile_id = response.jti.substring(0,8);
            this.form.profile_type = 'google';
            this.form.image_path = response.picture;

            var refData = localStorage.getItem('refData')
            if(refData && refData!='{}'){
                this.form.ref_by = JSON.parse(refData).ref_by;
                this.form.source = JSON.parse(refData).source;
            }

            await axios.post(this.$api + "auth/check-user", this.form).then((res) => {

                this.$Progress.finish() ;
                if(res.data.status == "exist"){
                    toast.fire({
                        'icon' : 'warning',
                        'title': res.data.message,
                    });
                    return ;
                }
                this.showModal = false;
                localStorage.setItem("greta", res.data.token);
                if(res.data.status != "logged in"){
                    setDoc(doc(db, 'users', response.jti.substring(0,8)), {
                        "Email" : this.form.email,
                        "assetImage" : this.form.email,
                        "blocked_by" : [],
                        "blocked_usr" : [],
                        "chattingWith" : null,
                        "createdAt" :Date.now().toString(),
                        "groups" : [],
                        "chats" : [],
                        "id" : response.jti.substring(0,8),
                        "imageBlob" : res.data.user_img,
                        "nickname" : response.user.displayName,
                        "photoUrl" : response.user.photoURL,
                        "pushToken" : '',
                    })
                }
                
                if(refData!='{}') localStorage.removeItem('refData')

                this.emitter.emit("loggedIn", res.data.token);
                this.showModal = false;
                this.token = res.data.token
                if(this.add_emit) {
                    this.floating() ; this.add_emit = false ;
                }
            }).catch((e) => { this.$Progress.fail() ; });
        },

        async emailSignInStep1(response) {
            // this.$Progress.start() ;
            // this.form.first_name =  'to_be_set';
            // this.form.last_name =  'to_be_set';
            // this.form.email = response.email;
            // this.form.profile_id = response.uid;
            // this.form.profile_type = 'email';
            // this.form.image_path = 'to_be_Set';

            // let data = {
            //     'email' : response.email
            // }
            this.form.email = response.email
            this.emailSignup.email = response.email

            await axios.post(this.$api + "auth/check-user-by-email", this.form).then((res) => {

                this.$Progress.finish() ;
                if(res.data.status == "exist"){
                    toast.fire({
                        'icon' : 'warning',
                        'title': res.data.message,
                    });
                    return ;
                }
                this.showModal = false;

                if(res.data.status == "new_user"){
                  this.showModal = 'email_login_details'
                }
                else{
                    localStorage.setItem("greta", res.data.token);
                    this.emitter.emit("loggedIn", res.data.token);
                    this.showModal = false;
                    this.token = res.data.token
                    if(this.add_emit) {
                        this.floating() ; this.add_emit = false ;
                    }
                }
            }).catch((e) => { this.$Progress.fail() ; });
        },

        submitSignupDetails() {
            this.$Progress.start() ;
            this.form.first_name =  this.emailSignup.first_name;
            this.form.last_name =  this.emailSignup.last_name;
            this.form.email =  this.emailSignup.email;
            this.form.profile_id = 'to_be_set';
            this.form.profile_type = 'email';
            this.form.image_path = 'to_be_Set';
            
            var refData = localStorage.getItem('refData')
            if(refData!='{}'){
                this.form.ref_by = JSON.parse(refData).ref_by;
                this.form.source = JSON.parse(refData).source;
            }

            axios.post(this.$api + 'auth/email-signup', this.form).then((res) => {
                this.errors = []
                this.emailSignup.reset() ;
                setDoc(doc(db, 'users', res.data.user.social_profile_id), {
                    "Email" : res.data.user.email,
                    "assetImage" : res.data.user.email,
                    "blocked_by" : [],
                    "blocked_usr" : [],
                    "chattingWith" : null,
                    "createdAt" : Date.now().toString(),
                    "groups" : [],
                    "chats" : [],
                    "id" : res.data.user.social_profile_id,
                    "imageBlob" : res.data.user.thumbnail_image,
                    "nickname" : res.data.user.first_name,
                    "photoUrl" : res.data.user.thumbnail_image,
                    "pushToken" : '',
                })

                if(refData!='{}') localStorage.removeItem('refData')

                this.$Progress.finish() ;
                this.showModal = false
                toast.fire({ icon: 'success', title: 'Signed up successfully'})
                localStorage.setItem("greta", res.data.token);
                this.emitter.emit("loggedIn", res.data.token);
                this.showModal = false;
                this.token = res.data.token
                if(this.add_emit) {
                    this.floating() ; this.add_emit = false ;
                }

            }).catch((error) => {
                console.log(error);
                if(error.response.status && error.response.status === 422) this.errors = error.response.data.errors;
                toast.fire({ icon: "error", title: this.language.toast_error_occured });
                this.$Progress.fail() ;
            })
        },

        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
            this.showModal = false
        },
        async locationiq(coords) {
            if(this.flag) {
                this.display_locality.latitude = coords.latitude ;
                this.display_locality.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get(
                    "https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" +
                    this.display_locality.latitude +
                    "&lon=" +
                    this.display_locality.longitude +
                    "&format=json"
                ).then((res) => {

                    this.display_locality.type = 'current'
                    this.display_locality.name = this.display_locality.location_locality =
                    res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;

                    this.display_locality.country = res.data.address.country_code;
                    this.display_locality.state = res.data.address.state;
                    this.display_locality.city = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                    this.display_locality.pincode = res.data.address.postcode;

                    localStorage.setItem('display_locality', JSON.stringify(this.display_locality))

                    this.form.fill(this.display_locality)

                    this.setCookie('display_locality_latitude',this.display_locality.latitude)
                    this.setCookie('display_locality_longitude',this.display_locality.longitude)

                    this.emitter.emit("location_updated")
                    if(this.save_location && this.token != null) this.update_location()
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
        cookie() {
            localStorage.setItem('storage_enabled',true)
            this.showCookiePrompt = false
        },
        class_change(item = null) {
            if(item) $('#location_autocomplete').removeClass('focus')
            else {
                var val = $('#location_autocomplete').val() ;
                if(val.length > 0) $('#location_autocomplete').addClass('focus')
                else $('#location_autocomplete').removeClass('focus')
            }
        },
        getAddressData(placeResultData) {
            this.focus = false
            const place = JSON.parse(JSON.stringify(placeResultData))

            this.display_locality.type = 'custom'
            this.display_locality.latitude = place.geometry.location.lat
            this.display_locality.longitude = place.geometry.location.lng

            place.address_components.forEach(i => {
                if(i.types.indexOf('country') > -1)
                    this.display_locality.country = i.short_name
            })

            this.display_locality.city =
            this.display_locality.state = this.display_locality.location_locality =
            this.display_locality.name = place.formatted_address

            this.display_locality.pincode = '000000'

            localStorage.setItem('display_locality', JSON.stringify(this.display_locality))

            this.form.fill(this.display_locality)

            this.setCookie('display_locality_latitude',this.display_locality.latitude)
            this.setCookie('display_locality_longitude',this.display_locality.longitude)

            this.showModal = false;
            this.emitter.emit("location_updated")
            if(this.save_location && this.token != null) this.update_location()
        },
        update_lang(l){
            this.current_lang.name = l.name
            this.current_lang.code = l.code
            var expires = "";
            var date = new Date();
            date.setTime(date.getTime() + (30*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
            document.cookie = "locale=" + (l.code || "")  + expires + "; path=/";
            this.emitter.emit("fetch_lang")
            this.emitter.emit("updated_lang", l)
            this.showModal = false
        },
        detect() {
            const targetElement = $('body').find('.pac-container:last')[0];
            const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    this.focus = true
                }
            }
            });
            const observerConfig = { attributes: true, attributeFilter: ['style'] };
            observer.observe(targetElement, observerConfig);
        },
        closeDropdown() {
            this.showModal = false
        },
        update_location() {

            this.$Progress.start()
            let fd = new FormData();
            fd.append('latitude',this.form.latitude)
            fd.append('longitude',this.form.longitude)
            fd.append('city',this.form.city)
            fd.append('state',this.form.state)
            fd.append('pincode',this.form.pincode)
            fd.append('location_locality',this.form.location_locality)
            fd.append('country',this.form.country)
            // fd.append('img_counter',0)

            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            axios.post(this.$api+'user/update-profile-location',fd,config).then((response) => {
                if(response.data.status){
                    toast.fire({
                        icon: "success",
                        title: this.language.toast_profile_updated,
                    });
                    this.save_location = false
                    this.disabled = false
                    this.emitter.emit('get_user')
                    this.$Progress.finish()
                }
            }).catch((e) => {
                this.$Progress.fail()
            });
        },
        handleScroll() {
            const button = $('.floating-button')[0];
            const button1 = $('.floating-button-1')[0];
            const footer = $('body .footer')[0];
            const distance = footer?.getBoundingClientRect()?.top - button?.getBoundingClientRect()?.bottom;
            const distance1 = footer?.getBoundingClientRect()?.top - button1?.getBoundingClientRect()?.bottom;
            this.buttonOverlapsFooter = distance <= 0;
            this.buttonOverlapsFooter1 = distance1 <= 0;
        },
        submit_feedback() {
            this.$Progress.start() ;
            axios.post(this.$domain + '/send-feedback', this.feedback).then((res) => {
                this.errors = []
                this.feedback.captcha = '' ;
                this.feedback.message = '' ;
                this.$Progress.finish() ;
                // this.showModal = false
                toast.fire({ icon: 'success', title: 'Message Sent Successfully!'})
            }).catch((error) => {
                if(error.response.status && error.response.status === 422) this.errors = error.response.data.errors;
                toast.fire({ icon: "error", title: this.language.toast_error_occured });
                this.$Progress.fail() ;
            })
        },
        floating() {
            if(this.token == null) {
                this.add_emit = true ;
                this.emitter.emit('showlogin') ;
            }
            else {
                if(this.user.can_post)
                    this.$router.push(this.add_url)
                else
                    this.$router.push('/checkout?type=new-ads') ;
            }
        },
        copyToClipboard(text) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                return navigator.clipboard.writeText(text);
            } else {
                var textArea = document.createElement("textarea");
                textArea.value = text;
                textArea.style.position = "fixed"; // Ensure it is invisible
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand("copy");
                    var msg = successful ? "successful" : "unsuccessful";
                } catch (err) {
                    console.error("Fallback: Unable to copy to clipboard", err);
                }

                document.body.removeChild(textArea);
                return Promise.resolve();
            }
        },
        copyUrl(data) {
            if(this.token!=null)
                var url = data[0]+'?ref_by='+this.user.user.ref_code+'&source='+data[1]
            this.copyToClipboard(url).then(() => {
                toast.fire({
                    'icon': 'success',
                    'title': this.language.copied,
                })
            })
            .catch((err) => { console.error("Failed to copy URL to clipboard:", err); });
        },
        show_feedback() {
            if(this.token) {
                this.captcha_src = '/get/captcha?' + (new Date()).getTime()
                this.showModal = 'feedback'
            }
            else {
                toast.fire({ 'icon': 'info', 'title': this.language.toast_login_feedback})
                this.showModal = 'login'
            }
        },
    }
}
</script>
