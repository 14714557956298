<template>
    <div class="row position-relative">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-ec">
                    <button type="button" class="main-button small me-2" @click="goback()"><i
                            class="fas fa-arrow-left fa-lg"></i></button>
                    <h3 class="me-auto">
                        <router-link to="/"><i class="fas cursor-pointer fa-store me-1 me-lg-2"></i></router-link>
                        <i class="fas fa-chevron-right fa-sm me-1 me-lg-2"></i>
                        {{ language.event_details }}
                    </h3>
                    <transition-group name="fade">
                        <button type="button" key="edit" v-if="loading == false && event && event.user_id == user?.user?.id" class="main-button small"
                            :class="{ 'me-4': token }" @click="editEvent(event.slug)">
                            <i class="fas fa-edit" :class="{ 'pe-1': !mobile }"></i>
                            <span v-if="!mobile">{{ language.edit }}</span>
                        </button>
                        <button type="button" key="delete" v-if="loading == false && event && event.user_id == user?.user?.id" class="main-button small"
                            :class="{ 'me-4': token }" @click="deleteEvent(event.crypt_id)">
                            <i class="fas fa-trash" :class="{ 'pe-1': !mobile }"></i>
                            <span v-if="!mobile">{{ language.delete }}</span>
                        </button>
                        <button type="button" key="share" v-if="loading == false && event" class="main-button small"
                            :class="{ 'me-4': token }" @click="showModal = 'share'">
                            <i class="fas fa-share-alt" :class="{ 'pe-1': !mobile }"></i>
                            <span v-if="!mobile">{{ language.share }}</span>
                        </button>
                    </transition-group>
                </div>
                <div class="card-body" v-if="event != []">
                    <div class="row" :class="{ 'match-height': !mobile }">
                        <div class="col-12 col-lg-5">
                            <div class="px-md-4" :class="event.images.length > 1 ? 'pb-4' : ''">
                                <swiper :effect="event.images.length > 1 ? 'creative' : ''" :creativeEffect="{
                        prev: {
                            shadow: true,
                            translate: [0, 0, -800],
                            rotate: [180, 0, 0],
                        },
                        next: {
                            shadow: true,
                            translate: [0, 0, -800],
                            rotate: [-180, 0, 0],
                        },
                    }" :slides-per-view="1"
                                    :keyboard="event.images.length > 1 ? { enabled: true } : false"
                                    :pagination="event.images.length > 1 ? { type: 'fraction' } : false"
                                    :autoplay="false" :navigation="true" :loop="event.images.length > 1 ? true : false"
                                    :speed="700" :autoHeight="mobile ? false : true" :modules="modules">
                                    <!-- :navigation="true" -->
                                    <swiper-slide v-for="(img, index) in event.images" :key="index">
                                        <div class="blog-post-thumb">
                                            <img draggable="false" class="img-fluid" :src="$s3_url + img.path"
                                                @click="openModalOnSlide(index)">
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7">
                            <div class="d-flex flex-column h-100 pt-lg-2">
                                <h2 class="fw-bolder product-title my-3">
                                    {{ event.name }}
                                </h2>
                                <div class="flex-bc pb-2">
                                    <p class="mb-0" v-if="loading == false && event">
                                        <i class="fas fa-map-marker-alt pe-1"></i> {{ event.distance }} {{
                        event.distance <= 1 ? 'km' : 'kms' }} {{ language.away }} </p>
                                </div>
                                <p v-if="token" class="product-description mb-1"><b>{{ language.description }}: </b>
                                    <transition name="fade" mode="out-in">
                                        <span :key="showingFullText">{{ formattedBody }}</span>
                                    </transition>
                                    <span v-if="event.description.split('\n').length >= 10"
                                        class="text-primary cursor-pointer"
                                        @click="showingFullText = !showingFullText"><u>Read {{ showingFullText ? "Less"
                        : "More" }}<br><br>
                                        </u></span>
                                </p>
                                <div v-else class="my-2">
                                    <button type="button" @click="emitter.emit('showlogin')" class="main-button">
                                        {{ language.login_readmore }}
                                    </button>
                                </div>
                                <p :class="{ 'ms-1': !token }" class="fs-6 text-muted mb-1"
                                    v-if="event.trade_type == 1 && event.looking_for"><b>{{ language.looking_for }}:</b> {{
                        event.looking_for }}</p>
                                <p v-if="event.mobile" :class="{ 'ms-1': !token }" class="fs-6 mb-1">
                                    <b>{{ language.contact_no }}: </b> <a class="hover"
                                        :href="'tel:' + event.mobile"><u>{{ event.mobile }}</u></a>
                                </p>
                                <p v-if="loading == false && event" :class="{ 'ms-1': !token }" class="mb-1">
                                    <b>{{ language.posted_by }}: </b>
                                    <router-link :to="'/u/' + event.user_details.slug" class="fs-6 hover">
                                        <u>{{ event.user_details ? event.user_details.first_name : '' }}</u>
                                    </router-link>
                                </p>
                                <p v-if="loading == false && event" :class="{ 'ms-1': !token }" class="mb-1">
                                    <b>Event Date: </b>
                                    <span>{{ event.formatted_date }}</span>
                                </p>
                                <p v-if="loading == false && event" :class="{ 'ms-1': !token }" class="mb-1">
                                    <b>Location: </b>
                                    <span>{{ event.location_name }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-3" v-if="mobile">
                            <span v-if="(p == undefined || p)" key="prev" class="pe-1"
                                :class="p == undefined ? 'text-muted' : 'text-primary'">
                                <i @click="p == undefined ? '' : $router.push('/event/' + p)"
                                    class="fas fa-2x fa-chevron-circle-left"></i>
                            </span>
                            <span v-if="(n == undefined || n)" key="next" class="ps-1"
                                :class="n == undefined ? 'text-muted' : 'text-primary'">
                                <i @click="n == undefined ? '' : $router.push('/event/' + n)"
                                    class="fas fa-2x fa-chevron-circle-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <NotFound :lang="language" :type="text" :load="loading" v-if="!event"></NotFound>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'report'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="submit_report" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.report_user }}</h3>
                                <a href="javascript:;" @click="showModal = false; form.reset();"
                                    class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row contact-form">
                                    <div class="col-12 col-lg-4">
                                        <label>{{ language.report_option }}</label>
                                        <select class="form-control" v-model.number="form.radio_message_id">
                                            <!-- To be Made Dynamic -->
                                            <option value="1">{{ language.report_option_1 }}</option>
                                            <option value="2">{{ language.report_option_2 }}</option>
                                            <option value="3">{{ language.report_option_3 }}</option>
                                            <option value="4">{{ language.report_option_4 }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <label class="ms-3 mb-1">{{ language.other_reason }}</label>
                                        <input type="text" v-model="form.reason" class="form-control" required
                                            autofocus>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button type="button" class="main-button light"
                                    @click="showModal = false; form.reset();">{{ language.close }}</button>
                                <button type="submit" class="main-button ms-4">{{ language.report }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'share'" :class="{ 'd-block show backdrop': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.share_text }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i
                                        class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row networks-body">
                                    <div class="col-12 mb-3">
                                        <div
                                            class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                                            <span class="link pe-md-4">{{ $domain + '/event/' + event.slug }}</span>
                                            <span class="ms-md-auto">
                                                <button type="button" class="main-button small"
                                                    @click="emitter.emit('copy_url', [$domain + '/event/' + event.slug, 'event_view'])">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.copy }}
                                                </button>
                                            </span>
                                        </div>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="col-3 d-flex justify-content-center" v-for="network in networks">
                                        <ShareNetwork :network="network.network" :key="network.network"
                                            :style="{ backgroundColor: network.color, border: '1px solid ' + network.color }"
                                            class="network-icon" :url="sharing.url" :title="sharing.title"
                                            :description="sharing.description" :quote="sharing.quote"
                                            :hashtags="sharing.hashtags" :twitterUser="sharing.twitterUser">
                                            <i :class="network.icon"></i>
                                            <span class="ms-2" v-if="!mobile">{{ network.name }}</span>
                                        </ShareNetwork>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'image'" :class="{ 'd-block show backdrop-dark': showModal }"
                tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content bg-transparent border-0 flex-lg-row align-items-center">
                        <div class="nav-buttons text-white me-2" v-if="!mobile && event.images.length > 1">
                            <i class="fas fa-chevron-circle-left"
                                @click="slide = (slide == 0 ? (event.images.length - 1) : slide - 1);"></i>
                        </div>
                        <div class="card-body text-center" style="height:80vh;">
                            <div class="position-relative h-100"
                                :class="slide == index ? 'd-inline-block align-self-center' : 'd-none'"
                                v-for="(img, index) in event.images">
                                <img draggable="false" :src="$s3_url + img.original_path" class="modal-img">
                                <button type="button" class="main-button small light close" @click="showModal = false">
                                    <i class="fas fa-times fa-15x"></i>
                                </button>
                            </div>
                        </div>
                        <div class="nav-buttons text-white ms-2" v-if="!mobile && event.images.length > 1">
                            <i class="fas fa-chevron-circle-right"
                                @click="slide = (slide == (event.images.length - 1) ? 0 : slide + 1);"></i>
                        </div>
                        <div class="nav-buttons text-white mt-2" v-if="mobile">
                            <i class="fas fa-chevron-circle-left me-2"
                                @click="slide = (slide == 0 ? (event.images.length-1) : slide-1) ;"></i>
                            <i class="fas fa-chevron-circle-right ms-2"
                                @click="slide = (slide == (event.images.length-1) ? 0 : slide+1) ;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
        margin-bottom: 60px;
    }

    100% {
        transform: scale(1);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
</style>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import "swiper/css/effect-flip";
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';
import 'swiper/css/autoplay';
import { EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation } from 'swiper';
import { collection, setDoc, updateDoc, getDoc, arrayUnion, doc } from "firebase/firestore";
</script>
<script>
export default {
    name: 'EventView',
    props: ['user', 'language'],
    data() {
        return {
            token: this.isLogged(),
            slide: 1,
            navdrop: false,
            event: false,
            total_likes: '',
            is_liked: '',
            liked: '',
            type: '',
            loading: true,
            docid: '',
            active_chats: [],
            groups: [],
            related_events: [],
            showingFullText: false,
            mobile: this.isMobile(),
            text: this.language.loading,
            n: false,
            p: false,
            showModal: false,
            fromgroup: false,
            fromPath: false,
            modalImg: false,
            form: new Form({
                event_id: this.$route.params.id,
                radio_message_id: 1,
                reason: '',
            }),
            modules: [EffectFlip, Autoplay, Pagination, Keyboard, EffectCreative, Navigation],
            networks: [
                { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
            sharing: {
                url: '',
                title: '',
                description: '',
                hashtags: 'thrift,sell,trade,exchange',
            },
        };
    },
    created() {
        this.type = this.$route.query.type;
        this.index()

        this.emitter.on("update_user", (data) => {
            if (this.$route.name == 'EventView') {
                this.token = this.isLogged();
                this.index()
            }
        });
        this.emitter.on("loggedOut", () => {
            if (this.$route.name == 'EventView') {
                this.token = null
                this.index()
            }
        });
        this.emitter.on('removeModal', () => {
            this.showModal = false
        })

        if (window.history.length > 0 && this.type) {
            this.emitter.emit('get-np', { type: this.type, id: this.$route.params.id })
        }

        this.emitter.on('set-np', (e) => {
            this.n = (e.n == undefined ? undefined : e.n + '?type=' + this.type + (this.type == 'group' ? '&id=' + this.$route.query.id : ''))
            this.p = (e.p == undefined ? undefined : e.p + '?type=' + this.type + (this.type == 'group' ? '&id=' + this.$route.query.id : ''))
        })
    },
    beforeMount() {
        this.emitter.off('set-np')
        this.emitter.off('set-gnp')
    },
    updated() {
        if (!this.mobile) $('[data-toggle="tooltip"]').tooltip()
    },
    beforeMount() {
        document.title = "RedThrift - Event";
        $('head').append('<link rel="canonical" href="' + this.$domain + this.$route.fullPath + '" />')
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    computed: {
        sources() {
            return this.event.images.map(img => {
                return this.$s3_url + img.original_path;
            });
        },
        formattedBody() {
            if (this.showingFullText || this.event.description.split('\n').length < 10) {
                return `${this.event.description} `;
            }
            return `${this.event.description.split('\n').slice(0, 10).join('\n').trim()}... `;
        },
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    methods: {
        index() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            axios.get(this.$api + "events/event/" + this.$route.params.id, config).then((res) => {
                if (res.data.status === false) {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.event_not_found
                    this.fromgroup = res.data.group_name
                }
                else {
                    this.event = res.data.event
                    this.related_events = []
                    this.loading = false

                    this.text = this.language.no_events_to_display
                    this.sharing.url = this.$domain + '/event/' + this.$route.params.id
                    this.sharing.title = `${this.event.title}\n`
                    // this.sharing.description = `${this.language.share_text}\n`
                    this.sharing.description = ''
                    this.$Progress.finish()
                    $('head meta[name="description"]').remove()
                    $('head meta[name="title"]').remove()
                    $('head').prepend('<meta name="og:description" content="' + this.event.title + '" />')
                    $('head').prepend('<meta name="og:title" content="Red Thrift - Don\'t trash it, encash it!" />')
                    $('head').prepend('<meta name="og:image" content="' + this.$s3_url + this.event.images[0].path + '" />')
                }
            }).catch((e) => {
                this.$Progress.fail()
                this.loading = false
                this.text = this.language.server_error
            });
        },
        share() {
            if (navigator.share) {
                navigator.share({
                    title: this.event.title,
                    text: this.language.share_text,
                    url: this.$domain + '/p/' + this.event.slug,
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
            }
        },
        deleteEventForUser(id) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var thiss = this
            Swal.fire({
                title: this.lang.toast_are_you_sure,
                text: this.lang.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.lang.toast_yes_delete,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    thiss.$Progress.start()
                    axios.get(thiss.$api + 'events/remove' + id, config).then((response) => {
                        if (response.data.status) {
                            thiss.$Progress.finish()
                            thiss.emitter.emit('remove_event', thiss.index)
                            toast.fire({
                                icon: "success",
                                title: thiss.lang.toast_event_deleted_success,
                            });
                            thiss.emitter.emit('get_user')
                        }
                    }).catch((e) => {
                        thiss.$Progress.fail()
                        toast.fire({
                            icon: "error",
                            title: thiss.lang.server_error,
                        });
                    });
                }
            });

        },
        editEvent(slug) {
            this.$router.push('/events/update/' + slug)
        },
        showImageModal(index) {
            this.modalImg = this.$s3_url + this.event.images[index].original_path
            this.showModal = 'image'
        },
        openModalOnSlide: function (number) {
            this.slide = number;
            this.showModal = 'image'
        },
        async report_firebase() {
            var chk = doc(db, "messages", this.event.user_details.social_profile_id + "-" + this.user.user.social_profile_id);
            var docSnap = await getDoc(chk);
            this.docid = ''
            if (docSnap.exists()) {
                this.docid = this.event.user_details.social_profile_id + "-" + this.user.user.social_profile_id;
            } else {
                this.docid = this.user.user.social_profile_id + "-" + this.event.user_details.social_profile_id
            }
            var docRef = doc(db, "messages", this.docid);
            updateDoc(docRef, {
                "isReported": true,
                "reported_by": this.user.user.social_profile_id,
                "reported_on": Date.now().toString(),
            })
        },
        goback() {
            window.history.state.back ? this.$router.go(-1) : this.$router.push('/')
        },
    },
}
</script>
