<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card">
                <div class="card-header flex-bc">
                    <button type="button" class="main-button small me-1" @click="$router.go(-1)"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <h3>{{ language.add_product_details }}</h3>
                    <button type="button" @click="showModal = 'share'" class="main-button small ms-auto">
                        <i class="fa fa-share-alt me-2"></i>{{ language.share }}
                    </button>
                </div>
                <form class="card-body contact-form" @submit.prevent="submit_details">
                    <div class="row">
                        <div class="col-12 required col-md-9">
                            <label class="ms-2 mb-1">{{ language.title }}</label>
                            <input type="text" maxlength="100" @keypress="validate" v-model="form.title" class="form-control" :class="{ 'is-invalid': errors.title }" required autofocus :placeholder="language.placeholder_max_100">
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.title"><strong>{{ errors.title[0]}}</strong></span>
                        </div>
                        <div class="col-12 required col-md-3">
                            <label class="ms-2 mb-1">{{ language.trade_type }}</label>
                            <v-select :clearable="false" :searchable="false" :reduce="(option) => option.value" class="v-form-control" v-model="form.trade_type" required :options="ttypes">
                                <template #search="{attributes, events}">
                                    <input class="vs__search" :required="!form.trade_type" v-bind="attributes" v-on="events" />
                                </template>
                            </v-select>
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.trade_type"><strong>{{ errors.trade_type[0]}}</strong></span>
                        </div>
                        <!-- <div class="col-12 col-md-6">
                            <label class="ms-2 mb-1">{{ language.looking_for}}</label>
                            <input type="text" @keypress="validate" v-model="form.looking_for" maxlength="50" class="form-control" :class="{ 'is-invalid': errors.looking_for }" :placeholder="language.placeholder_max_50">
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.looking_for"><strong>{{ errors.looking_for[0]}}</strong></span>
                        </div> -->
                        <div class="col-12 required col-md-4">
                            <label class="ms-2 mb-1">{{ language.price }}</label>
                            <input type="tel" @keypress="validate_price" v-model.number="form.price" class="form-control" :class="{ 'is-invalid': errors.price }" required maxlength="7" min="1">
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.price"><strong>{{ errors.price[0]}}</strong></span>
                        </div>
                        <div class="col-12 col-md-4 required">
                            <label class="ms-2 mb-1">{{ language.mobile }}</label>
                            <input type="tel" @keypress="validate_price" required v-model.number="form.mobile" class="form-control" :class="{ 'is-invalid': errors.mobile }" maxlength="15" minlength="7">
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.mobile"><strong>{{ errors.mobile[0]}}</strong></span>
                        </div>
                        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
                            <div class="custom-control custom-switch custom-switch-lg">
                                <input type="checkbox" v-model="form.post_to_market" class="custom-control-input" id="ptm">
                                <label class="custom-control-label" for="ptm">{{ language.post_to_marketplace }}</label>
                            </div>
                        </div>
                        <div class="col-12 required">
                            <label class="ms-2 mb-1">{{ language.description }}</label>
                            <textarea v-model="form.description" class="form-control" :class="{ 'is-invalid': errors.description }" required ></textarea>
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.description"><strong>{{ errors.description[0]}}</strong></span>
                        </div>
                        <div class="col-12">
                            <label class="ms-2 mb-1">{{ language.location }}</label>
                            <div class="row">
                                <div class="col-12 col-md-5 col-lg-7">
                                    <GMapAutocomplete @placeholder="language?.search_your_location || 'N/A'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" @class="'mb-4 form-control'" ></GMapAutocomplete>
                                </div>
                                <div class="col-md-5 flex-cc col-lg-3">
                                    <button type="button" @click="current_location" class="main-button mb-4"><i class="fa fa-map-marker-alt me-2"></i>{{language.use_my_current_location}}</button>
                                </div>
                                <div class="flex-cc col-md-2 col-lg-2">
                                    <transition name="bounce">
                                        <button data-toggle="tooltip" :title="form.loc_label" key="change" aria-label="change" type="button" v-if="form.loc_label" class="main-button w-100 justify-content-center light small mb-4">
                                            <i :class="mobile  ? '' : 'fa-15x'" class="fa fa-map-marker-alt pe-1 pe-lg-2 text-body"></i>
                                            <span class="location">{{ form.loc_label }}</span>
                                        </button>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 required col-lg-4">
                            <label class="ms-2 mb-1">{{ language.category }}</label>
                            <v-select :clearable="false" @option:selected="reset_subcat" :reduce="(option) => option.value" class="v-form-control" v-model="form.cat" required :options="comp_cats">
                                <template #search="{attributes, events}">
                                    <input class="vs__search" :required="!form.cat" v-bind="attributes" v-on="events" />
                                </template>
                            </v-select>
                        </div>
                        <div class="col-12 required col-lg-4">
                            <label class="ms-2 mb-1">{{ language.sub_category }}</label>
                            <v-select :clearable="false" @option:selected="subcat_change" class="v-form-control" v-model="form.subcat" :reduce="(option) => option.value" required :options="comp_subcats">
                                <template #search="{attributes, events}">
                                    <input class="vs__search" :required="!form.subcat" v-bind="attributes" v-on="events" />
                                </template>
                            </v-select>
                        </div>
                        <div class="col-12 required col-lg-4">
                            <label class="ms-2 mb-1">{{ language.images }}</label>
                            <input type="file" ref="images" class="form-control" accept="image/*" @change="uploadphoto" multiple :class="{ 'is-invalid': errors.original_images }" :required="previews.length == 0 ? true : false">
                            <span class="ms-2 mb-1 invalid-feedback" role="alert" v-if="errors.original_images"><strong>{{ errors.original_images[0]}}</strong></span>
                        </div>
                        <div class="col-12 col-lg-6">
                            <label class="ms-2 mb-4"></label>
                            <div class="row">
                                <div class="col-12 col-lg-6 d-flex justify-content-center">
                                    <div class="custom-control custom-switch custom-switch-lg">
                                        <input type="checkbox" v-model="form.post_to_group" @change="checkgroup()" class="custom-control-input" id="ptg">
                                        <label class="custom-control-label" for="ptg">{{ language.post_to_group }}</label>
                                    </div>
                                </div>
                                <transition name="fade">
                                    <div class="col-12 col-lg-6 d-flex justify-content-center" v-if="form.post_to_group">
                                        <div class="custom-control custom-switch custom-switch-lg">
                                            <input type="checkbox" v-model="select_all" @change="select_all_groups()" class="custom-control-input" id="select_all">
                                            <label class="custom-control-label" for="select_all">{{ language.select_all }}</label>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <transition-group name="fade">
                            <div v-if="form.post_to_group" class="col-12 col-lg-6">
                                <label class="ms-2 mb-1">{{ language.select_group }}</label>
                                <v-select class="v-form-control" multiple v-model="form.group_ids" :reduce="(option) => option.value" required :options="comp_groups">
                                    <template #search="{attributes, events}">
                                        <input
                                        class="vs__search"
                                        :required="form.post_to_group && form.group_ids.length == 0 && comp_groups.length > 0"
                                        v-bind="attributes"
                                        v-on="events"
                                        />
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-12" ref="preview_container" v-if="previews.length > 0">
                                <div class="row">
                                    <transition-group name="bounce">
                                        <div class="col-6 col-lg-3" v-for="(img,i) in previews" :key="i">
                                            <div class="blog-post-thumb p-0 position-relative">
                                                <button type="button" class="main-button small close" @click="removeimage(i)">
                                                    <i class="fas fa-times fa-15x"></i>
                                                </button>
                                                <div class="img rounded-3">
                                                    <img @click="showImageModal(img)" :src="img" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                    </transition-group>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <transition name="fade">
                        <div class="row pt-4" v-if="show_custom">
                            <h4 class="title">Additional Information About Product<hr></h4>
                            <custom-field :key="index" :language="language" :errors="errors" v-model:model_value="additional['field_'+c.id]" :value="additional['field_'+c.id]" :field="c" v-for="(c,index) in customFields"></custom-field>
                        </div>
                    </transition>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                            <hr /><button type="button" class="main-button light me-3" @click="reset_form()">{{ language.reset }}</button>
                            <button type="submit" class="main-button" :disabled="disabled">
                                <transition name="fade">
                                    <i v-if="disabled" class="fas fa-spinner fa-spin"></i>
                                </transition>
                                {{ language.add_product }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'image'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="card-body text-center position-relative" style="height:80vh;">
                            <div class="d-inline-block position-relative h-100">
                                <img :src="modalImg" class="modal-img">
                                <button type="button" class="main-button small light close" @click="showModal = false">
                                    <i class="fas fa-times fa-15x"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" v-if="showModal == 'share'" :class="{ 'd-block show backdrop' : showModal}" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <div class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.share_link }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light"><i class="fas fa-times fa-2x"></i></a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row networks-body">
                                    <div class="col-12 mb-3">
                                        <div class="group-block small d-flex align-items-center flex-column flex-md-row justify-content-center">
                                            <span class="link pe-md-4">{{ $domain+'/p/add' }}</span>
                                            <span class="ms-md-auto">
                                                <button type="button" class="main-button small" @click="emitter.emit('copy_url', [$domain+'/p/add', 'product_add'])">
                                                    <i class="far fa-clipboard me-2"></i> {{ language.copy }}
                                                </button>
                                            </span>
                                        </div>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="col-3 d-flex justify-content-center" v-for="network in networks">
                                        <ShareNetwork
                                            :network="network.network"
                                            :key="network.network"
                                            :style="{backgroundColor: network.color, border: '1px solid '+network.color}"
                                            class="network-icon"
                                            :url="sharing.url"
                                            :title="sharing.title"
                                            :description="sharing.description"
                                            :quote="sharing.quote"
                                            :hashtags="sharing.hashtags"
                                            :twitterUser="sharing.twitterUser"
                                        >
                                            <i :class="network.icon"></i>
                                            <span class="ms-2" v-if="!mobile">{{ network.name }}</span>
                                        </ShareNetwork>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        position: absolute;
        animation: bounce-in 0.3s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
</style>
<script setup>
    import { updateDoc, arrayUnion, doc } from "firebase/firestore";
</script>
<script>
export default {
    name: 'ProductAdd',
    props: ['user','language','categories'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            groups: [],
            showModal: false,
            modalImg: false,
            form: new Form({
                cat: '',
                subcat: '',
                title: '',
                description: '',
                price: '',
                looking_for: '',
                trade_type: 2,
                images: [],
                group_ids: [],
                post_to_market: true,
                post_to_group: false,
                latitude: '',
                longitude: '',
                loc_label: '',
                mobile: '',
            }),
            additional: new Form({}),
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            flag: false,
            previews: [],
            customFields: [],
            show_custom: false,
            form_filled: false,
            total_size: 0,
            disabled: false,
            select_all: false,
            errors: [],
            networks: [
                { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
            sharing: {
                url: '',
                title: '',
                description: '',
                hashtags: 'thrift,sell,trade,exchange',
            },
        };
    },
    async created() {
        if(this.token != null) await this.index()
        else {
            this.emitter.emit('showlogin', true)
        }

        if(!this.user.can_post) this.$router.push('/checkout?type=new-ads')

        var display_locality = JSON.parse(localStorage.getItem('display_locality'))
        this.form.latitude = display_locality.latitude
        this.form.longitude = display_locality.longitude
        this.form.loc_label = display_locality.location_locality

        this.emitter.on('removeModal', () => {
            this.showModal = false
        })
        this.emitter.on("update_user", async (data) => {
            if(this.$route.name == 'ProductAdd') {
                this.token = this.isLogged();
                if(!this.user.can_post) this.$router.push('/checkout?type=new-ads') ;
                await this.index()
                if(this.$route.params.id && this.groups.length) {
                    if(this.groups.findIndex(g => g.firebase_group_id == this.$route.params.id) > -1) {
                        this.form.post_to_group = true
                        this.form.group_ids.push(this.groups.find(item => item.firebase_group_id == this.$route.params.id).group_id)
                    }
                    else {
                        await this.addTogroup()
                        await this.index()
                    }

                }
                if(this.form_filled) this.submit_details()
            }
        });
    },
    computed: {
        comp_cats() {
            var data = []
            this.categories.forEach(cat => {
                var d = {
                    value: cat.id,
                    label: this.language[cat.text],
                }
                data.push(d)
            })
            return data
        },
        comp_subcats() {
            var data = []
            this.categories.forEach(cat => {
                if(cat.id == this.form.cat) {
                    cat.sub_categories.forEach(item => {
                        var d = {
                            value: item.id,
                            label: this.language[item.text],
                        }
                        data.push(d)
                    })
                }
            })
            return data
        },
        comp_groups() {
            var data = []
            this.groups.forEach(g => {
                var d = {
                    value: g.group_id,
                    label: g.name,
                }
                data.push(d)
            })
            return data
        },
        ttypes() {
            return [{ value: 1, label: this.language.trade }, { value: 2, label: this.language.sell }, { value: 3, label: this.language.free }, { value: 4, label: this.language.rent }, { value: 5, label: this.language.buy }, { value: 6, label: this.language.service }] ;
        },
        form_groups() {
            return this.form.group_ids ;
        },
    },
    watch: {
        form_groups(newVal,oldVal) {
            if(newVal.length < oldVal.length && this.select_all) this.select_all = false
        }
    },
    mounted() {
        this.sharing.url =  this.$domain + this.$route.fullPath
        this.sharing.title = `${this.$appname} - ${this.language.add_product}\n`
    },
    methods: {
        async index() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            await axios.get(this.$api + "groups/get-all",config).then((res) => {
                this.$Progress.finish()
                this.groups = res.data.groups
                if(this.$route.params.id && this.groups.length && this.groups.findIndex(g => g.firebase_group_id == this.$route.params.id) > -1) {
                    this.form.post_to_group = true
                    this.form.group_ids.push(this.groups.find(item => item.firebase_group_id == this.$route.params.id).group_id)
                }
            });
        },
        async addTogroup() {
            this.$Progress.start() ;

            await updateDoc(doc(db, 'groups', this.$route.params.id), {
                "members": arrayUnion(this.user.user.social_profile_id)
            })
            await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                "groups": arrayUnion(this.$route.params.id)
            })
            var fd = {
                firebase_group_id: this.$route.params.id,
                user_ids: [this.user.user.id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            axios.post(this.$api + 'groups/add-members',fd,config).then((res) => {
            }).catch((e) => {
                this.$Progress.fail() ;
            })
        },
        checkgroup() {
            if(this.comp_groups.length <= 0) {
                this.form.post_to_group = false ;
                toast.fire({
                    'icon': 'info' ,
                    'title': this.language.toast_you_are_not_part_of_community,
                })
            }
        },
        getAddressData(placeResultData) {
            const place = JSON.parse(JSON.stringify(placeResultData))
            this.form.latitude = place.geometry.location.lat
            this.form.longitude = place.geometry.location.lng
            this.form.loc_label = place.formatted_address
        },
        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
        },
        async locationiq(coords) {
            if(this.flag) {
                this.form.latitude = coords.latitude ;
                this.form.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get("https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" + this.form.latitude + "&lon=" + this.form.longitude + "&format=json").then((res) => {
                    this.form.loc_label = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
        submit_details() {
            if(!this.form.latitude || !this.form.longitude) {
                toast.fire({'icon' : 'info', 'title': this.language.toast_select_location_first });
                return ;
            }

            if(this.token == null) {
                this.emitter.emit('showlogin', true)
                this.form_filled = true
                return ;
            }

            this.form_filled = false

            this.disabled = true
            this.errors = []
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var fd = new FormData()
            fd.append('title',this.form.title)
            fd.append('description',this.form.description)
            fd.append('price',this.form.price)
            fd.append('looking_for',this.form.looking_for)
            fd.append('trade_type',this.form.trade_type)
            fd.append('slug',this.slug())
            fd.append('category_id',this.form.cat)
            fd.append('sub_category_id',this.form.subcat)
            fd.append('show_in_market',this.form.post_to_market ? 1 : 0)
            fd.append('latitude', this.form.latitude)
            fd.append('longitude', this.form.longitude)
            fd.append('loc_label', this.form.loc_label)
            fd.append('mobile', this.form.mobile)
            let keys = Object.keys(this.additional)
            keys.forEach(key => {
                if (key.substring(0, 6) === 'field_') {
                    fd.append(key, this.additional[key])
                }
            });
            for (let index = 0; index < this.form.images.length; index++) {
                fd.append('original_images[]',this.form.images[index])
            }
            if(this.form.group_ids != []) {
                for (let index = 0; index < this.form.group_ids.length; index++) {
                    if(typeof this.form.group_ids[index] != 'object')
                        fd.append('group_ids[]',this.form.group_ids[index])
                    else
                        fd.append('group_ids[]',this.form.group_ids[index].value)
                }
            }
            else{
                fd.append('group_ids[]')
            }

            this.$Progress.start();
            axios.post(this.$api + "products/add-product",fd,config).then((res) => {
                if (res.data.status) {
                    toast.fire({
                        icon: "success",
                        title: this.language.toast_product_add_success,
                    });
                    this.form.reset() ;
                    this.previews = []
                    this.$Progress.finish();
                    this.errors = [];
                    this.emitter.emit('get_user')
                    if(this.$route.params.id) this.$router.push('/group/'+this.$route.params.id) ;
                    else this.$router.push('/store') ;
                }
                else{
                    toast.fire({
                        icon: "error",
                        title: this.language.toast_error_occured,
                    });
                    this.$Progress.fail();
                }
                this.disabled = false
            })
            .catch((error) => {
                this.$Progress.fail();
                if (error.response.status === 422) this.errors = error.response.data.errors 
                toast.fire({
                    icon: "error",
                    title: this.language.toast_error_occured,
                });
                this.disabled = false
            });
        },
        uploadphoto(e) {
            var fi = e.target.files, left = 5;
            if(fi.length + this.previews.length > left) {
                toast.fire({
                    'icon' : 'info',
                    'title' : this.language.toast_max_files_allowed
                })
                left = left - this.previews.length
                // this.$refs.images.value = null
                // return false;
            }
            // Array.from(fi).slice(0,5).forEach(element => {
            //     this.total_size += element.size
            // });
            // this.total_size = 0
            // if(this.total_size > 2097152) {
            //     Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 4000 })
            //     .fire({
            //         'icon' : 'info',
            //         'title' : this.language.toast_files_too_big
            //     })
            //     this.$refs.images.value = null
            //     return false;
            // }
            Array.from(fi).slice(0,left).forEach(async element => {

                // console.log(`originalFile size ${element.size / 1024 / 1024} MB`);
                const options = {
                    maxSizeMB: 0.1,
                    maxWidthOrHeight: 1000,
                    useWebWorker: true,
                }
                try {
                    const compressed_File = await imageCompression(element, options);
                    // console.log(`compressed_File size ${compressed_File.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                    this.previews.push(URL.createObjectURL(compressed_File))
                    this.form.images.push(compressed_File)
                } catch (error) { console.log(error); }
            });
            this.$refs.images.value = null
        },
        showImageModal(img) {
            this.modalImg = img
            this.showModal = 'image'
        },
        removeimage(id) {
            this.previews.splice(id, 1)
            this.form.images.splice(id, 1)
        },
        validate_price: function (evt) {
            var key = evt.keyCode || evt.which;
            if(key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46)  return;
            key = String.fromCharCode(key);
            var regex = /[0-9]|\./;
            if (!regex.test(key)) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault();
            }

        },
        validate: function (evt) {

            return ;

            var key = evt.keyCode || evt.which;
            key = String.fromCharCode(key);

            var regex = /[\ra-z0-9A-Z ]|\./;
            if (!regex.test(key)) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault();
            }
        },
        reset_form() {
            this.form.reset() ;
            this.previews = []
            this.$router.push('/store')
        },
        select_all_groups() {
            if(this.select_all) {
                this.form.group_ids = [...this.comp_groups]
            }
            else {
                this.form.group_ids = []
            }
        },
        slug() {
            var currentTime = Math.floor(Date.now() / 1000); // Get current Unix timestamp
            var modifiedTitle = this.form.title.replace(/[^A-Za-z0-9]/g, '-').replace(/_+/g, '-').toLowerCase();
            var concatenatedText = modifiedTitle + '_' + currentTime;
            return concatenatedText;
        },
        subcat_change(data) {
            var fields = this.categories.find( i => i.id == this.form.cat)?.sub_categories?.find( i => i.id == this.form.subcat)?.custom_fields || []
            if(fields.length) {
                this.customFields = fields.sort(function (a, b) {
                    return a.type.localeCompare(b.type);
                });
                this.show_custom = true
            }
        },
        reset_subcat() {
            this.form.subcat = ''
        }
    },
}
</script>
