<template>
   <div ref="element">
        <div class="product-img-container" @click="$router.push('/event/'+event.slug)">
            <img :src="event.thumbnail_image ? $s3_url+event.thumbnail_image : ''" :alt="event.name">
        </div>
        <div class="overlay">
            <div class="product-content">
                <span ref="title" :data-toggle="isEllipsisActive ? 'tooltip' : ''" :title="isEllipsisActive ? event.name : ''" @click="$router.push('/event/'+event.slug+type)" class="product-title">{{ event.name }}</span>
                <div class="like-container">
                    <span class="distance ms-auto">{{ event.user_distance }} {{  user?.user?.distance_unit == 'mi' ? 'mi' : (event.user_distance <= 1 ? 'km' : 'kms') }} {{ lang.away }}</span>
                    <!-- <span class="distance ms-auto"><i class="fa fa-map-marker-alt me-1"></i>{{ event.location_name }} </span> -->
                </div>
                <div class="product-description" v-if="display_type == 'list'">{{ event.description}}</div>

                <div class="button-bar" :class="can_del ? 'justify-content-between' : 'justify-content-end'" v-if="is_group_admin || can_del">
                    <button type="button" class="main-button small light" v-if="can_del" @click="editEvent(event.slug)">
                        <i class="fas fa-edit" :class="{'pe-1': !mobile}"></i><span v-if="!mobile">{{ lang.edit }}</span>
                    </button>
                    <button type="button" class="main-button small light" @click="deleteEvent(event.crypt_id)">
                        <i class="fas fa-trash" :class="{'pe-1': !mobile}"></i><span v-if="!mobile">{{ lang.delete }}</span>
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['user','event', 'group', 'can_del', 'type', 'display_type', 'is_group_admin', 'lang'],
    name: 'Event',
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("greta")}`,
                },
            },
            isEllipsisActive: false,
            element: null,
        };
    },
    created() {
    },
    beforeUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    computed: {
        f_name() {
            return this.event.user_details.first_name.indexOf(' ') != -1 ? this.event.user_details.first_name.substring(0, this.event.user_details.first_name.indexOf(' ')) : this.event.user_details.first_name ;
        }
    },
    mounted() {
        if(this.$refs.element.classList.contains('last_item')) {
            this.element = this.$refs.element
            this.checkEvent()
        }
        this.checkEllipsisOverflow();
        this.$nextTick(() => {
            if(!this.mobile)
            $('[data-toggle="tooltip"]').tooltip()
        })
        window.addEventListener('resize', this.checkEllipsisOverflow);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkEllipsisOverflow);
        $('[data-toggle="tooltip"]').tooltip('dispose')
    },
    methods: {
        checkEllipsisOverflow() {
            const titleElement = this.$refs.title;
            const containerWidth = titleElement.clientWidth;

            const clone = titleElement.cloneNode(true);
            clone.style.visibility = 'hidden';
            clone.style.position = 'absolute';
            clone.style.width = 'auto';
            clone.style.maxWidth = 'none';
            document.body.appendChild(clone);

            this.isEllipsisActive = clone.offsetWidth > containerWidth;
            document.body.removeChild(clone);
        },
        deleteEvent(id) {
            console.log(this.type);
            if (this.type == 'group_events') {
                this.deleteEventFromGroup(id, this.group.firebase_group_id)
            } else {
                this.deleteEventForUser(id)
            }
        },
        deleteEventFromGroup(id) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var thiss = this
            Swal.fire({
                title: this.lang.toast_are_you_sure,
                text: this.lang.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.lang.toast_yes_delete,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    thiss.$Progress.start()
                    console.log(thiss.group);
                    let payload = {
                        eid : id,
                        gid : thiss.group.firebase_group_id
                    }
                    axios.post(thiss.$api+'groups/remove-event', payload, config).then((response) => {
                        if(response.data.status){
                            thiss.$Progress.finish()
                            thiss.emitter.emit('remove_event')
                            // Swal.fire({
                            //     icon: 'success',
                            //     title: thiss.lang.deleted,
                            //     text: thiss.lang.toast_product_deleted_success,
                            //     customClass: {
                            //     confirmButton: 'btn btn-success'
                            //     }
                            // });
                            toast.fire({
                                icon: "success",
                                title: 'Event deleted successfully',
                            });
                            thiss.$emit('deleted_event')
                        }
                    }).catch((e) => {
                        thiss.$Progress.fail()
                        toast.fire({
                            icon: "error",
                            title: thiss.lang.server_error,
                        });
                    });
                }
            });

        },
        deleteEventForUser(id) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var thiss = this
            Swal.fire({
                title: this.lang.toast_are_you_sure,
                text: this.lang.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.lang.toast_yes_delete,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    thiss.$Progress.start()
                    axios.get(thiss.$api+'events/delete/'+id,config).then((response) => {
                        if(response.data.status){
                            thiss.$Progress.finish()
                            thiss.emitter.emit('remove_event', thiss.index)
                            // Swal.fire({
                            //     icon: 'success',
                            //     title: thiss.lang.deleted,
                            //     text: thiss.lang.toast_product_deleted_success,
                            //     customClass: {
                            //     confirmButton: 'btn btn-success'
                            //     }
                            // });
                            toast.fire({
                                icon: "success",
                                title: thiss.lang.toast_event_deleted_success,
                            });
                            thiss.emitter.emit('get_user')
                        }
                    }).catch((e) => {
                        thiss.$Progress.fail()
                        toast.fire({
                            icon: "error",
                            title: thiss.lang.server_error,
                        });
                    });
                }
            });

        },
        checkEvent() {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.emitter.emit('view_more')
                        // observer.unobserve(this.element);
                    }
                });
            });
            observer.observe(this.element);
        },

        editEvent(slug) {
            this.$router.push('/events/update/' + slug)
        },

    },
}
</script>
