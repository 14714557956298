<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card min-sc" id="products_card">
                <div class="card-header flex-sc">
                    <button type="button" class="main-button small me-1" @click="$router.go(-1)"><i class="fas fa-arrow-left fa-lg"></i></button>
                    <transition-group tag="div" class="header-container pe-4" name="fade">
                        <h3 key="name" v-if="group?.name">{{ group?.name }}</h3>
                        <i key="icon" v-if="group?.name" class="fas fa-chevron-right fa-lg mx-1"></i>
                        <h3 key="ads" v-if="group?.name">{{ language.events }}</h3>
                    </transition-group>
                    <router-link to="/user/events" data-toggle="tooltip" class="main-button small dark">
                        {{ language.add_event }}
                    </router-link>
                </div>
                <div class="card-body">
                    <transition-group tag="div" class="pcont" name="bounce">
                        <Event :lang="language" class="product-block-card" display_type="card" :can_del="event.user_id == user.user.id" :user="user" :event="event" type="group_events" :group="group" :is_group_admin="is_admin" @deleted_event="deletedEvent()"  v-for="(event, index) in events" :key="index" />
                    </transition-group>
                </div>
                <NotFound :lang="language" :type="text" :load="loading" v-if="loading || events.length == 0"></NotFound>
            </div>
        </div>
    </div>
</template>
<style scoped>
.flip-enter-active {
    transition: all 0.5s ease;
}
.flip-leave-active {
    display: none;
}
.flip-enter-from, .flip-leave-to {
    transform: rotateY(180deg);
    opacity: 0;
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.2s reverse;
}

.fade-enter-active {
    transition: all 0.3s ease-in;
}

.fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    position: absolute;
    opacity: 0;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    position: absolute;
    transform: translateX(-20%);
    opacity: 0;
}
</style>
<script setup>
import { collection, orderBy, onSnapshot, getDocs, query, where, addDoc, setDoc, updateDoc, deleteDoc, getDoc, arrayUnion, arrayRemove, doc } from "firebase/firestore";
</script>
<script>
export default {
    name: 'GroupEvents',
    props: ['user', 'language', 'categories'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            id: this.$route.params.id,
            events: [],
            group: [],
            members: [],
            buttonLoading: false,
            products: [],
            pids: [],
            c_page:1,
            l_count:0,
            searched: false,
            search_string: '',
            errors: [],
            last_item: this.isMobile() ? 4 : 1,
            showInput: false,
            navdrop: false,
            memberdrop: false,
            liked: [],
            requests: [],
            chat: [],
            search: '',
            screen: 'main',
            infoscreen: false,
            a: false,
            productscreen: this.isMobile() ? true : false,
            showModal: false,
            searched_user: [],
            group_invites: [],
            active_chats: [],
            name: '',
            description: '',
            preview: '',
            icon: '',
            latitude: '',
            longitude: '',
            loc_label: '',
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            directMessage: {
                name: '',
                social_profile_id: '',
                fromCryptid: '',
                message: ''
            },
            is_private: false,
            loading: true,
            text: this.language.loading,
            cloading: true,
            ctext: this.language.loading,
            msg_to_send: '',
            networks: [
                { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
                { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            ],
            sharing: {
                url: '',
                title: '',
                description: '',
                hashtags: 'thrift,sell,trade,exchange',
            },
            filter_data: new Form({
                sort_by: 'newest',
                type: [],
                distance: 0,
                price: [0, ''],
                date_posted: 0,
                cat: [],
                subcat: [],
            }),
            filter_applied: false,
            max_price: '',
            is_admin: false,
        };
    },
    created() {
        if (this.token != null) this.index()
        this.emitter.on('removeModal', () => {
            this.showModal = false
        })
        this.emitter.on("view_more", () => {
            if(this.$route.name == 'Group') {
                this.view_more()
            }
        });
        this.emitter.on("remove_product", (id) => {
            if(this.$route.name == 'Group') {
                this.products.splice(id, 1)
            }
        });
    },
    watch: {
        search(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.search_user()
            }
        },
        search_string(newVal, oldVal) {
            if (oldVal && !newVal && this.searched) {
                this.searched = false
                this.c_page = 1
                this.products = []
                this.get_products()
            }
        },
    },
    mounted() {
        this.debouncedSearch = this.$debounce(this.search_page, 500);

        this.emitter.on('get-np', (e) => {
            if(e.type == 'group') {
                var index = this.pids.indexOf(e.id)
                if((index+1) == this.pids.length - 3) this.view_more()
                var obj = { n: this.pids[index+1], p: this.pids[index-1]}
                this.$nextTick(() => {
                    this.emitter.emit('set-np', obj)
                })
            }
        })
    },
    updated() {
        this.tooltip_fun()
        $('.chat_container').animate({ scrollTop: $('.chat_container').prop("scrollHeight") }, 500);
    },
    beforeUnmount() {
        this.emitter.emit('product_add', '/p/add/')
    },
    methods: {
        async index() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()

            await axios.post(this.$api + "groups/get-events/" + this.id, {}, config).then((res) => {
                this.group = res.data.group
                this.events = res.data.events
                this.is_admin = res.data.is_admin
                this.name = this.group.name
                this.description = this.group.description
                this.latitude = this.group.latitude
                this.longitude = this.group.longitude
                this.loc_label = this.group.loc_label
                this.preview = this.group.icon_blob ? this.$s3_url+this.group.icon_blob : ''
                this.sharing.url =  this.$domain + '/group/invite/' + this.group.invite_code
                this.sharing.title = `${this.name} Ads\n`
                this.loading = false
                this.text = this.language.no_events_to_display
            }).catch((err) => { console.log(err); this.$Progress.fail() });

            this.$Progress.finish()
        },

        deletedEvent() {
            this.index()
        },

        view_more() {
            if(this.l_count > 0) {
                this.c_page++
                this.get_products()
            }
        },
        changeShowInput() {
            this.showInput = !this.showInput
        },
        search_page() {
            if(this.search_string.length > 1){
                this.$refs.search_field.focus()
                this.searched = true
                this.c_page = 1
                this.products = []
                this.get_products()
            }
        },
        async delete_group() {
            var thiss = this
            Swal.fire({
                title: this.language.toast_are_you_sure,
                text: this.language.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.language.toast_yes_delete,
                cancelButtonText: this.language.cancel,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(async function (result) {
                if (result.value) {
                    const q = query(collection(db, "groups"), where("groupId", "==", thiss.data.groupId));
                    const querySnapshot = await getDocs(q);
                    var curr_grp;
                    querySnapshot.forEach((doc) => {
                        curr_grp = doc.data();
                    });
                    curr_grp.members.forEach((ele) => {
                        updateDoc(doc(db, 'users', ele), {
                            "groups": arrayRemove(thiss.data.groupId)
                        })
                    })
                    deleteDoc(doc(db, 'groups', thiss.data.groupId))
                    var config = { headers: { Authorization: `Bearer ${thiss.token}` } }
                    thiss.$Progress.start()
                    axios.get(thiss.$api + 'groups/delete/' + thiss.data.groupId, config).then((res) => {
                        thiss.$Progress.finish()
                        Swal.fire({
                            icon: 'success',
                            title: thiss.language.toast_deleted,
                            text: thiss.language.toast_group_deleted,
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        thiss.$router.push('/groups')
                    }).catch((err) => {
                        thiss.$Progress.fail();
                        toast.fire({
                            'icon': 'error',
                            'title': thiss.language.server_error,
                        });
                    })
                }
            });
        },
        search_user() {
            if (this.search.length > 0) {
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                axios.post(this.$api + "groups/invite-search", { 'string': this.search, 'firebase_group_id': this.data.groupId }, config).then((res) => {
                    this.$Progress.finish()
                    this.searched_user = res.data.results
                    this.group_invites = res.data.group_invites
                }).catch((e) => {
                    this.$Progress.fail()
                });
            }
        },
        async invite_user(su) {
            if (this.group_invites.includes(su.id)) {
                toast.fire({
                    'icon': 'info',
                    'title': this.language.toast_user_already_invited,
                });
                return ;
            }
            this.buttonLoading = 'invite'
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()
            await axios.post(this.$api + "groups/invite", { 'email': su.email, 'firebase_group_id': this.data.groupId }, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_user_invited,
                });
                this.group_invites.push(su.id)
                if(this.searched_user.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
                toast.fire({
                    'icon': 'error',
                    'title': this.language.server_error,
                });
            });
            this.buttonLoading = false
        },
        getAddressData(placeResultData) {
            const place = JSON.parse(JSON.stringify(placeResultData))
            this.latitude = place.geometry.location.lat
            this.longitude = place.geometry.location.lng
            this.loc_label = place.formatted_address
        },
        current_location() {
            this.$Progress.start();
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            this.flag = true
                            this.$nextTick(() => {
                                this.locationiq(position.coords)
                                this.$Progress.finish();
                            })
                        });
                    }
                } else {
                    Swal.mixin({ toast: true, position: 'top-start', showConfirmButton: false, timer: 4000 }).fire({
                        icon: "warning",
                        title: this.language.please_allow_location_access,
                    });
                    this.$Progress.fail();
                }
            });
        },
        async locationiq(coords) {
            if(this.flag) {
                this.latitude = coords.latitude ;
                this.longitude = coords.longitude ;
                delete axios.defaults.headers.common["X-Requested-With"];
                await axios.get("https://us1.locationiq.com/v1/reverse.php?key=pk.ccd52f9f8b4fee4e363dc8cad72e1c8e&lat=" + this.latitude + "&lon=" + this.longitude + "&format=json").then((res) => {
                    this.loc_label = res.data.address.city || res.data.address.county || res.data.address.suburb || res.data.address.state;
                });
                axios.defaults.headers.common["X-Requested-With"];
            }
        },
        async update_group() {
            this.buttonLoading = 'update'

            if(!(this.latitude && this.longitude)) {
                toast.fire({'icon' : 'info', 'title': this.language.toast_select_location_first });
                this.buttonLoading = false
                return ;
            }

            const colRef = collection(db, 'groups')
            const nameDocs = await getDocs(query(colRef, where("groupName", "==", this.name), where('admin', '==', this.user.user.social_profile_id)))
            if(this.name != this.data.groupName && !nameDocs.empty) {
                this.errors.name = [this.language.groupname_taken];
                this.buttonLoading = false
                return ;
            }
            this.errors = []

            this.$Progress.start()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            var fd = new FormData()
            fd.append('name', this.name)
            fd.append('description', this.description)
            fd.append('icon', this.icon)
            fd.append('latitude', this.latitude)
            fd.append('longitude', this.longitude)
            fd.append('loc_label', this.loc_label)
            fd.append('is_private', this.is_private ? 1 : 0)
            fd.append('firebase_group_id', this.data.groupId)
            await axios.post(this.$api + 'groups/update', fd, config).then(async (res) => {
                await updateDoc(doc(db, 'groups', this.data.groupId), {
                    "groupName": this.name,
                    "description": this.description,
                    "is_private": this.is_private,
                    "icon": res.data.path,
                })
                this.$Progress.finish()
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_group_updated_success,
                });
                this.index()
                this.showModal = false
            }).catch((e) => {
                this.$Progress.fail()
                toast.fire({
                    'icon': 'error',
                    'title': this.language.server_error,
                });
            });
            this.buttonLoading = false
        },
        async leave_group(uid, spid, type) {
            var id = this.data.groupId
            var thiss = this
            var str = (type == 'leave') ? this.language.i_am_leaving : this.language.remove
            var str2 = (type == 'leave') ? this.language.toast_group_left_success : this.language.toast_member_removed_success
            Swal.fire({
                title: this.language.toast_are_you_sure,
                text: this.language.toast_wont_able_to_revert,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: str,
                cancelButtonText: this.language.cancel,
                customClass: {
                    confirmButton: 'main-button',
                    cancelButton: 'main-button bg-danger ms-3'
                },
                buttonsStyling: false
            }).then(async function (result) {
                if (result.value) {
                    thiss.buttonLoading = 'leave'
                    await updateDoc(doc(db, 'groups', id), {
                        "members": arrayRemove(spid)
                    })
                    await updateDoc(doc(db, 'users', spid), {
                        "groups": arrayRemove(id)
                    })
                    var fd = {
                        firebase_group_id: id,
                        user_ids: [uid],
                    }
                    var config = { headers: { Authorization: `Bearer ${thiss.token}` } }
                    thiss.$Progress.start()
                    await axios.post(thiss.$api + 'groups/remove-members', fd, config).then((res) => {
                        thiss.$Progress.finish()
                        Swal.fire({
                            icon: 'success',
                            title: thiss.language.toast_leaved,
                            text: str2,
                            customClass: { confirmButton: 'btn btn-success' }
                        });
                        if (type == 'leave') thiss.$router.push('/groups')
                        else thiss.index()
                    }).catch((err) => {
                        thiss.$Progress.fail();
                        toast.fire({
                            'icon': 'error',
                            'title': thiss.language.server_error,
                        });
                    })
                    thiss.buttonLoading = false
                }
            });
        },
        async approve(id, uid) {
            this.buttonLoading = 'approve_request'
            await updateDoc(doc(db, 'groups', this.data.groupId), {
                "members": arrayUnion(uid)
            })
            await updateDoc(doc(db, 'users', uid), {
                "groups": arrayUnion(this.data.groupId)
            })
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.groupId,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/approve-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_accepted,
                })
                this.index()
                if(this.requests.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },
        async reject(id) {
            this.buttonLoading = 'reject_request'
            this.$Progress.start();
            var fd = {
                firebase_group_id: this.data.groupId,
                user_ids: [id],
            }
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.post(this.$api + 'groups/delete-requests', fd, config).then((res) => {
                this.$Progress.finish();
                toast.fire({
                    'icon': 'success',
                    'title': this.language.toast_invite_rejected,
                })
                this.index()
                if(this.requests.length < 2)
                    this.showModal = false
            }).catch((e) => {
                this.$Progress.fail();
            })
            this.buttonLoading = false
        },
        send_message() {
            if (this.msg_to_send) {
                $('#input_send').removeClass('form-control border border-danger is-invalid');
                const docRef = doc(db, "groups", this.id);
                const colRef = collection(docRef, 'messages')

                var str = Date.now().toString();
                setDoc(doc(colRef, str), {
                    "idFrom": this.user.user.social_profile_id,
                    "content": this.msg_to_send,
                    "timestamp": Date.now().toString(),
                    "sender": this.user.user.first_name,
                    "fromCryptid": this.user.user.crypt_id,
                    "type": 0
                })
                updateDoc(doc(db, 'groups', this.id), {
                    "recentMessage": this.msg_to_send,
                    "recentMessageSender": this.user.user.first_name,
                    "recentMessageTime": Date.now().toString(),
                })
                this.msg_to_send = ''
                $('.chat_container').animate({ scrollTop: $('.chat_container').prop("scrollHeight") }, 500);
            }
            else {
                $('#input_send').addClass('form-control border border-danger is-invalid me-2');
            }
        },
        change_productscreen(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.productscreen = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        },
        change_infoscreen(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.infoscreen = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        },
        change_showModal(id) {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.showModal = id
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
            if(id == 'search')
                this.$nextTick(() => this.$refs.inputSearch.focus())
        },
        scroll_to_products() {
            $('#products_card')[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        async directMessageSend() {
            if (this.directMessage.message == '') return

            this.buttonLoading = 'send_message'
            var chk = query(doc(db, "messages", this.directMessage.social_profile_id.id + "-" + this.user.user.social_profile_id));
            var docSnap = await getDoc(chk);
            var docid = ''
            if (docSnap.exists()) {
                docid = this.directMessage.social_profile_id + "-" + this.user.user.social_profile_id;
            } else {
                docid = this.user.user.social_profile_id + "-" + this.directMessage.social_profile_id
            }

            const docRef = doc(db, "messages", docid);
            const colRef = collection(docRef, docid)
            var msg = this.directMessage.message
            var str = Date.now().toString();
            setDoc(doc(colRef, str), {
                "idFrom": this.user.user.social_profile_id,
                "idTo": this.directMessage.social_profile_id,
                "content": this.directMessage.message,
                "imageBlob": null,
                "timestamp": str,
                "type": 0,
                "seen": 0
            })
            setDoc(docRef, {
                'recentMessage': this.directMessage.message,
                "timestamp": str,
                'isDisabled': false,
                'blocked_by': '',
            })
            var chat_data = {
                user: this.directMessage.social_profile_id,
                user_crypt: this.directMessage.fromCryptid,
                product_id: 0,
                chat_id: docid,
            }

            await updateDoc(doc(db, 'users', this.user.user.social_profile_id), {
                "chats": arrayUnion(chat_data)
            })

            var rev_chat_data = {
                user: this.user.user.social_profile_id,
                user_crypt: this.user.user.crypt_id,
                product_id: 0,
                chat_id: docid,
            }

            await updateDoc(doc(db, 'users', this.directMessage.social_profile_id), {
                "chats": arrayUnion(rev_chat_data)
            })
            this.buttonLoading = false
            this.$Progress.finish()
            toast.fire({
                'icon': 'success',
                'title': this.language.toast_message_sent,
            })
            this.$router.push('/chats')
        },
        reply_private(s, i, f) {
            this.directMessage.name = s;
            this.directMessage.social_profile_id = i;
            this.directMessage.fromCryptid = f;
            this.showModal = 'messageDirect';
        },
        async resetLink() {
            this.$Progress.start()
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + "groups/reset-invite-code/" + this.id, config).then((res) => {
                this.group = res.data.group
                toast.fire({
                    'icon': 'success',
                    'title': this.language.link_reset,
                })

            }).catch((err) => { console.log(err); this.$Progress.fail() });
            this.$Progress.finish()

        },
        closeDropdown() {
            this.navdrop = false
        },
        closeMember() {
            this.memberdrop = false
        },
        filterShow() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.$nextTick(() => this.showModal = 'filters')
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
        },
        apply_filter() {
            this.showModal = false;
            this.c_page = 1;
            this.filter_applied = true
            this.products = []
            this.get_products();
        },
        reset_filter() {
            this.showModal = false;
            this.filter_data.reset()
            this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000
            this.filter_data.price[0] = 0
            this.filter_data.price[1] = ''
            this.c_page = 1;
            this.filter_applied = false;
            this.products = []
            this.get_products();
        },
        price(val) {
            var formatter = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                maximumFractionDigits: 0,
            });
            return formatter.format(val);
        },
        async make_admin(id, uspid, type) {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            this.$Progress.start()

            if(type == 'add') {
                this.buttonLoading = 'make_admin'
                await updateDoc(doc(db, "groups",this.id), { "admins": arrayUnion(uspid) })
            }
            else {
                this.buttonLoading = 'remove_admin'
                await updateDoc(doc(db, "groups",this.id), { "admins": arrayRemove(uspid) })
            }

            await axios.post(this.$api + "groups/make-admin", { 'id': id, 'type': type }, config).then((res) => {
                this.$Progress.finish();
                if(type == 'add')
                toast.fire({ 'icon': 'success', 'title': this.language.toast_make_admin });
                else if(type == 'remove')
                toast.fire({ 'icon': 'success', 'title': this.language.toast_remove_admin });

                var index = this.members.members.findIndex(i => i.id == id)
                this.members.members[index].role = (type == 'add' ? 1 : 2)

            }).catch((e) => {
                this.$Progress.fail();
                toast.fire({'icon': 'error', 'title': this.language.server_error });
            });
            this.buttonLoading = false
        },
        async uploadphoto(e) {
            var fi = e.target.files[0];
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 200,
                useWebWorker: true,
            }
            const compressed_File = await imageCompression(fi, options);
            this.preview = URL.createObjectURL(compressed_File)
            this.icon = compressed_File
            this.$refs.images.value = null
        },
        tooltip_fun() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
            this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable'))
        }
    },
}
</script>
