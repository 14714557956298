<template>
    <div class="row">
        <div class="col-12">
            <div class="features-card position-relative">
                <div class="card-header flex-bc" ref="header">
                    <h3 v-if="!mobile">{{ language.events }}</h3>
                    <h3 v-else data-toggle="tooltip" title="Events"><i class="fas fa-store"></i></h3>
                    <transition-group name="fade">
                        <div key="search" class="position-relative ms-auto me-1 me-lg-2" :class="mobile ? 'w-40' : 'w-25'">
                            <input type="search" ref="search_field" v-model="search" @keyup.enter="search_page" class="form-control" :placeholder="language.type_min_3" required>
                            <button type="button" @click="search_page" class="main-button light small search_button"><i class="fas fa-15x fa-search"></i></button>
                        </div>
                        <button key="filter_data" aria-label="filter_data" data-toggle="tooltip" :title="language.heading_sort_filter" type="button" @click="filterShow" :class="{'light' : !filter_applied}" class="main-button small search_button me-1 me-lg-2"><i class="fas fa-15x fa-filter"></i></button>
                        <button key="display_switch" aria-label="display_switch" data-toggle="tooltip" :title="language.display_list_view" type="button" @click="change_display_type()" class="main-button light small search_button me-1 me-lg-2"><i class="fas fa-15x" :class="display_type == 1 ? 'fa-list' : (display_type == 2 && map_view ? 'fa-map' : 'fa-th')"></i></button>
                        <button key="change" aria-label="change" type="button" v-if="display_locality" @click="emitter.emit('location_change', false)" class="main-button light small me-1 me-lg-2">
                            <i :class="is_default_location ? 'fa-map-marker-alt' : 'fa-map-marker-alt', mobile  ? '' : 'fa-15x'" class="fa pe-1 pe-lg-2 text-body"></i>
                            <span class="location">{{display_locality.name}}</span>
                        </button>
                        <a v-if="language && !mobile" type="button" class="main-button small" data-toggle="tooltip" @click="addEvent" :title="language.add_event">
                            {{ language.add_event }}
                        </a>
                    </transition-group>
                </div>
                <div class="card-body">
                    <transition appear name="slide-left" mode="out-in">
                        <transition-group appear tag="div" class="pcont match-height" v-if="display_type == 1" name="bounce">
                            <Event :lang="language" class="product-block-card" display_type="card" :can_del="false" :user="user" :event="event" :type="'?type=events_marketplace'" :group="null" :is_group_admin="false" @deleted_event="deletedEvent()"  v-for="(event,index) in events" :key="event.id" />
                        </transition-group>
                        <transition-group tag="div" v-else-if="display_type == 2" name="bounce">
                            <Event :lang="language" class="product-block-list" display_type="list" :can_del="false" :user="user" :event="event" :type="'?type=events_marketplace'" :group="null" :is_group_admin="false" @deleted_event="deletedEvent()"  v-for="(event,index) in events" :key="event.id" />
                        </transition-group>
                        <transition appear v-else-if="display_type == 3" tag="div" name="bounce">
                            <NotFound :lang="language" :type="text" :load="all_loading" v-if="all_loading || all_events.length == 0"></NotFound>
                            <div class="row" v-else>
                                <GMapMap
                                    class="map-display"
                                    ref="googleMap"
                                    :center="center"
                                    :zoom="11"
                                    :options="{
                                        mapId: '1e4e75cecfdada8b',
                                        zoomControl: true,
                                        mapTypeControl: true,
                                        scaleControl: true,
                                        // mapTypeControlOptions:{position:google.maps.ControlPosition.BOTTOM_RIGHT},
                                        streetViewControl: false,
                                        draggable: true,
                                        rotateControl: true,
                                        fullscreenControl: true,
                                    }"
                                    :dragend="true"
                                    @drag="handleMarker()"
                                    @zoom_changed="handleMarker()"
                                    @maptypeid_changed="handleMarker()"
                                    map-type-id="terrain"
                                    >
                                    <GMapMarker
                                        :key="index"
                                        v-for="(m, index) in all_events"
                                        :position="{lat: parseFloat(m.latitude), lng: parseFloat(m.longitude)}"
                                        :clickable="true"
                                        @click="openedMarkerID = m.id"
                                        :icon="{
                                            url :'https://static-00.iconduck.com/assets.00/map-marker-icon-342x512-gd1hf1rz.png',
                                            scaledSize: {width: 26, height: 37},
                                        }"
                                        :draggable="false"
                                    >
                                    <!-- :icon="'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png'" -->
                                    <GMapInfoWindow :closeclick="true" @closeclick="openedMarkerID = null" :options="{maxWidth: 300, maxHeight: 300 }" :opened="openedMarkerID === m.id">
                                        <Event :lang="language" class="product-block-card" display_type="card" :can_del="false" :user="user" :event="m" :type="'?type=events_marketplace'" :group="null" :is_group_admin="false" @deleted_event="deletedEvent()" />
                                    </GMapInfoWindow>
                                    </GMapMarker>
                                </GMapMap>
                            </div>
                        </transition>
                    </transition>
                </div>
                <transition name="fade">
                    <NotFound :lang="language" :type="text" :load="loading" v-if="(loading || events.length == 0) && display_type!=3"></NotFound>
                </transition>
            </div>
        </div>
        <transition-group name="fade">
            <div class="modal fade" v-if="showModal == 'filters'" :class="{ 'd-block show backdrop': showModal }" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content bg-transparent border-0">
                        <form @submit.prevent="apply_filter" class="features-card rounded-4">
                            <div class="card-header flex-bc">
                                <h3>{{ language.heading_sort_filter }}</h3>
                                <a href="javascript:;" @click="showModal = false;" class="main-button small light">
                                    <i class="fas fa-times fa-2x"></i>
                                </a>
                            </div>
                            <div class="modal-body border-bottom">
                                <div class="row match-height">
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.sort_by }}</h5>
                                            <ul class="ps-3 mb-4">
                                                <!-- <label class="radio-container small">Default
                                                    <input type="radio" v-model="filter_data.sort_by" value="default">
                                                    <span class="checkmark"></span>
                                                </label> -->
                                                <label class="radio-container small">{{ language.text_newest }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="newest">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_distance }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="distance">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_az }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-az">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="radio-container small">{{ language.text_title_za }}
                                                    <input type="radio" v-model="filter_data.sort_by" value="title-za">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </ul>
                                        </span>
                                    </div>
                                    <div class="col-lg-4">

                                    </div>
                                    <div class="col-lg-4">
                                        <span>
                                            <h5>{{ language.filter_by_date }}</h5>
                                            <div class="col-12">
                                                <v-select :clearable="false" :reduce="(option) => option.value" class="v-form-control" v-model="filter_data.date_posted" :options="filter_dates" />
                                            </div>
                                        </span>

                                        <span class="mt-auto">
                                            <h5>{{ language.filter_by_distance }}</h5>
                                            <Slider tooltipPosition="bottom" :step="1" class="slider-blue mx-3 mx-md-0 mb-5 mb-md-0" :min="1" :max="3000" :format="f_distance" v-model="filter_data.distance" />
                                        </span>
                                        <!-- <span class="slider-container mt-auto">
                                            <div class="col-12 d-flex text-nowrap align-items-center justify-content-between">
                                                <input type="range" v-model="filter_data.date_posted" step="1" class="form-control filter-slider pe-4" :min="7" :max="100">
                                                <h5 class="mb-0">{{ filter_data.date_posted+' days ago' }}</h5>
                                            </div>
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer pt-3 text-center">
                                <button aria-label="{{language.reset}}" type="button" class="main-button light me-2" @click="reset_filter">{{language.reset}}</button>
                                <button aria-label="{{language.apply}}" type="submit" class="main-button ms-2">{{ language.apply }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</template>
<style scoped>
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.03);
            margin-bottom: 60px;
        }
        100% {
            transform: scale(1);
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .scale-up-enter-active, .scale-up-leave-active {
        transition: all 0.3s ease-in;
    }

    .scale-up-enter-from, .scale-up-leave-to {
        transform: scaleY(0);
        opacity: 0.5;
    }
    .slide-left-enter-active, .slide-left-leave-active {
        transition: all 0.3s ease-in;
    }

    .slide-left-enter-from, .slide-left-leave-to {
        transform: scaleX(-30px);
        opacity: 0.5;
        filter: blur(5px);
    }
</style>
<script>
    export default {
        name: 'EventsMarketplace',
        props: ['user','language','categories','default_display_type', 'map_view'],
        data() {
            return {
                token: this.isLogged(),
                mobile: this.isMobile(),
                events: [],
                all_events: [],
                api: this.$api,
                c_page:1,
                l_count:0,
                center: null,
                gMap: null,
                openedMarkerID: null,
                display_type: 1,
                searched: false,
                display_locality: null,
                is_default_location: false,
                loading: true,
                all_loading: true,
                text: this.language.loading,
                showInput: false,
                search: '',
                pids: [],
                filter_data: new Form({
                    // sort_by: 'default',
                    sort_by: 'newest',
                    type: [],
                    distance: 0,
                    price: [0, ''],
                    date_posted: 0,
                    cat: [],
                    subcat: [],
                }),
                max_price: '',
                filter_applied: false,
                showModal: false,
                showBanners: false,
                buttonOverlapsFooter: false,
                isScrollable: false,
            };
        },
        computed: {
            filter_dates() {
                return [{ value:7, label: this.language.last_7_days }, { value:15, label: this.language.last_15_days }, { value:30, label: this.language.last_30_days}, { value:0, label: this.language.all_time }]
            },
        },
        async created() {
            var check = localStorage.getItem('banners_enabled')
            if(check == null || !check) this.showBanners = true

            this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000

            this.display_locality = JSON.parse(localStorage.getItem('display_locality'))
            this.center = {
                lat: this.display_locality.latitude, lng: this.display_locality.longitude,
            }

            this.display_type = this.default_display_type
            if(!this.map_view && this.default_display_type == 3) this.display_type = 1

            if(this.display_type != 3) await this.index()
            else {
                this.all_events = []
                this.getAll()
            }

            this.emitter.on("loggedIn", async (token) => {
                if(this.$route.name == 'Marketplace') {
                    this.token = token
                    this.events = []
                    if(this.display_type != 3) {
                        this.c_page = 1
                        await this.index()
                    }
                    else {
                        this.all_events = []
                        this.getAll()
                    }                }
            });

            this.emitter.on("location_updated", () => {
                // if(this.$route.name == 'Marketplace') {
                    this.display_locality = JSON.parse(localStorage.getItem('display_locality'))
                    this.center = {
                        lat: this.display_locality.latitude, lng: this.display_locality.longitude,
                    }

                    // if(this.mobile) {
                    //     var container = $('.card-header')[0];
                    //     if (container.scrollWidth <= container.clientWidth) this.isScrollable = true
                    //     else this.isScrollable = false
                    // }
                    this.c_page = 1
                    if(this.display_type == 3) {
                        this.all_events = []
                        this.getAll();
                    }
                    else {
                        this.events = []
                        this.index();
                    }
                // }
            });

            this.emitter.on("loggedOut", async () => {
                this.token = null
                this.showInput = false
                this.events = []
                if(this.display_type != 3) {
                    this.c_page = 1
                    await this.index()
                }
                else {
                    this.all_events = []
                    this.getAll()
                }
            });
            this.emitter.on("view_more", () => {
                if(this.$route.name == 'EventsMarketplace') {
                    this.view_more()
                }
            });
        },
        mounted() {

            this.debouncedSearch = this.$debounce(this.search_page, 500);

            this.handleScroll()
            window.addEventListener('scroll', this.handleScroll);
            if(!this.mobile)
            $('[data-toggle="tooltip"]').tooltip()
            var container = $('.card-header')[0];
            if (container.scrollWidth > container.clientWidth) this.isScrollable = true

            this.emitter.on('get-np', (e) => {
                if(e.type == 'marketplace') {
                    var index = this.pids.indexOf(e.id)
                    if((index+1) == this.pids.length - 3) this.view_more()
                    var obj = { n: this.pids[index+1], p: this.pids[index-1]}
                    this.$nextTick(() => {
                        this.emitter.emit('set-np', obj)
                    })
                }
            })
        },
        watch: {
            search(newVal, oldVal) {
                if (oldVal && !newVal && this.searched) {
                    this.searched = false
                    // if(this.showInput) this.showInput = false
                    this.events = []
                    if(this.display_type != 3) {
                        this.c_page = 1
                        this.index()
                    }
                    else {
                        this.all_events = []
                        this.getAll()
                    }                }
            },
        },
        beforeUnmount() {
            $('[data-toggle="tooltip"]').tooltip('dispose')
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            async index() {
                this.loading = true
                this.text = this.language.loading
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                var data = {
                    'string' : this.search,
                    'filter_data': this.filter_data,
                }
                await axios.post(this.$api + "marketplace/events"+"?page="+this.c_page, data, config).then((res) => {
                    this.$Progress.finish()
                    this.loading = false

                    var resdata = Object.values(res.data.events.data)

                    if(this.c_page != 1) {
                        this.events = [...this.events, ...resdata];
                        var items = resdata
                        this.pids = [...this.pids, ...items.map(e => e.slug)]
                    }
                    else {
                        this.events = resdata
                        this.pids = resdata.map(e => e.slug)
                    }

                    this.is_default_location = res.data.is_default_location
                    this.l_count = resdata.length
                    this.text = this.language.no_events_to_display


                }).catch((e) => {
                    this.$Progress.fail()
                    this.loading = false
                    this.text = this.language.server_error
                });
            },
            async getAll() {
                this.display_type = 3
                this.all_loading = true
                this.text = this.language.loading
                var config = { headers: { Authorization: `Bearer ${this.token}` } }
                this.$Progress.start()
                var data = {
                    'string' : this.search,
                    'filter_data': this.filter_data,
                }
                await axios.post(this.$api + "marketplace/events/fetch-all", data, config).then((res) => {
                    this.$Progress.finish()
                    this.all_loading = false
                    this.all_events = res.data.items
                    this.text = this.language.no_events_to_display
                }).catch((e) => {
                    this.$Progress.fail()
                    this.all_loading = false
                    this.text = this.language.server_error
                });

                this.$nextTick(() => {
                    this.$refs?.googleMap.$mapPromise.then((map) => {
                        this.gMap = map
                        // Create the DIV to hold the control and call the CenterControl()
                        // constructor passing in this DIV.
                        const centerControlDiv = document.createElement('div');
                        // this.addCenterControl(centerControlDiv, map);
                        map.controls[google.maps.ControlPosition.TOP_CENTER].push(
                            centerControlDiv
                        );
                    });

                })
            },
            addCenterControl(controlDiv, map) {
                const controlUI = document.createElement('div');

                controlUI.innerHTML = `
                    <div style="color: white; background: red; padding: 1rem;">
                    You can click me, i can also be a vue component
                    </div>
                `;

                controlDiv.appendChild(controlUI);
                controlUI.addEventListener('click', () => {
                    // Do what ever you want to happen on click event
                    map.setCenter({
                    lat: 53.57532,
                    lng: 10.01534,
                    });
                });
            },
            view_more() {
                if(this.l_count > 0) {
                    this.c_page++
                    this.index()
                }
            },
            search_page() {
                if(this.search.length > 1){
                    this.$refs.search_field.focus()
                    this.searched = true
                    this.events = []
                    if(this.display_type != 3) {
                        this.c_page = 1
                        this.index()
                    }
                    else {
                        this.all_events = []
                        this.getAll()
                    }
                }
                // this.$router.push(`/marketplace/search?query=${this.search}`)
            },
            handleScroll() {
                const button = $('.floating-button')[0];
                const footer = $('body .footer')[0];
                const distance = footer?.getBoundingClientRect()?.top - button?.getBoundingClientRect()?.bottom;
                this.buttonOverlapsFooter = distance <= 0;
            },
            filterShow() {
                $('[data-toggle="tooltip"]').tooltip('dispose')
                this.$nextTick(() => this.showModal = 'filters')
                this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip('enable') )
            },
            async apply_filter() {
                this.showModal = false;
                this.c_page = 1;
                this.filter_applied = true
                if(this.display_type == 3) {
                    this.all_events = []
                    await this.getAll();
                }
                else {
                    this.events = []
                    await this.index();
                }
            },
            async reset_filter() {
                this.showModal = false;
                this.filter_data.reset()
                this.filter_data.distance = this.token ? this.user.user.distance_margin : 3000
                this.filter_data.price[0] = 0
                this.filter_data.price[1] = ''
                if(Object.keys(this.$route.query).length > 0) this.$router.push('/')
                this.c_page = 1;
                this.filter_applied = false;
                if(this.display_type == 3) {
                    this.all_events = []
                    await this.getAll();
                }
                else {
                    this.events = []
                    await this.index();
                }
            },
            f_distance(val) {
                var string = Math.floor(val) + (this.user.length > 0 ? ' ' + this.user.user.distance_unit : ' km')
                return string.toString()
            },
            addEvent() {
                if(this.token == null) {
                    this.add_emit = true ;
                    this.emitter.emit('showlogin') ;
                }
                else {
                    this.$router.push('/events/create') ;
                }
            },
            async change_display_type() {
                if(this.display_type == 1) this.display_type = 2
                else if(this.display_type == 2) {
                    if(this.map_view) {
                        this.all_events = []
                        this.getAll()
                    }
                    else {
                        this.display_type = 1
                    }
                }
                else if(this.display_type == 3) {
                    this.display_type = 1
                    this.events = []
                    this.c_page = 1
                    await this.index()
                }
            },
            handleMarker(event) {
            },
            deletedEvent() {
                this.index()
            },
        },
    }
</script>
