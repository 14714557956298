import './bootstrap';
import { createApp } from 'vue';
import App from './components/App.vue';
import { Form, HasError, AlertError } from 'vform'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Swal from 'sweetalert2'
import { createRouter, createWebHistory } from 'vue-router'
import mitt from 'mitt';
import moment from 'moment'
const emitter = mitt();
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import VueSelect from 'vue-select'
import VueSocialSharing from 'vue-social-sharing'
import InfiniteLoading from "v3-infinite-loading";
import imageCompression from 'browser-image-compression';
import Slider from '@vueform/slider'
import vue3GoogleLogin from 'vue3-google-login'
import VueGoogleMaps from '@fawmi/vue-google-maps'
// import { OAuth2Client } from 'google-auth-library'


/* code from our Firebase console */
var firebaseConfig = {
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_ID,
    measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp)

//Components
import Marketplace from './components/Marketplace.vue';
import FirebaseUser from './components/FirebaseUser.vue';
import Groups from './components/Groups.vue';
import GroupInviteLink from './components/GroupInviteLink.vue';
import Group from './components/Group.vue';
import GroupEvents from './components/GroupEvents.vue';
import Chats from './components/Chats.vue';
import Products from './components/Products.vue';
import ProductAdd from './components/ProductAdd.vue';
import ProductUpdate from './components/ProductUpdate.vue';
import ProductView from './components/ProductView.vue';
import ProductViewAdmin from './components/ProductViewAdmin.vue';
import UserStore from './components/UserStore.vue';
import EventsMarketplace from './components/EventsMarketplace.vue';
import EventView from './components/EventView.vue';
import MyEvents from './components/MyEvents.vue';
import EventAdd from './components/EventAdd.vue';
import EventUpdate from './components/EventUpdate.vue';
import Checkout from './components/Checkout.vue';
import UserProfile from './components/MyProfile.vue';
import UserLikes from './components/MyLiked.vue';
import Notifications from './components/Notifications.vue';
import GlobalNotifications from './components/GlobalNotifications.vue';
import Error from './components/partials/error.vue';
import Transaction from './components/partials/Transaction.vue';
import CustomField from './components/partials/customField.vue';
import Notification from './components/partials/Notification.vue';
import Product from './components/partials/Product.vue';
import Event from './components/partials/Event.vue';
import GroupBlock from './components/partials/GroupBlock.vue';
import NotFound from './components/partials/NotFound.vue';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
})

window.Form = Form
window.moment = moment
window.Swal = Swal
window.toast = Toast
window.db = db
window.firebaseApp = firebaseApp
window.imageCompression = imageCompression
// const oauthClient = new OAuth2Client({
//     clientId: '1029566703307-1v60kjhn3a0ii7b0lfomi1q0pjbkq5u1.apps.googleusercontent.com',
//     clientSecret: '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',
//     redirectUri: 'http://localhost:8000/auth/google'
// })

const Vue = createApp(App);
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
Vue.component("infinite-loading", InfiniteLoading);
Vue.component('v-select', VueSelect)
Vue.component('Product', Product)
Vue.component('group-block', GroupBlock)
Vue.component('NotFound', NotFound)
Vue.component('custom-field', CustomField)
Vue.component('transaction', Transaction)
Vue.component('Notification', Notification)
Vue.component('Slider', Slider)
Vue.component('Event', Event)
Vue.use(VueProgressBar, { color: 'rgb(0,107,180)', failedColor: 'red', height: '15px' }).use(VueSocialSharing)
Vue.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_MAPS_API_KEY,
        libraries: "places",
        loading: 'async',
        v: 3.56,
    },
})

Vue.directive('click-outside', {
    mounted(el, binding) {
        const handleClickOutside = (event) => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value();
            }
        };
        const handleSelfClick = (event) => { event.stopPropagation(); };
        el.addEventListener('click', handleSelfClick);
        document.addEventListener('click', handleClickOutside);
        el._clickOutsideHandler = handleClickOutside;
        el._selfClickHandler = handleSelfClick;
    },
    unmounted(el) {
        el.removeEventListener('click', el._selfClickHandler);
        document.removeEventListener('click', el._clickOutsideHandler);
        delete el._selfClickHandler;
        delete el._clickOutsideHandler;
    }
});

Vue.directive('swipe', {
    mounted(el, binding) {
        let startX ;

        const touchstart = (event) => {
            startX = event.touches[0].clientX;
        };

        const touchmove = (event) => {
            const deltaX = event.touches[0].clientX - startX;
            if (Math.abs(deltaX) > 50) {
                if (deltaX > 0 && binding.value.left) {
                    binding.value.left();
                } else if (deltaX < 0 && binding.value.right) {
                    binding.value.right();
                }
            }
        };

        const touchend = (event) => {
            startX = null;
        };

        el.addEventListener('touchstart', touchstart);
        el.addEventListener('touchmove', touchmove);
        el.addEventListener('touchend', touchend);

        // Store the event listeners on the element to access them during unmount
        el._touchstart = touchstart;
        el._touchmove = touchmove;
        el._touchend = touchend;
    },
    unmounted(el) {
        el.removeEventListener('touchstart', el._touchstart);
        el.removeEventListener('touchmove', el._touchmove);
        el.removeEventListener('touchend', el._touchend);
    }
})

const routes = [
    //Front Routes
    { path: '/firebase-user', name: 'FirebaseUser', component: FirebaseUser, meta: { auth: false, both: true }, },
    { path: '/', name: 'Marketplace', component: Marketplace, meta: { auth: false, both: true }, },
    { path: '/auth/signIn', name: 'SignIn', component: Marketplace, meta: { auth: false, both: true }, },
    { path: '/groups', name: 'Groups', component: Groups, meta: { auth: false, both: true }, },
    { path: '/group/:id', name: 'Group', component: Group, meta: { auth: true }, },
    { path: '/group/:id/events', name: 'GroupEvents', component: GroupEvents, meta: { auth: true }, },
    { path: '/group/i/:id', name: 'GroupInfo', component: GroupInviteLink, meta: { auth: false, both: true }, },
    { path: '/group/invite/:id', name: 'GroupInviteLink', component: GroupInviteLink, meta: { auth: false, both: true }, },
    { path: '/chats', name: 'Chats', component: Chats, meta: { auth: false, both: true }, },
    { path: '/store', name: 'Products', component: Products, meta: { auth: true }, },
    { path: '/events', name: 'EventsMarketplace', component: EventsMarketplace, meta: { auth: false, both: true }, },
    { path: '/event/:id', name: 'Event', component: EventView, meta: { auth: false, both: true }, },
    { path: '/user/events', name: 'MyEvents', component: MyEvents, meta: { auth: true }, },
    { path: '/events/create', name: 'EventAdd', component: EventAdd, meta: { auth: true }, },
    { path: '/events/update/:id?', name: 'EventUpdate', component: EventUpdate, meta: { auth: true }, },
    { path: '/p/add/:id?', name: 'ProductAdd', component: ProductAdd, meta: { auth: false, both: true }, },
    { path: '/checkout', name: 'Checkout', component: Checkout, meta: { auth: true }, },
    // { path: '/checkout-payment/:id', name: 'Checkout', component: Checkout, meta: { auth: true }, },
    { path: '/p/update/:id', name: 'ProductUpdate', component: ProductUpdate, meta: { auth: true }, },
    { path: '/p/:id', name: 'ProductView', component: ProductView, meta: { auth: false, both: true, transition: 'slide-left' }, },
    { path: '/view-ad/admin/:id', name: 'ProductViewAdmin', component: ProductViewAdmin, meta: { auth: false, both: true, transition: 'slide-left' }, },
    { path: '/u/:id', name: 'UserStore', component: UserStore, meta: { auth: false, both: true }, },
    { path: '/user/profile', name: 'UserProfile', component: UserProfile, meta: { auth: true }, },
    { path: '/user/likes', name: 'UserLikes', component: UserLikes, meta: { auth: true }, },
    { path: '/u/notifications', name: 'GlobalNotifications', component: GlobalNotifications, meta: { auth: true }, },
    { path: '/:pathMatch(.*)*', name: 'Error', component: Error, meta: { auth: false, both: true }, }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) { return savedPosition }
        else { return { top: 0, behavior: 'smooth' } }
    }
})

function isLogged() {
    return localStorage.getItem('greta') !== undefined ? localStorage.getItem('greta') : false;
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (isLogged()) next()
        else {
            next('/')
            Toast.fire({
                icon: "info",
                title: 'Login to Continue',
            });
        }
    }
    else {
        if (to.matched.some(record => record.meta.both)) {
            next()
        }
        else {
            if (isLogged()) next('/')
            else next()
        }
    }
});

const filters = {
    formatDate(value) {
        return moment(String(value)).format('DD MMM,YYYY h:mm a')
    },
    DateOnly(value) {
        return moment(String(value)).format('MMM DD,YYYY')
    },
    formatUnix(value) {
        return moment.unix(value / 1000).format('DD MMM,YYYY h:mm a')
    }
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

if (getCookie('locale') == undefined) {
    setCookie('locale', 'en', 30)
}
let display_locality = localStorage.getItem('display_locality')
if (display_locality == undefined || display_locality == null) {
    display_locality = {
        latitude: 28.7041,
        longitude: 77.1025,
        location_locality: 'Delhi',
        country: 'IN',
        state: 'Delhi',
        city: 'Delhi',
        pincode: '110001',
        name: 'Delhi',
        type: 'default',
    }
    setCookie('display_locality_latitude', display_locality.latitude, 30)
    setCookie('display_locality_longitude', display_locality.longitude, 30)
    localStorage.setItem('display_locality', JSON.stringify(display_locality))
}
Vue.config.globalProperties.$appname = import.meta.env.VITE_APP_NAME
Vue.config.globalProperties.$api = import.meta.env.VITE_API_URL
Vue.config.globalProperties.$domain = import.meta.env.VITE_APP_URL
Vue.config.globalProperties.$s3_url = import.meta.env.VITE_S3_URL
Vue.config.globalProperties.emitter = emitter
Vue.config.globalProperties.$filters = filters
Vue.config.globalProperties.$debounce = (callback, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callback.apply(this, args);
        }, delay);
    };
}
Vue.config.globalProperties.isLogged = () => {
    return localStorage.getItem('greta') != null ? localStorage.getItem('greta') : null;
}
Vue.config.globalProperties.verifyCode = async (code) => {
    let { tokens } = await oauthClient.getToken(code)
    oauthClient.setCredentials({ access_token: tokens.access_token })
    const userinfo = await oauthClient.request({
        // url: 'https://www.googleapis.com/oauth2/v3/userinfo'
        url: 'https://www.googleapis.com/auth/userinfo.profile'
    })
    return userinfo.data
}
Vue.config.globalProperties.setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
Vue.config.globalProperties.numberShort = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
Vue.config.globalProperties.getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
Vue.config.globalProperties.getLocation = () => {
    return localStorage.getItem('location') != null ? localStorage.getItem('location') : null;
}
Vue.config.globalProperties.lang = () => {
    return getCookie('locale') != undefined ? getCookie('locale') : null;
}
Vue.config.globalProperties.encode = (val) => {
    return btoa(btoa(val))
}
Vue.config.globalProperties.decode_val = (val) => {
    return atob(atob(val))
}
Vue.config.globalProperties.isMobile = () => {
    let check = false;
    (function(a) {
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
        check = true;
    })
    (navigator.userAgent || navigator.vendor || window.opera);
    return check;
}
Vue.use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_KEY,
})
Vue.use(router)
Vue.mount('#root');


