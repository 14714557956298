<template>
    <header class="header-area header-sticky" :class="{ 'px-0' : mobile}" v-click-outside="closeDropdown">
        <div class="container">
            <div class="d-flex">
                <div class="col-12 backdrop-blur" :class="{ 'pt-1' : !mobile}">
                    <nav class="main-nav" :class=" mobile ? 'rounded-0' : (!token ?'d-lg-flex':''), navbarDropdown ? 'roundex' : ''">
                        <router-link to="/" class="logo" :class="$domain == 'https://redthrift.com' ? (mobile ? '' : 'mt-1') : ''">
                            <img :src="mobile ? '/redthriftm.png' : '/redthrift.png'" class="img-fluid" style="height:60px" alt="Red Thrift Logo"/>
                        </router-link>
                        <!-- <transition name="scale-up">
                            <div class="headings" key="headings" v-if="token == null && !mobile">
                                <div>{{ language.buy }}</div>
                                <div>{{ language.sell }}</div>
                                <div>{{ language.rent }}</div>
                                <div>{{ language.trade }}</div>
                                <div>{{ language.free }}</div>
                            </div>
                        </transition> -->
                        <transition name="bounce">
                            <ul class="nav stroke" key="ul" :class="{'align-items-baseline' : !mobile}" v-show="navbarDropdown" @click="navbarDropdown = false">
                                <transition-group name="bounce">
                                    <!-- <li><router-link exact-active-class="active" to="/">{{ language.home }}</router-link></li> -->
                                    <li :key="language.marketplace" v-if="token != null"><router-link exact-active-class="active" to="/">{{ language.marketplace }}</router-link></li>
                                    <li :key="language.events" v-if="token != null"><router-link :class="$route.name && $route.name == 'EventsMarketPlace' ? 'active' : ''" exact-active-class="active" to="/events">Events</router-link></li>
                                    <li :key="language.group" v-if="token != null"><router-link exact-active-class="active" to="/groups">{{ language.group }}</router-link></li>
                                    <li :key="language.chat" v-if="token != null"><router-link exact-active-class="active" to="/chats">{{ language.chat }}</router-link></li>
                                    <li :key="language.products" v-if="token != null"><router-link :class="$route.name && ['ProductAdd','ProductUpdate'].includes($route.name) ? 'active' : ''" exact-active-class="active" to="/store">{{ language.products }}</router-link></li>
                                    <li :key="language.my_profile" v-if="token != null && mobile"><router-link exact-active-class="active" to="/user/profile">{{ language.my_profile }}</router-link></li>
                                    <li :key="language.my_notifications" v-if="token != null && mobile"><router-link exact-active-class="active" to="/user/notifications">{{ language.my_notifications }}</router-link></li>
                                    <li :key="language.my_liked" v-if="token != null && mobile"><router-link exact-active-class="active" to="/user/likes">{{ language.my_liked }}</router-link></li>
                                    <li :key="language.logout" v-if="token != null && mobile"><a class="dropdown-item" href="javascript:;" @click="logout">{{ language.logout }}</a></li>
                                    <li :key="language.sign_in" v-if="token == null">
                                        <a href="javascript:;" @click="emitter.emit('showlogin')">{{ language.sign_in }}</a>
                                    </li>
                                    <li key="name" v-else-if="token!=null && !mobile" @mouseleave="show_drop=false" @mouseover="show_drop=true">
                                        <a href="javascript:;" class="dropdown-toggle" v-if="!mobile">
                                            <img v-if="user.user" :src="$s3_url+user.user.thumbnail_image" class="profile-icon">
                                            <i class="fas fa-user" v-else></i>
                                            {{ name }}
                                        </a>
                                        <transition name="slide-up" mode="out-in">
                                            <div class="dropdown-menu p-0" v-if="show_drop && !mobile" aria-labelledby="navbarDropdown">
                                                <router-link to="/user/profile" class="dropdown-item">{{ language.my_profile }}</router-link>
                                                <router-link to="/user/events" class="dropdown-item">My Events</router-link>
                                                <!-- <router-link to="/user/notifications" class="dropdown-item">{{ language.my_notifications }}</router-link>
                                                <router-link to="/user/g-notifications" class="dropdown-item">G Notifications</router-link> -->
                                                <router-link to="/user/likes" class="dropdown-item">{{ language.my_liked }}</router-link>
                                                <a class="dropdown-item" href="javascript:;" @click="logout">{{ language.logout }}</a>
                                            </div>
                                        </transition>
                                    </li>
                                    <li key="notif" v-if="token!=null && !mobile" class="px-3">
                                        <router-link class="position-relative px-1" to="/u/notifications">
                                            <i class="fas fa-15x fa-bell" :class="notifs > 0 ? 'faa-tada animated' : ''"></i>
                                            <span v-if="notifs > 0" class="notif small">{{ notifs }}</span>
                                        </router-link>
                                    </li>
                                    <li key="lang" class="lang-li" v-if="!mobile">
                                        <span class="language" @click="language_model">{{ current_lang.code.toUpperCase() }}</span>
                                    </li>
                                </transition-group>
                            </ul>
                        </transition>
                        <transition-group name="fade">
                            <router-link key="menu" class="menu-account" v-if="mobile && token != null" to="/user/profile">
                                <img v-if="user.user" :src="$s3_url+user.user.thumbnail_image" class="profile-icon">
                            </router-link>
                            <router-link to="/u/notifications" key="notif" v-if="token!=null && mobile" :class="notifs.length ? 'mx-1' : ''" class="btn notif-icon">
                                <i class="fas fa-2x fa-bell" :class="notifs.length ? 'faa-tada animated' : ''"></i>
                                <span v-if="notifs > 0" class="notif">{{ notifs }}</span>
                            </router-link>
                        </transition-group>
                        <transition name="fade">
                            <a class="menu-trigger" href="javascript:;" v-if="token" @click="navbarDropdown = !navbarDropdown;" :class="{ 'active' : navbarDropdown }">
                                <span>Menu</span>
                            </a>
                            <span class="a-li" v-else-if="mobile" @click="emitter.emit('showlogin')">{{ language.sign_in }}</span>
                        </transition>
                        <span class="language li" :class="{ 'token' : token!=null}" v-if="mobile" @click="language_model">
                            {{ current_lang.code.toUpperCase() }}
                        </span>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 0.2s ease-in;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .bounce-enter-active {
        animation: bounce-in 0.5s;
    }
    .bounce-leave-active {
        animation: bounce-in 0.2s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    .slide-up-enter-active, .slide-up-leave-active {
        transition: all 0.25s ease-out;
    }

    .slide-up-enter-from {
        opacity: 0;
        transform: translateY(30px);
    }

    .slide-up-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
    .scale-up-enter-active, .scale-up-leave-active {
        transition: all 0.3s ease-in;
    }

    .scale-up-enter-from, .scale-up-leave-to {
        transform: scaleY(0);
        opacity: 0.5;
    }

</style>
<script setup>
    import axios from "axios";
    import { getAuth, signOut } from "firebase/auth";
</script>
<script>
export default {
    name: 'f_header',
    props: ['language'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            user: [],
            show_drop: false,
            navbarDropdown: false,
            lang_code: '',
            current_lang: {
                code: '',
                name: ''
            },
            languages: [],
            notifs: 0,
            notifPoll: null,
        };
    },
    watch: {
        '$route': function(){
            this.navbarDropdown = false;
        }
    },
    created() {
        this.index()
        var refData = localStorage.getItem('refData')
        if(refData == null) {
            window.localStorage.setItem('refData', JSON.stringify(this.$route.query)) ;
        }
        if(refData && refData=='{}' && JSON.stringify(this.$route.query) != '{}') {
            window.localStorage.setItem('refData', JSON.stringify(this.$route.query)) ;
            this.$router.replace(this.$route.path)
        }
        if(this.$route.name == 'SignIn') {
            var code = this.$route.query.apiKey;
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            axios.post(this.$api + 'auth/verify-sign-in-email', { email: email, code: code}).then((result) => {
                if(result.data.status == 'success') {
                    window.localStorage.removeItem('emailForSignIn');
                    this.$router.push('/')
                    // window.history.pushState(null, "", '/');
                    this.emitter.emit('emailForSignIn', {email: email, uid: result.data.uid});
                }
                else {
                    toast.fire({
                        'icon' : 'error',
                        'title': 'Link Expired.. Try Again',
                    });
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        if(this.token != null) {
            this.get_user()
            this.getNotifs() ;
            this.notifPoll = setInterval(() => { this.getNotifs() ; }, 5000);
        }

        this.emitter.on("loggedIn", async token => {
            await this.get_user(token);
            this.notifPoll = setInterval(() => { this.getNotifs() ; }, 5000);
            this.token = token;
            this.navbarDropdown = false
        });
        this.emitter.on("get_user", () => {
            this.get_user()
        });
        window.addEventListener('resize', this.resize);
    },
    destroyed() {
        clearInterval(this.notifPoll)
        window.removeEventListener("resize", this.resize);
    },
    mounted() {
        this.emitter.on('updated_lang', (data) => {
            this.current_lang = data
        })
    },
    computed: {
        name() {
            return this.user.user ? this.user.user.first_name.split(/(\s+)/)[0] : 'User'
        },
    },
    methods: {
        clickoutsidehandler (event) {
            if(this.navbarDropdown)
                this.navbarDropdown = false
        },
        async index() {
            await axios.get(this.$api+'get-languages').then((res)=>{
                this.languages = res.data.languages
            })

            // Getting current language
            var cookie_code = ''
            const value = `; ${document.cookie}`;
            const parts = value.split(`; locale=`);
            cookie_code = parts.pop().split(';').shift()
            this.current_lang.code = cookie_code
            this.current_lang.name = this.languages.find(item =>item.code == cookie_code).name
        },
        logout() {
            this.$Progress.start()
            const auth = getAuth();
            signOut(auth).then(() => {
                this.$Progress.finish()
                localStorage.removeItem('greta') ;
                this.navbarDropdown = false
                this.token = null ;
                this.$router.push('/')
                clearInterval(this.notifPoll)
                this.emitter.emit("loggedOut");
                // toast.fire({
                //     'icon' : 'success',
                //     'title': this.language.toast_logout_success,
                // });
            }).catch((error) => {
                this.$Progress.fail()
                toast.fire({
                    'icon' : 'error',
                    'title': this.language.toast_error_occured,
                });
            });
        },
        async get_user(token = null) {
            if(token == null) token = this.token
            var config = { headers: { Authorization: `Bearer ${token}` } }
            await axios.get(this.$api + "user/get-profile", config).then((res) => {
                this.user = res.data
                this.show_drop = false
                this.emitter.emit('update_user', this.user)
            }).catch((error) => {
                if (error?.response?.status === 401) this.logout()
            });
        },
        language_model() {
            this.emitter.emit('lang_modal', this.languages)
        },
        resize(e) {
            this.mobile = this.isMobile()
        },
        closeDropdown() {
            this.show_drop = false
            this.navbarDropdown = false
        },
        openFullscreen() {
            var elem = document.body ;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        },
        async getNotifs() {
            var config = { headers: { Authorization: `Bearer ${this.token}` } }
            await axios.get(this.$api + "notifications/get-notifications/count", config).then((res) => {
                this.notifs = res.data.notifications
                if(this.notifs > 0) this.emitter.emit('fetchNewNotifs') ;
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>
